import React from 'react';
import axios from 'axios';
import {
	observable, action, makeObservable, computed
} from 'mobx';

import { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';
import { LoadingSpinner } from '~/util/Components/LoadingSpinner';
import { DeliveryDatesForm } from '~/engage/available-delivery-dates/Components/DeliveryDatesForm';
import { DeliveryDatesResults } from '~/engage/available-delivery-dates/Components/DeliveryDatesResults';
import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import { formModel, formSettings } from '~/engage/available-delivery-dates/Stores/deliveryDatesFormSettings';

class AvailableDeliveryDatesStore {
	results = [];

	form = [];

	globalDynamicStore;

	editMode = false;

	zipCode = null;

	sendZipIfPresent;

	constructor(link, globalDynamicStore, sendZipIfPresent = true) {
		makeObservable(this, {
			results: observable.ref,
			form: observable,
			globalDynamicStore: observable,
			editMode: observable,
			zipCode: observable,
			datesToDisplay: computed,
			editZip: action.bound,
			submitHandler: action.bound
		});

		this.link = link;
		this.globalDynamicStore = globalDynamicStore;
		this.sendZipIfPresent = sendZipIfPresent;
	}

	editZip() {
		this.editMode = true;
		this.form.model.zipCode = '';
	}

	get datesToDisplay() {
		const {
			deliveryDates = [],
			deliveryAvailability = [],
			legacyDeliveryAvailability = [],
		} = this.results || {};

		if (deliveryDates.length) {
			return deliveryDates;
		}
		if (deliveryAvailability.length) {
			return deliveryAvailability;
		}
		if (legacyDeliveryAvailability.length) {
			return legacyDeliveryAvailability;
		}
		return [];
	}

	submitHandler() {
		const data = {};
		let method = 'get';

		this.viewState.goTo('loading');

		if (this.sendZipIfPresent && this.form?.model?.zipCode?.length >= 5) {
			method = 'post';
			data.zipCode = this.form.model.zipCode;
		}

		return axios({
			data,
			method,
			url: this.link,
		}).then((response) => {
			this.results = response.data;
			this.zipCode = this.form.model.zipCode;
			this.editMode = false;

			if (this.viewState.currentView.key !== 'results') {
				this.viewState.goTo('results');
			}
		});
	}

	async init() {
		this.viewState = new ViewStateStore(this, {
			loading: {
				component: () => (
					<div style={{ minHeight: '100px' }}>
						<LoadingSpinner isLoading />
					</div>
				),
			},
			form: {
				component: DeliveryDatesForm,
				onEnter: () => ({ store: this }),
			},
			results: {
				component: DeliveryDatesResults,
				onEnter: () => ({
					store: this,
					zipCode: this.form.model.zipCode,
					editCallback() {
						this.viewState.goTo('form');
					}
				}),
			}
		});

		this.viewState.goTo('loading');
		this.form = new FormBuilder(formModel(), formSettings(this, this.submitHandler));
		try {
			const response = await this.globalDynamicStore.transZoneStore.getTransZone();
			const zip = response.data.zipCode.slice(0, 5);

			this.zipCode = zip;
			this.form.updateModelValue('zipCode', zip);
			if (zip !== '') {
				this.submitHandler();
			} else {
				this.viewState.goTo('form');
			}
		} catch (error) {
			this.viewState.goTo('form');
		}
	}
}

export { AvailableDeliveryDatesStore };
