import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { noop } from '~/util/noop';
import { autoFocusHelper } from '~/util/autoFocusHelper';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import styles from '~/engage/sell-gift-card/Components/SellGiftCard.module.scss';

export const GiftCardGunScanner = observer(({ cancelGunScannerClickHandler = noop }) => {
	const { featureTogglesModel } = useGlobalContext();

	return (
		<div className={styles['SellGiftCard-gunScanningView']}>
			<h2 className="Heading Heading--5">Scan gift card…</h2>
			{/* This is needed for Cypress to type into. */}
			<div data-qa="cypress-only-placeholder" contentEditable className={cn('tw-w-full tw-border tw-px-1', {
				'tw-hidden': !featureTogglesModel.isOn('DEBUG_MODE'),
			})} />
			<button
				type="button"
				onClick={cancelGunScannerClickHandler}
				className="ButtonAnchor tw-mt-3"
				ref={autoFocusHelper}
			>
				Cancel
			</button>
		</div>
	);
});
