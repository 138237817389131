import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';
import type { AxiosResponse } from 'axios';

import type { Customer } from '~/engage/customer-search/search-results/Types/Customer.interface';
import type { CustomerLinkingStore } from '~/engage/customer-search/search-results/customer-linking/Stores/CustomerLinking.store';
import type { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';
import { isEmpty } from '~/util/isEmpty';
import { SapCustomer } from '~/engage/customer-search/search-results/Components/SapCustomer';
import { LoadingSpinner } from '~/util/Components/LoadingSpinner';
import { LinkWebCustomer } from '~/engage/customer-search/search-results/customer-linking/Components/LinkWebCustomer';

import styles from '~/engage/customer-search/search-results/customer-linking/Components/customer-search-results.module.scss';

interface Response {
	results: Customer[]
	_links: {
		proxy: {
			href: string
		}
	}
}

export const SearchLink = observer((props: { proxyCustomer: (arg0: string) => void; viewState?: ViewStateStore; customerLinkStore: CustomerLinkingStore; }) => {
	const { customerLinkStore } = props;
	let continueWithoutLinking = null;

	return (
		<div className={styles['customer-search-results']} data-qa="customer-search-results">
			<SapCustomer customer={customerLinkStore.customerToLink} readOnly linkStatus={1} />
			<h2 className={`tw-heading-6 tw-text-white tw-bg-gray ${styles['customer-search-heading']}`}>
				{
					customerLinkStore?.customerToLink?.type === 'LINKED_MULTI' ? 'Select an online account:' : 'Select an online account to link:'
				}
			</h2>
			{
				customerLinkStore.results.case({
					pending: () => {
						return (
							<div style={{ minHeight: '200px' }}>
								<LoadingSpinner isLoading />
							</div>
						);
					},
					rejected: (error: unknown) => {
						return <h2>{error as ReactNode}</h2>;
					},
					fulfilled: (response: AxiosResponse<Response>) => {
						const users = response?.data?.results;

						if (customerLinkStore?.customerToLink?.type !== 'LINKED_MULTI') {
							continueWithoutLinking = (
								<button className="ButtonAnchor" onClick={() => {
									props.proxyCustomer(response?.data?._links?.proxy?.href);
								}}>Continue without linking</button>
							);
						}
						return (
							<ol className={`${styles['customer-search-result-list']} ${styles['customer-search-result-link-list']} u-noListStyle tw-ml-0"`} data-qa="customer-search-result-list">
								{
									!isEmpty(users) &&
									users?.map?.((user, i) => (
										<li key={`${user?.primaryEmail?.[user?.email!] || ''}${i}`} className={styles['customer-search-result-item']} data-qa="customer-search-result-item">
											<LinkWebCustomer
												customer={user}
												customerLinkStore={customerLinkStore}
												viewState={props.viewState}
											/>
										</li>
									))
								}
								{
									isEmpty(users) &&
									<li>No web accounts found.</li>
								}
							</ol>
						);
					}
				})
			}
			<div className={styles['customer-search-footer']}>
				<div className="ButtonGroup">
					{continueWithoutLinking}
					<button className="ButtonAnchor" onClick={() => {
						props.viewState?.goTo('searchResults');
					}}>Back to search results</button>
				</div>
			</div>
		</div>
	);
});
