import { isNumber } from '~/util/isNumber';

type Radius = {
	tl: number
	tr: number
	br: number
	bl: number
};

const isRadiusType = (
	obj: Radius | number,
): obj is Radius => {
	return !isNumber(obj);
};

/**
 * Draws a rounded rectangle using the current state of the canvas.
 * If you omit the last three params, it will draw a rectangle
 * outline with a 5 pixel border radius
 * @param {CanvasRenderingContext2D} ctx
 * @param {Number} x The top left x coordinate
 * @param {Number} y The top left y coordinate
 * @param {Number} width The width of the rectangle
 * @param {Number} height The height of the rectangle
 * @param {Number} [radius = 5] The corner radius; It can also be an object
 *                 to specify different radii for corners
 * @param {Number} [radius.tl = 0] Top left
 * @param {Number} [radius.tr = 0] Top right
 * @param {Number} [radius.br = 0] Bottom right
 * @param {Number} [radius.bl = 0] Bottom left
 * @param {Boolean} [fill = false] Whether to fill the rectangle.
 * @param {Boolean} [stroke = true] Whether to stroke the rectangle.
 */
export const roundedRect = (
	ctx: CanvasRenderingContext2D,
	x: number,
	y: number,
	width: number,
	height: number,
	radius: Radius | number = 5,
	fill = false,
	stroke = true,
	fillOptions?: (ctx: CanvasRenderingContext2D) => void,
	strokeOptions?: (ctx: CanvasRenderingContext2D) => void,
) => {
	let theRadius: Radius = {
		tl: 0, tr: 0, br: 0, bl: 0,
	};

	if (!isRadiusType(radius)) {
		theRadius = {
			tl: radius, tr: radius, br: radius, bl: radius,
		};
	} else {
		theRadius = { ...radius };
	}
	ctx.beginPath();
	ctx.moveTo(x + theRadius.tl, y);
	ctx.lineTo(x + width - theRadius.tr, y);
	ctx.quadraticCurveTo(x + width, y, x + width, y + theRadius.tr);
	ctx.lineTo(x + width, y + height - theRadius.br);
	ctx.quadraticCurveTo(x + width, y + height, x + width - theRadius.br, y + height);
	ctx.lineTo(x + theRadius.bl, y + height);
	ctx.quadraticCurveTo(x, y + height, x, y + height - theRadius.bl);
	ctx.lineTo(x, y + theRadius.tl);
	ctx.quadraticCurveTo(x, y, x + theRadius.tl, y);
	ctx.closePath();
	if (fill) {
		fillOptions?.(ctx);
		ctx.fill();
	}
	if (stroke) {
		strokeOptions?.(ctx);
		ctx.stroke();
	}
};
