import React from 'react';

import { TextButton } from '~/components/Buttons/Components/TextButton';

type Props = {
	values: {
		onTryAgainClick: () => void
	}
};

export const InvalidGiftCardNumberMessage = ({ values: { onTryAgainClick } }: Props) => {
	return (
		<div className="tw-text-red">
			Gift card number is not valid. Please{' '}
			<TextButton
				type="button"
				onClick={onTryAgainClick}
			>
				try again
			</TextButton>.
		</div>
	);
};
