import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { formatCurrency } from '~/util/formatCurrency';
import { Quantity } from '~/engage/shop-search/search-results/Components/Quantity';
import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';

import styles from '~/engage/toolbar/side-menu/Components/shop-side-menu.legacy.module.scss';

// RNB.Engage.ShopSearch.Renderers.searchResult
const SearchResult = observer(class SearchResult extends React.Component {
	render() {
		const result = this.props.searchResult;
		const buttonClasses = classNames('ButtonTertiary js-button-flasher', result.buttonClasses);

		return (
			<div className={styles['shop-search-result-item']}>
				<div className={styles['item']}>
					<span data-qa="sales-text" className={styles['sales-text']}>{result.salesText}</span>
					<span>Item no: {result.articleNumber}</span>
				</div>
				<div className={styles['status']}>{result?.status || ''}</div>
				<div className={styles['unitPrice']}>{formatCurrency(result.price)}</div>
				<div className={styles['quantity']}><Quantity result={result} /></div>
				<div className={styles['call-to-action']}>
					<button
						className={buttonClasses}
						data-qa="shop-search-add-item-button"
						data-tr-link-event-type={LinkEventTypes.SITE_ACTION}
						data-tr-link-event-name="add to cart"
						data-tr-link-event-comp-type="design associate tools"
						data-tr-link-event-comp-name="quick search results modal"
						onClick={() => {
							this.props.searchResultsStore.addToCartHandler(result);
						}}>{result.buttonText}</button>
					<div className={styles['added-message']}>{result.message}</div>
				</div>
			</div>
		);
	}
});

export { SearchResult };
