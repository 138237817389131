import React from 'react';
import { observer } from 'mobx-react';

import { SearchResultStore } from '~/engage/shop-search/search-results/Stores/SearchResult.store';
import { SearchResult } from '~/engage/shop-search/search-results/Components/SearchResult';

// RNB.Engage.ShopSearch.Renderers.searchResults
const SearchResults = observer(class SearchResults extends React.Component {
	render() {
		const productSearchResults = this.props?.store?.results?.value?.productSearchResults;
		const results = productSearchResults?.map?.(searchResult => new SearchResultStore(searchResult));

		return (
			<div className="shop-search-result-list">
				{
					results?.map?.(searchResult => (
						<SearchResult
							key={`result-${searchResult.articleNumber}`}
							searchResult={searchResult}
							searchResultsStore={this.props.store}
						/>
					))
				}
			</div>
		);
	}
});

export { SearchResults };
