'use client';

import React from 'react';

import cn from 'classnames';

interface Props {
	action?: () => void
}
export const StartOverMessage = ({ values, inline = false }: { values: Props, inline?: boolean }) => {
	const { action } = values;

	return (
		<div className={cn({ 'tw-text-red': inline })}>
			Unable to save changes. Please{' '}
			{
				typeof action === 'function'
					?
					<button
						type="button"
						onClick={() => action()}
						className="ButtonAnchor"
					>
					start over
					</button>
					: 'start over'

			}
			.
		</div>
	);
};
