import React from 'react';
import axios, { type AxiosResponse } from 'axios';
import {
	model, Model, prop, idProp, modelFlow, _await, _async, getRoot,
} from 'mobx-keystone';
import { observable } from 'mobx';
import Router from 'next/router';

import type { ReactPropsType } from '~/util/ReactPropsMap';
import type { OpportunitiesStore, OpportunityCategoryKey } from '~/engage/opportunities/Stores/Opportunities.store.root';
import type { OpportunityModel } from '~/engage/opportunities/Models/Opportunity.model';
import type { OpportunityNotesStore } from '~/engage/opportunities/Stores/OpportunityNotes.store';
import type { TriggeredEmailModel } from '~/engage/opportunities/Models/TriggeredEmail.model';
import { AddEditNotesModal } from '~/engage/opportunities/Components/AddEditNotesModal';
import { modelNamespace } from '~/util/modelNamespace';
import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import { PromiseError } from '~/util/messaging/promise-error/PromiseError';
import { ConfirmRemoveOpportunityModal } from '~/engage/opportunities/Components/ConfirmRemoveOpportunityModal';
import { ConfirmSendEmailModal } from '~/engage/opportunities/Components/ConfirmSendEmailModal';
import { ConfirmSendEmailModalConfirmation } from '~/engage/opportunities/Components/ConfirmSendEmailModalConfirmation';
import { MyOpportunityTrackingStore } from '~/tracking/my-opportunity-events/Stores/MyOpportunity.tracking.store';

type ProxyResponse = {
	_links: {
		proxiedCustomerDetails: {
			href: string
		}
		proxyLandingPage: {
			href: string
		}
	}
}

@model(`${modelNamespace.OPPORTUNITIES}/OpportunityStore`)
export class OpportunityStore extends Model({
	id: idProp,
	model: prop<OpportunityModel>(),
	moveOpportunityToCategoryLink: prop<string | undefined>(),
	pinOpportunityLink: prop<string | undefined>(),
	notes: prop<OpportunityNotesStore>(),
	proxyLink: prop<string>(),
	removeOpportunityLink: prop<string | undefined>(),
	unpinOpportunityLink: prop<string | undefined>(),
}) {
	@observable.ref
	confirmRemoveOpportunityPromiseError?: PromiseError;

	@observable.ref
	sendEmailPromiseError?: PromiseError;

	get canAddEditNotes() {
		return this.notes.opportunityNotesLink !== undefined;
	}

	get canMoveOpportunityToCategory() {
		return Boolean(this.moveOpportunityToCategoryLink && this.model.moveToCategories.length);
	}

	get canRemoveOpportunity() {
		return this.removeOpportunityLink !== undefined;
	}

	get moveToMenuItemsClickHandlers() {
		const onClicks: ReactPropsType<'button'>['onClick'][] = [];

		this.model.moveToCategories.forEach((moveToCategory) => {
			onClicks.push(() => {
				this.moveOpportunityToCategory(moveToCategory);
			});
		});
		return onClicks;
	}

	get canSendTriggeredEmail() {
		return Boolean(this.model.triggeredEmails?.length);
	}

	get sendEmailsClickHandlers() {
		const onClicks: ReactPropsType<'button'>['onClick'][] = [];
		this.model.sortedTriggeredEmails.forEach((triggeredEmail) => {
			onClicks.push(() => {
				this.openSendEmailModal(triggeredEmail!);
			});
		});
		return onClicks;
	}

	get canPinOpportunity() {
		return this.pinOpportunityLink !== undefined;
	}

	get canUnpinOpportunity() {
		return this.unpinOpportunityLink !== undefined;
	}

	notesForm: unknown;

	@modelFlow
	moveOpportunityToCategory = _async(function* (this: OpportunityStore, categoryKey: OpportunityCategoryKey) {
		const rootStore = getRoot<OpportunitiesStore>(this);

		try {
			if (!this.moveOpportunityToCategoryLink) {
				throw new Error('No moveOpportunityToCategoryLink found.');
			}
			if (!rootStore) {
				throw new Error('No rootStore found.');
			}
			if (!rootStore.hasMagicOverlay(rootStore.magicOverlay)) {
				throw new Error('No magicOverlay found.');
			}
			const payload = {
				category: categoryKey,
			};
			const promise = axios.put(this.moveOpportunityToCategoryLink, payload);

			rootStore.magicOverlay.startLoading();
			const response = yield* _await(promise);
			yield* _await(rootStore.fetchPageData());

			const {
				data: {
					analytics = {},
				} = {},
			} = response;

			MyOpportunityTrackingStore.trackAddOpp(analytics);
		} catch (error: unknown) {
			if (axios.isAxiosError(error)) {
				rootStore.onError(error);
				return;
			}
			console.error(error);
		} finally {
			if (rootStore.hasMagicOverlay(rootStore.magicOverlay)) {
				rootStore.magicOverlay.stopLoading();
			}
		}
	});

	onRemoveOpportunityFailure(error: unknown) {
		if (!axios.isAxiosError(error)) {
			console.error(error);
			return;
		}
		this.confirmRemoveOpportunityPromiseError = new PromiseError(error);
	}

	@modelFlow
	onRemoveOpportunitySuccess = _async(function* (this: OpportunityStore) {
		const rootStore = getRoot<OpportunitiesStore>(this);

		if (!rootStore) {
			throw new Error('No rootStore found.');
		}
		if (!rootStore.hasMagicOverlay(rootStore.magicOverlay)) {
			throw new Error('No magicOverlay found.');
		}
		if (!rootStore.hasMagicModal(rootStore.magicModal)) {
			throw new Error('No magicModal found.');
		}
		try {
			yield* _await(rootStore.fetchPageData());
			rootStore.magicModal.closeModal();
			rootStore.magicOverlay.stopLoading();
		} catch (error: unknown) {
			this.onRemoveOpportunityFailure(error);
		}
	});

	openAddEditNotesModal() {
		const rootStore = getRoot<OpportunitiesStore>(this);

		try {
			if (!rootStore) {
				throw new Error('No rootStore found.');
			}
			if (!rootStore.hasMagicModal(rootStore.magicModal)) {
				throw new Error('No magicModal found.');
			}
			if (!this.notes.opportunityNotesLink) {
				throw new Error('No opportunityNotesLink found.');
			}
			this.notes.form = new FormBuilder(this.notes.formModel, this.notes.formSettings);
			this.notes.promiseError = undefined;
			rootStore.magicModal.openModal({
				title: 'Add/Edit Notes',
				maxWidth: '500px',
				content: {
					children: <AddEditNotesModal store={this.notes} />,
				},
			});
		} catch (error: unknown) {
			console.error(error);
		}
	}

	openConfirmRemoveOpportunityModal() {
		const rootStore = getRoot<OpportunitiesStore>(this);

		try {
			if (!rootStore) {
				throw new Error('No rootStore found.');
			}
			if (!rootStore.hasMagicModal(rootStore.magicModal)) {
				throw new Error('No magicModal found.');
			}
			this.confirmRemoveOpportunityPromiseError = undefined;
			rootStore.magicModal.openModal({
				title: 'Remove From My Opportunities?',
				maxWidth: '500px',
				content: {
					children: <ConfirmRemoveOpportunityModal store={this} />,
				},
			});
		} catch (error: unknown) {
			console.error(error);
		}
	}

	@modelFlow
	proxyCustomer = _async(function* (this: OpportunityStore) {
		const rootStore = getRoot<OpportunitiesStore>(this);

		if (!rootStore) {
			throw new Error('No rootStore found.');
		}
		if (!rootStore.hasMagicOverlay(rootStore.magicOverlay)) {
			throw new Error('No magicOverlay found.');
		}
		try {
			const promise = axios.post(this.proxyLink);
			const response: AxiosResponse<ProxyResponse> = yield* _await(promise);

			Router.push(response.data._links.proxyLandingPage.href ?? '/cart');
		} catch (error: unknown) {
			if (!axios.isAxiosError(error)) {
				console.error(error);
				return;
			}
			rootStore.onError(error);
		}
	});

	@modelFlow
	removeOpportunity = _async(function* (this: OpportunityStore) {
		const rootStore = getRoot<OpportunitiesStore>(this);

		if (!rootStore) {
			throw new Error('No rootStore found.');
		}
		if (!rootStore.hasMagicOverlay(rootStore.magicOverlay)) {
			throw new Error('No magicOverlay found.');
		}
		if (!this.removeOpportunityLink) {
			throw new Error('No removeOpportunityLink found.');
		}
		try {
			const promise = axios.delete(this.removeOpportunityLink);
			rootStore.magicOverlay.startLoading(promise);
			this.confirmRemoveOpportunityPromiseError = undefined;
			const response = yield* _await(promise);
			this.onRemoveOpportunitySuccess();

			const {
				data: {
					analytics = {},
				} = {},
			} = response;

			MyOpportunityTrackingStore.trackRemoveOpp(analytics);
		} catch (error: unknown) {
			this.onRemoveOpportunityFailure(error);
			rootStore.magicOverlay.stopLoading();
		} finally {
			if (rootStore.hasMagicOverlay(rootStore.magicOverlay)) {
				rootStore.magicOverlay.stopLoading();
			}
		}
	});

	openSendEmailModal(triggeredEmail: TriggeredEmailModel) {
		const rootStore = getRoot<OpportunitiesStore>(this);

		try {
			if (!rootStore) {
				throw new Error('No rootStore found.');
			}
			if (!rootStore.hasMagicModal(rootStore.magicModal)) {
				throw new Error('No magicModal found.');
			}
			if (!triggeredEmail.sendTriggeredEmailLink) {
				throw new Error('No sendTriggeredEmailLink found.');
			}
			this.sendEmailPromiseError = undefined;
			rootStore.magicModal.openModal({
				title: 'Send Email',
				maxWidth: '500px',
				content: {
					children: <ConfirmSendEmailModal store={this} triggeredEmail={triggeredEmail} />,
				},
			});
		} catch (error: unknown) {
			if (!axios.isAxiosError(error)) {
				console.error(error);
				return;
			}
			rootStore.onError(error);
		}
	}

	onSendEmailFailure(error: unknown) {
		const rootStore = getRoot<OpportunitiesStore>(this);

		if (!axios.isAxiosError(error)) {
			console.error(error);
			return;
		}
		if (!rootStore) {
			throw new Error('No rootStore found.');
		}
		if (!rootStore.hasMagicOverlay(rootStore.magicOverlay)) {
			throw new Error('No magicOverlay found.');
		}
		this.sendEmailPromiseError = new PromiseError(error);
		rootStore.magicOverlay.stopLoading();
	}

	@modelFlow
	onSendEmailSuccess = _async(function* (this: OpportunityStore) {
		const rootStore = getRoot<OpportunitiesStore>(this);

		if (!rootStore) {
			throw new Error('No rootStore found.');
		}
		if (!rootStore.hasMagicOverlay(rootStore.magicOverlay)) {
			throw new Error('No magicOverlay found.');
		}
		if (!rootStore.hasMagicModal(rootStore.magicModal)) {
			throw new Error('No magicModal found.');
		}
		try {
			yield* _await(rootStore.fetchPageData());
			rootStore.magicModal.alterModal({
				title: 'Email Sent',
				content: {
					children: <ConfirmSendEmailModalConfirmation />,
				},
			});
			rootStore.magicOverlay.stopLoading();
		} catch (error: unknown) {
			if (axios.isAxiosError(error)) {
				rootStore.onError(error);
				return;
			}
			console.error(error);
		}
	});

	@modelFlow
	sendEmail = _async(function* (this: OpportunityStore, triggeredEmail: TriggeredEmailModel) {
		const rootStore = getRoot<OpportunitiesStore>(this);

		if (!rootStore) {
			throw new Error('No rootStore found.');
		}
		if (!rootStore.hasMagicOverlay(rootStore.magicOverlay)) {
			throw new Error('No magicOverlay found.');
		}
		if (!triggeredEmail.sendTriggeredEmailLink) {
			throw new Error('No sendTriggeredEmailLink found.');
		}
		const {
			triggeredEmailType,
			sendTriggeredEmailLink,
		} = triggeredEmail;

		try {
			const promise = axios.post(sendTriggeredEmailLink, { triggeredEmailType });
			rootStore.magicOverlay.startLoading(promise);
			this.sendEmailPromiseError = undefined;
			yield* _await(promise);
			this.onSendEmailSuccess();
		} catch (error: unknown) {
			this.onSendEmailFailure(error);
			rootStore.magicOverlay.stopLoading();
		} finally {
			if (rootStore.hasMagicOverlay(rootStore.magicOverlay)) {
				rootStore.magicOverlay.stopLoading();
			}
		}
	});

	@modelFlow
	pinOpportunity = _async(function* (this: OpportunityStore) {
		const rootStore = getRoot<OpportunitiesStore>(this);

		try {
			if (!this.pinOpportunityLink) {
				throw new Error('No pinOpportunityLink found.');
			}
			if (!rootStore) {
				throw new Error('No rootStore found.');
			}
			if (!rootStore.hasMagicOverlay(rootStore.magicOverlay)) {
				throw new Error('No magicOverlay found.');
			}
			const promise = axios.post(this.pinOpportunityLink);

			rootStore.magicOverlay.startLoading();
			yield* _await(promise);
			yield* _await(rootStore.fetchPageData());
		} catch (error: unknown) {
			if (axios.isAxiosError(error)) {
				rootStore.onError(error);
				return;
			}
			console.error(error);
		} finally {
			if (rootStore.hasMagicOverlay(rootStore.magicOverlay)) {
				rootStore.magicOverlay.stopLoading();
			}
		}
	});

	@modelFlow
	unpinOpportunity = _async(function* (this: OpportunityStore) {
		const rootStore = getRoot<OpportunitiesStore>(this);

		try {
			if (!this.unpinOpportunityLink) {
				throw new Error('No unpinOpportunityLink found.');
			}
			if (!rootStore) {
				throw new Error('No rootStore found.');
			}
			if (!rootStore.hasMagicOverlay(rootStore.magicOverlay)) {
				throw new Error('No magicOverlay found.');
			}
			const promise = axios.post(this.unpinOpportunityLink);

			rootStore.magicOverlay.startLoading();
			yield* _await(promise);
			yield* _await(rootStore.fetchPageData());
		} catch (error: unknown) {
			if (axios.isAxiosError(error)) {
				rootStore.onError(error);
				return;
			}
			console.error(error);
		} finally {
			if (rootStore.hasMagicOverlay(rootStore.magicOverlay)) {
				rootStore.magicOverlay.stopLoading();
			}
		}
	});
}
