import { action } from 'mobx';
import { type ModelCreationData } from 'mobx-keystone';

import { type OnlineAccessFormDefaults } from '~/engage/toolbar/create-account/Stores/LegacyCreateAccountForm.store';
import { type MagicModalStore } from '~/components/magic-modal/Stores/MagicModal.store';
import { type GlobalDynamicStore } from '~/global/global-dynamic/Stores/GlobalDynamic.store';
import { SetUpOnlineAccess } from '~/engage/toolbar/set-up-online-access/SetUpOnlineAccess';
import { LegacyCreateAccountStore } from '~/engage/toolbar/create-account/Stores/LegacyCreateAccount.store';
import { LegacyCreateAccount } from '~/engage/toolbar/create-account/Components/LegacyCreateAccount';
import { SetUpOnlineAccessStore, type ViewKey } from '~/engage/toolbar/set-up-online-access/SetUpOnlineAccess.store';
import { noop } from '~/util/noop';

export type CreateAccountOptions<TToggle extends 'MARKETING_MMS_OPTIN_ENGAGE' | undefined = undefined> = {
	formDefaults: TToggle extends undefined ? Partial<OnlineAccessFormDefaults> : Partial<ModelCreationData<SetUpOnlineAccessStore>>
	modalOptions: Record<string, any>
	onCreateAccountSuccess: () => void
	onCreateAccountSuccessView: TToggle extends undefined ? string : ViewKey
};

export const openSetUpOnlineAccessModal = action((
	magicModal: MagicModalStore,
	globalDynamicStore: GlobalDynamicStore,
	options: Partial<CreateAccountOptions<'MARKETING_MMS_OPTIN_ENGAGE' | undefined>> = {},
) => {
	const { modalOptions } = options;
	const activeFeatureToggles = 'activeFeatureToggles' in globalDynamicStore.model
		? globalDynamicStore.model.activeFeatureToggles
		: [];

	if (Array.isArray(activeFeatureToggles) && activeFeatureToggles.includes('MARKETING_MMS_OPTIN_ENGAGE')) {
		const initialModelData = {
			...options.formDefaults,
		};
		const {
			formDefaults,
			...config
		} = options;
		const onCreateAccountSuccessView: ViewKey = 'SUCCESS';
		const storeOptions = {
			formDefaults: initialModelData,
			modalOptions: {},
			onCreateAccountSuccess: noop,
			onCreateAccountSuccessView,
			...config
		};
		/**
		 * MARKETING_MMS_OPTIN_ENGAGE
		 * Remove the type assertion
		 */
		const store = SetUpOnlineAccessStore.create(
			initialModelData,
			storeOptions as Partial<CreateAccountOptions<'MARKETING_MMS_OPTIN_ENGAGE'>>,
		);

		magicModal.openModal({
			id: 'create-account',
			title: 'Set Up Online Access',
			maxWidth: '535px',
			content: {
				children: <SetUpOnlineAccess store={store} />,
			},
			...modalOptions,
		});
		return;
	}
	const createAccountStore = new LegacyCreateAccountStore(magicModal, globalDynamicStore, options);

	magicModal.openModal({
		id: 'create-account',
		title: 'Set Up Online Access',
		maxWidth: '410px',
		content: {
			children: <LegacyCreateAccount createAccountStore={createAccountStore} />,
		},
		...modalOptions,
	});
});
