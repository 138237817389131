import React from 'react';
import cn from 'classnames';

export const FinancialTransactionInvalidCardInformationMessage = ({ inline }: { inline?: boolean }) => {
	return (
		<div className={cn({ 'tw-text-red': inline })}>
			Please enter a valid card number, billing ZIP code, and CVV.
		</div>
	);
};
