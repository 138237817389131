import React from 'react';
import { observer } from 'mobx-react';

import { MaskedField } from '~/util/formz/Components/fields/MaskedField';

// RNB.Engage.CartSearch.Renderer.cartSearchField
const CartSearchField = observer(class CartSearchField extends React.Component {
	render() {
		return <MaskedField field={this.props.form.fields.cartId} />;
	}
});

export { CartSearchField };
