import React from 'react';
import { action, makeObservable } from 'mobx';
import { fromPromise } from 'mobx-utils';
import axios, { type AxiosError } from 'axios';

import { isEmpty } from '~/util/isEmpty';
import { RecentCustomersModal } from '~/engage/recent-customers/Models/RecentCustomersModal.model';
import { CartImagesStore } from '~/engage/recent-customers/Stores/CartImages.store';
import { redirect } from '~/util/redirect';
import { PromiseError } from '~/util/messaging/promise-error/PromiseError';
import { PromiseErrorMessaging } from '~/util/messaging/promise-error/PromiseErrorMessaging';

class RecentCustomersModalStore {
	model: any;

	magicModal: any;

	constructor(recentCustomerPagesLink: any, magicModal: any) {
		makeObservable(this, {
			fetchRecentCustomersByPageNumber: action.bound,
			fetchRecentCustomerPages: action.bound,
			proxyRecentCustomer: action,
			toggleCartImages: action.bound,
		});

		this.model = new RecentCustomersModal(recentCustomerPagesLink);
		this.magicModal = magicModal;
	}

	fetchRecentCustomersByPageNumber(pageNumber = 1) {
		this.model.pageNumber = pageNumber;
		const link = this.model.recentCustomerPages?.[this.model.pageIndex]?._links?.recentCustomersPage?.href;

		if (!link) {
			return Promise.reject();
		}
		const promise = axios.get(link);

		this.model.cartImagesStores.clear();
		promise.then((response) => {
			response?.data?.recentCustomers?.map?.((recentCustomer: { _links: { cartImages: { href: string; }; }; }, i: number) => {
				this.model.cartImagesStores.push(new CartImagesStore({
					index: i,
					link: recentCustomer?._links?.cartImages?.href,
				}));
				return recentCustomer;
			});
			return response;
		}, (error) => {
			console.error('Failed to fetch page', error);
		});
		this.model.modalResults = fromPromise(promise);
		return promise;
	}

	fetchRecentCustomerPages() {
		return axios.get(this.model.recentCustomerPagesLink).then((resp) => {
			this.model.recentCustomerPages = resp?.data?.recentCustomerPages;
		}, (error) => {
			console.error('Failed to retrieve recent customer pages', error);
		});
	}

	proxyRecentCustomer(link: string) {
		return axios.post(link).then((resp) => {
			redirect(resp?.data?._links?.proxyLandingPage?.href || '/shop/shoppingcart/cartDetails.do');
		}, (error: unknown) => {
			if (!axios.isAxiosError(error)) {
				console.error(error);
				return;
			}
			this.onError(error);
		});
	}

	toggleCartImages(index: any) {
		const cartImagesStore = this.model.cartImagesStores?.find?.((store: any) => store.index === index);

		if (isEmpty(cartImagesStore.link)) {
			return;
		}
		cartImagesStore.isOpen = !cartImagesStore.isOpen;
		if (cartImagesStore.isOpen) {
			cartImagesStore.loadCartImages();
		}
	}

	onError(error: AxiosError) {
		const promiseError = new PromiseError(error);
		this.magicModal.closeModal();
		this.magicModal.openModal({
			title: promiseError.errorKey === 'blockedCustomerError' ? 'Alert' : 'Error',
			maxWidth: '500px',
			content: {
				children: <PromiseErrorMessaging errorMessage={promiseError.errorMessageObj} inline />
			}
		});
	}
}

export const RecentCustomersModalStoreFactory = {
	create(recentCustomerPagesLink: string, magicModal: any) {
		const store = new RecentCustomersModalStore(recentCustomerPagesLink, magicModal);

		store.fetchRecentCustomerPages().then(() => {
			store.fetchRecentCustomersByPageNumber(store.model.pageNumber);
		});
		return store;
	},
};
