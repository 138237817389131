import React from 'react';
import { observer } from 'mobx-react';

import { noop } from '~/util/noop';

import styles from '~/engage/customer-search/search-results/customer-linking/Components/customer-search-results.module.scss';

export const QuoteSearchResultsError = observer(({ httpStatusCode = 404, tryAgainHandler = noop }) => {
	switch (httpStatusCode) {
		case 410:
			return (
				<div className={styles['error-message']} data-qa="quote-search-results-message">
					<span>
						Quotes from the past seven years are available in a customer's account.
					</span>
				</div>
			);
		case 404:
		default:
			return (
				<div className={styles['error-message']} data-qa="quote-search-results-message">
					<span>Quote number cannot be found. Please review what was entered and </span>
					<button
						type="button"
						onClick={tryAgainHandler}
					>
						try again
					</button>.
				</div>
			);
	}
});
