import React, { useState, useEffect, createRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { observer } from 'mobx-react';
import cn from 'classnames';

import type { GiftCardScannerStore } from '~/engage/gift-card-scanner/Stores/GiftCardScanner.store';
import { noop } from '~/util/noop';
import { FileUpload } from '~/engage/gift-card-scanner/Components/FileUpload';
import scannerStyles from '~/engage/gift-card-scanner/Components/GiftCardScanner.module.scss';
import scannerFadeStyles from '~/engage/gift-card-scanner/Components/GiftCardScannerFade.module.scss';

// console.log('scannerStyles:', scannerStyles);
export const GiftCardCameraScanner = observer(({ store }: { store: GiftCardScannerStore }) => {
	const {
		decode = noop,
		model: {
			hasError = false,
			showHotspotLabel = false,
			useFileUpload = false,
		} = {},
		closeScannerOverlay = noop,
		initCameraScanning = noop,
	} = store;
	const [isClosing, setIsClosing] = useState(false);
	const videoElem = createRef<HTMLDivElement>();
	// const onResize = debounce(() => {
	// 	this.initScanner();
	// }, 500);
	// const removeEventListener = () => {
	// 	window.removeEventListener('resize', onResize);
	// };

	useEffect(() => {
		if (!videoElem.current) {
			// return removeEventListener;
			return;
		}
		initCameraScanning(videoElem.current);
		// window.addEventListener('resize', onResize);
		// return removeEventListener;
	}, []);

	return (
		<CSSTransition
			timeout={200}
			classNames={{
				appear: scannerFadeStyles['GiftCardScannerFade-appear'],
				appearActive: `${scannerFadeStyles['GiftCardScannerFade-appear']} ${scannerFadeStyles['GiftCardScannerFade-appear-active']}`,
				exit: scannerFadeStyles['GiftCardScannerFade-leave'],
				exitActive: `${scannerFadeStyles['GiftCardScannerFade-leave']} ${scannerFadeStyles['GiftCardScannerFade-leave-active']}`,
			}}
			in
			appear
			mountOnEnter
			unmountOnExit
		>
			<div className={scannerFadeStyles['GiftCardScannerFade-component']}>
				<div
					key="container"
					className={cn(scannerStyles['GiftCardScanner-container'], {
						[scannerFadeStyles['GiftCardScannerFade-leave']]: isClosing,
						[scannerFadeStyles['GiftCardScannerFade-leave-active']]: isClosing,
					})}
					ref={videoElem}
				/>
				<button
					key="close-button"
					type="button"
					className={scannerStyles['GiftCardScanner-closeBtn']}
					onClick={() => {
						setIsClosing(true);
						setTimeout(closeScannerOverlay, 200);
					}}
				>
					<svg version="1.2" xmlns="http://www.w3.org/2000/svg" overflow="visible" preserveAspectRatio="none" width="14" height="14"><path d="M14 1.41L12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7 14 1.41z" vectorEffect="non-scaling-stroke" fill="#fff" /></svg>
				</button>
				<div className={scannerStyles['GiftCardScanner-videoOverlay']} key="video-overlay" />
				<div className={scannerStyles['GiftCardScanner-contentOverlay']}>
					{
						hasError &&
						<div key="error-message" className={scannerStyles['GiftCardScanner-hotSpotLabel']}>
							An unknown error occurred, please try again.<br />
							If the problem persists, please open a Help Desk ticket.
						</div>
					}
					<div
						key="hotspot-label"
						className={cn(scannerStyles['GiftCardScanner-hotSpotLabel'], {
							'tw-hidden': !showHotspotLabel,
						})}
					>
						Line up the barcode in the frame
					</div>
				</div>
				<div
					key="video"
					className={scannerStyles['GiftCardScanner-video']}
					ref={videoElem}
				>
					{
						useFileUpload &&
						<FileUpload key="file-upload" decode={decode} />
					}
				</div>
			</div>
		</CSSTransition>
	);
});
