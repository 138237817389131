import { isOnServer } from '~/global/global.constants';

const isHtmlInputElementType = (
	obj: HTMLElement,
): obj is HTMLInputElement => {
	return 'select' in obj && typeof obj.select === 'function' && 'value' in obj;
};

export const autoFocusHelper = <TElement extends HTMLButtonElement | HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | null>(elem: TElement, timeout?: number) => {
	const doStuff = () => {
		if (isOnServer || !elem || document.activeElement === elem) {
			return;
		}
		elem.focus();
		try {
			if (isHtmlInputElementType(elem)) {
				elem.select();
			} else if ('createTextRange' in elem && typeof elem.createTextRange === 'function') {
				const range = elem.createTextRange();

				range.collapse(false);
				range.select();
			}
		} catch (error) {
			console.error('Text selection not supported.', error);
		}
	};

	if (timeout === undefined) {
		doStuff();
	} else {
		window.setTimeout(doStuff, timeout);
	}
};
