import React from 'react';
import { observer } from 'mobx-react';

import { LoadingSpinner } from '~/util/Components/LoadingSpinner';

const AsyncFormSubmitLoading = observer(class AsyncFormSubmitLoading extends React.Component {
	render() {
		const { form, children } = this.props;

		if (form?.plugins?.ajaxSubmit?.obsPromise) {
			return (
				<LoadingSpinner isLoading={form?.plugins?.ajaxSubmit?.obsPromise?.state === 'pending'}>
					{children || null}
				</LoadingSpinner>
			);
		}
		return null;
	}
});

export { AsyncFormSubmitLoading };
