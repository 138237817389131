import {
	observable, computed, action, extendObservable, toJS, makeObservable,
} from 'mobx';

// RNB.Engage.ShopSearch.Stores.searchResult
class SearchResultStore {
	constructor(searchResultData) {
		makeObservable(this, {
			quantity: observable,
			message: observable,
			buttonText: computed,
			buttonClasses: computed,
			addToCartStatus: observable,
			changeQuantity: action.bound,
		});

		extendObservable(this, toJS(searchResultData));
	}

	quantity = 1;

	message = '';

	get buttonText() {
		const itemText = this.quantity > 1 ? 'Items' : 'Item';

		if (this.addToCartStatus === null) {
			return 'Add to Cart';
		}
		return this.addToCartStatus.case({
			pending: () => `Adding ${itemText}`,
			rejected: () => 'Unable to Add',
			fulfilled: () => `Added ${itemText}`,
		});
	}

	get buttonClasses() {
		if (this.addToCartStatus === null) {
			return '';
		}
		return this.addToCartStatus.case({
			pending: () => 'isProcessing',
			rejected: () => '',
			fulfilled: () => 'isProcessing',
		});
	}

	addToCartStatus = null;

	changeQuantity(event) {
		this.quantity = event.target.value;
		this.message = '';
		this.addToCartStatus = null;
	}
}

export { SearchResultStore };
