'use client';

import { observer } from 'mobx-react';

import { type OnlineAccessFormDefaults } from '~/engage/toolbar/create-account/Stores/LegacyCreateAccountForm.store';
import { type LegacyCreateAccountStore } from '~/engage/toolbar/create-account/Stores/LegacyCreateAccount.store';
import { EmailField } from '~/util/formz/Components/fields/EmailField';
import { Field } from '~/util/formz/Components/fields/Field';
import { PromiseErrorMessaging } from '~/util/messaging/promise-error/PromiseErrorMessaging';
import { useSafeSpread } from '~/hooks/useSafeSpread';

type Props = {
	createAccountStore: LegacyCreateAccountStore
};

/**
 * @deprecated MARKETING_MMS_OPTIN_ENGAGE (entire file)
 */
export const LegacyForm = observer((props: Props) => {
	const {
		createAccountStore: store,
		createAccountStore: {
			createAccountFormStore: formStore,
			createAccountFormStore: {
				form,
			},
		},
	} = props;
	const [formReactProps, formKey] = useSafeSpread(form?.reactProps || {});

	if (!form?.fields) {
		return null;
	}
	const fields = form.fields as Record<string, unknown>;
	const model = form.model as OnlineAccessFormDefaults;

	return (
		<form key={formKey} {...formReactProps}>
			{
				<>
					<EmailField field={fields.username} />
					{
						formStore.promiseError &&
						<div className="tw-mb-4">
							<PromiseErrorMessaging
								errorMessage={formStore.promiseError.errorMessageObj}
								childProps={{
									email: model.username,
								}}
								inline
							/>
						</div>
					}
				</>
			}
			<Field field={fields.zipCode} />
			<Field field={fields.optIn} />
			<div className="form-actions ButtonGroup">
				<button
					className="Button"
					data-qa="create-account-modal-submit-button"
				>
					Submit
				</button>
				<button
					className="ButtonAnchor"
					type="button"
					onClick={(event) => {
						event.preventDefault(); store.cancelHandler();
					}}
				>
					Cancel
				</button>
			</div>
		</form>
	);
});
