import {
	action, observable, computed, makeObservable,
} from 'mobx';

import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import {
	customerNumberLookupFormModel,
	customerNumberLookupFormSettings,
} from '~/engage/toolbar/customer-side-menu/customer-lookup/Stores/CustomerNumberLookup.form.settings';

class CustomerNumberLookupStore {
	form;

	parentStore;

	constructor() {
		makeObservable(this, {
			form: observable,
			parentStore: observable,
			payload: computed,
			save: action.bound,
		});
	}

	get payload() {
		return {
			customerNumber: this.form.model.customerNumber,
		};
	}

	save() {
		this.parentStore.save(this.payload);
	}
}

const CustomerNumberLookupStoreFactory = {
	create: (parentStore) => {
		const instance = new CustomerNumberLookupStore();
		instance.parentStore = parentStore;
		instance.form = new FormBuilder(customerNumberLookupFormModel(), customerNumberLookupFormSettings(instance));

		return instance;
	},
};

export { CustomerNumberLookupStoreFactory };
