import React from 'react';
import { observer } from 'mobx-react';

import type { Customer } from '~/engage/customer-search/search-results/Types/Customer.interface';
import type { CustomerLinkingStore } from '~/engage/customer-search/search-results/customer-linking/Stores/CustomerLinking.store';
import type { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';
import { Button } from '~/components/Buttons/Components/Button';
import { ButtonVariant } from '~/components/Buttons/Types/constants';

import styles from '~/engage/customer-search/search-results/customer-linking/Components/customer-search-results.module.scss';

type Props = {
	viewState?: ViewStateStore
	customerLinkStore: CustomerLinkingStore
	customer: Customer
};

export const LinkWebCustomer = observer(({ customerLinkStore, customer, viewState }: Props) => {
	const href = customer?._links?.proxy?.href as string;
	const viewProps = viewState?.viewProps as {[x: string]: any, proxyCustomer: (arg0: string) => void };
	const customerType = customerLinkStore.customerToLink.type;
	let activityDate;

	try {
		activityDate = new Intl.DateTimeFormat('en-US', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit'
		}).format(new Date(customer.lastActivityDate || customer.lastOrderDate || ''));
	} catch (error: unknown) {
		activityDate = 'Unknown';
	}

	return (
		<div className={`${styles['customer-search-result']} link-web-customer-search-result h-card"`}>
			<div className={styles['customer-search-col']}>
				<div className={styles['p-email']} data-qa="p-email">{customer.primaryEmail}</div>
				<div className={styles['activity-date']}>Cart Activity Date: {activityDate}</div>
			</div>
			<div className={styles['customer-search-col']}>
				<div />
				{
					Boolean(href) &&
					<Button
						className={styles['link-and-continue-button']}
						data-qa="link-and-continue-button"
						type="button"
						variant={ButtonVariant.Tertiary}
						onClick={() => {
							if (customerType === 'LINKED_MULTI') {
								if (viewProps.proxyCustomer) {
									viewProps.proxyCustomer(href);
								}
							} else {
								customerLinkStore.linkCustomer(href, customer);
							}
						}}
					>
						{ customerType === 'LINKED_MULTI' ? 'Continue' : 'Link and Continue' }
					</Button>
				}
			</div>
		</div>
	);
});
