import { action, makeObservable } from 'mobx';
import Quagga, { QuaggaJSConfigObject, QuaggaJSResultObject } from '@ericblade/quagga2';

import { GiftCardScannerModel } from '~/engage/gift-card-scanner/Models/GiftCardScanner.model';
import { roundedRect } from '~/util/roundedRect';
import { noop } from '~/util/noop';

export class GiftCardScannerStore {
	featureTogglesModel: any;

	model: any;

	addGunScanningKeyboardEventListeners() {
		document.addEventListener<'keydown'>('keydown', this.gunScanningKeyboardHandler, true);
	}

	askPermission() {
		if (typeof navigator.mediaDevices?.getUserMedia === 'function') {
			return navigator.mediaDevices.getUserMedia({
				video: true,
				audio: false,
			});
		}
		return Promise.reject('getUserMedia not supported');
	}

	cleanup() {
		if (this.model.isIpad) {
			this.cleanupCameraScanning();
		} else {
			this.cleanupGunScanning();
		}
	}

	cleanupCameraScanning() {
		this.model.showVideo = false;
		this.model.scannerStarted = false;
		Quagga.offDetected(this.onDetectedHandler);
		Quagga.offProcessed(this.onProcessedHandler);
		if (this.model.useFileUpload || !this.model.scannerStarted) {
			return;
		}
		Quagga.stop();
	}

	cleanupGunScanning() {
		this.removeGunScanningKeyboardEventListeners();
	}

	closeScannerOverlay() {
		this.onCloseScannerOverlay();
		this.cleanup();
	}

	decode(src: string) {
		Quagga.decodeSingle({
			...this.model.scannerConfig,
			src,
		}, this.onDetectedHandler);
	}

	drawHotSpotFrame(ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement, result: QuaggaJSResultObject) {
		const { boxes = [] } = result;
		// @ericblade/quagga2 1.4.2 correctly returns lots of boxes, 1.5.0 and above does not.
		// https://github.com/ericblade/quagga2/issues/466
		if (!boxes.length) {
			return;
		}
		ctx.clearRect(
			0,
			0,
			parseInt(canvas.getAttribute('width') || '0', 10),
			parseInt(canvas.getAttribute('height') || '0', 10),
		);
		const filteredBoxes = result.boxes.filter(box => box !== result.box);

		if (!filteredBoxes.length) {
			return;
		}
		const rectMinX = Math.min(...filteredBoxes[0].map(([x]) => x));
		const rectMaxX = Math.max(...filteredBoxes[0].map(([x]) => x));
		const rectMinY = Math.min(...filteredBoxes[0].map(([, y]) => y));
		const rectMaxY = Math.max(...filteredBoxes[0].map(([, y]) => y));
		const rectPosX = rectMinX;
		const rectPosY = rectMinY;
		const rectWidth = rectMaxX - rectMinX;
		const rectHeight = rectMaxY - rectMinY;

		this.model.hotspotFrameX = typeof this.model.hotspotFrameX === 'undefined' ? rectPosX : this.model.hotspotFrameX;
		this.model.hotspotFrameY = typeof this.model.hotspotFrameY === 'undefined' ? rectPosY : this.model.hotspotFrameY;
		this.model.hotspotFrameWidth = typeof this.model.hotspotFrameWidth === 'undefined' ? rectWidth : this.model.hotspotFrameWidth;
		this.model.hotspotFrameHeight = typeof this.model.hotspotFrameHeight === 'undefined' ? rectHeight : this.model.hotspotFrameHeight;
		// Draw curtain
		ctx.fillStyle = 'rgba(0, 0, 0, 0.60)';
		ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
		// Draw frame
		roundedRect(
			ctx,
			rectPosX,
			rectPosY,
			rectWidth,
			rectHeight,
			2,
			true,
			false,
			(ctx2) => {
				ctx2.globalCompositeOperation = 'destination-out';
				ctx2.fillStyle = '#fff';
			},
		);
		ctx.globalCompositeOperation = 'source-over';
		ctx.lineWidth = 1;
		// Draw label
		// ctx.font = '19px proxima-nova, Helvetica, Arial, sans-serif';
		// ctx.textAlign = 'center';
		// ctx.fillStyle = '#fff';
		// ctx.fillText('Line up the barcode in the frame', canvas.width / 2, this.model.hotspotFrameY - 13);
	}

	getVideoInputDevices() {
		if (typeof navigator.mediaDevices?.enumerateDevices === 'function') {
			return navigator.mediaDevices.enumerateDevices();
		}
		return Promise.reject('enumerateDevices not supported');
	}

	gunScanningKeyboardHandler(event: KeyboardEvent) {
		const { key = '' } = event;
		const { model = {} } = this;

		if (!model.rawBarcode) {
			model.rawBarcode = '';
		}
		if (!/\d/.test(key) || model.rawBarcode.length >= 30) {
			return;
		}
		model.rawBarcode += key;
		if (model.rawBarcode.length < 30) {
			return;
		}
		console.log('30 digits have been captured', model.rawBarcode);
		this.onGunScannerSuccess();
	}

	init(target?: HTMLDivElement, quaggaConfig?: QuaggaJSConfigObject) {
		if (this.model.isIpad) {
			this.initCameraScanning(target, quaggaConfig);
		} else {
			this.initGunScanning();
		}
	}

	async initCameraScanning(target?: HTMLDivElement, quaggaConfig?: QuaggaJSConfigObject) {
		if (this.model.useFileUpload) {
			return;
		}
		this.cleanup();
		try {
			await this.askPermission();
			try {
				const devices = await this.getVideoInputDevices();

				this.onGetVideoInputDevicesSuccess(devices);
				this.model.videoContainerElem = target;
				if (this.model.debugMode) {
					console.log('devices:', devices);
					console.log('this.model.backCameraInputDevice:', this.model.backCameraInputDevice);
					console.log('this.model.selectedDeviceId:', this.model.selectedDeviceId);
				}

				if (this.model.scannerStarted) {
					return;
				}
				Quagga.init(this.model.getScannerConfig(quaggaConfig), (error) => {
					if (error) {
						console.error(error);
					}
					Quagga.onDetected(this.onDetectedHandler);
					Quagga.onProcessed(this.onProcessedHandler);
					Quagga.start();
					setTimeout(() => {
						this.model.canvasBoundingRect = target?.querySelector?.('.drawingBuffer')?.getBoundingClientRect();
						this.model.showHotspotLabel = true;
						this.model.scannerStarted = true;
					}, 100);
				});
			} catch (error) {
				this.onError(error);
			}
		} catch (error) {
			this.onError(error);
		}
	}

	initGunScanning() {
		this.reset();
		this.addGunScanningKeyboardEventListeners();
	}

	onCloseScannerOverlay = noop;

	onDetectedHandler(data: QuaggaJSResultObject) {
		if (this.model.debugMode) {
			console.log('detected!');
			console.log(data);
		}
		const {
			codeResult: {
				code = '',
			} = {},
		} = data;

		if (!code) {
			return;
		}
		this.model.rawBarcode = code;
		this.closeScannerOverlay();
	}

	onError(error: unknown) {
		console.error(error);
		this.model.hasError = true;
	}

	onGetVideoInputDevicesSuccess(devices: MediaDeviceInfo[]) {
		this.model.videoInputDevices = devices;
		if (this.model.backCameraInputDevice) {
			this.model.selectedDeviceId = this.model.backCameraInputDevice.deviceId;
		}
		return devices;
	}

	onGunScannerSuccess = noop;

	onProcessedHandler(result: QuaggaJSResultObject) {
		const drawingCtx = Quagga.canvas.ctx.overlay;
		const drawingCanvas = Quagga.canvas.dom.overlay;

		if (!result) {
			return;
		}
		this.drawHotSpotFrame(drawingCtx, drawingCanvas, result);
		if (result.codeResult?.code) {
			Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: 'red', lineWidth: 3 });
		}
	}

	removeGunScanningKeyboardEventListeners() {
		document.removeEventListener<'keydown'>('keydown', this.gunScanningKeyboardHandler);
	}

	reset() {
		this.model = new GiftCardScannerModel(this.featureTogglesModel);
	}

	constructor() {
		makeObservable(this, {
			addGunScanningKeyboardEventListeners: action.bound,
			askPermission: action.bound,
			cleanup: action.bound,
			cleanupCameraScanning: action.bound,
			cleanupGunScanning: action.bound,
			closeScannerOverlay: action.bound,
			decode: action.bound,
			drawHotSpotFrame: action.bound,
			getVideoInputDevices: action.bound,
			gunScanningKeyboardHandler: action.bound,
			init: action.bound,
			initCameraScanning: action.bound,
			initGunScanning: action.bound,
			onDetectedHandler: action.bound,
			onError: action.bound,
			onGetVideoInputDevicesSuccess: action.bound,
			onProcessedHandler: action.bound,
			removeGunScanningKeyboardEventListeners: action.bound,
			reset: action.bound,
		});
	}
}

export const GiftCardScannerStoreFactory = {
	create({
		featureTogglesModel = {},
		onCloseScannerOverlay = noop,
		onGunScannerSuccess = noop,
	} = {}) {
		if (!featureTogglesModel) {
			throw new Error('featureTogglesModel not found.');
		}
		const store = new GiftCardScannerStore();

		store.featureTogglesModel = featureTogglesModel;
		store.model = new GiftCardScannerModel(store.featureTogglesModel);
		store.onGunScannerSuccess = onGunScannerSuccess;
		store.onCloseScannerOverlay = onCloseScannerOverlay;
		return store;
	},
};
