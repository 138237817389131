import React from 'react';
import { observer } from 'mobx-react';

import { AsyncFormSubmitError } from '~/util/formz/Components/plugins/async-form-submit/AsyncFormSubmitError';
import { AsyncFormSubmitLoading } from '~/util/formz/Components/plugins/async-form-submit/AsyncFormSubmitLoading';

const AsyncFormSubmit = observer(class AsyncFormSubmit extends React.Component {
	static defaultProps = {
		showFormLevelErrors: true,
	}

	render() {
		const { form } = this.props;

		return (
			<div className="ajax-submit-wrapper" style={{ position: 'relative' }}>
				<AsyncFormSubmitError form={form} showFormLevelErrors={this.props.showFormLevelErrors} />
				{this.props.children}
				<AsyncFormSubmitLoading form={form} />
			</div>
		);
	}
});

export { AsyncFormSubmit };
