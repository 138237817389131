import {
	action, observable, computed, makeObservable,
} from 'mobx';

import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import {
	advancedBusinessLookupFormModel,
	advancedBusinessLookupFormSettings,
} from '~/engage/toolbar/customer-side-menu/customer-lookup/Stores/AdvancedBusinessLookup.form.settings';

class AdvancedBusinessLookupStore {
	form;

	parentStore;

	reactions;

	constructor() {
		makeObservable(this, {
			form: observable,
			parentStore: observable,
			payload: computed,
			isFormValid: computed,
			destroy: action.bound,
			save: action.bound,
		});
	}

	get payload() {
		return {
			customerType: 'BUSINESS',
			contactFirstName: this.form.model.firstName,
			contactLastName: this.form.model.lastName,
			street: this.form.model.street,
			businessName: this.form.model.businessName,
			city: this.form.model.city,
			zipCode: this.form.model.zipCode,
		};
	}

	get isFormValid() {
		let valueCtr = 0;
		Object.values(this.form.model).forEach((value) => {
			if (value.replace(/[*]/g, '').length >= 2) {
				valueCtr++;
			}
		});

		return !this.form.plugins.formValidator.hasErrors && valueCtr >= 2;
	}

	destroy() {
		while (this.reactions.length > 0) {
			const reactionToDestroy = this.reactions.pop();
			reactionToDestroy();
		}
	}

	save() {
		if (this.isFormValid) {
			this.parentStore.save(this.payload);
		}
	}
}

const AdvancedBusinessLookupStoreFactory = {
	create: (parentStore) => {
		const instance = new AdvancedBusinessLookupStore();
		instance.parentStore = parentStore;
		instance.reactions = [];
		instance.form = new FormBuilder(advancedBusinessLookupFormModel(), advancedBusinessLookupFormSettings(instance));

		return instance;
	},
};

export { AdvancedBusinessLookupStoreFactory };
