import React from 'react';
import cn from 'classnames';

export const FinancialTransactionCancelledMessage = ({ inline }: { inline?: boolean }) => {
	return (
		<div className={cn({ 'tw-text-red': inline })}>
			Customer cancelled transaction. Please try again.
		</div>
	);
};
