import {
	observable, computed, intercept, makeObservable,
} from 'mobx';

export class MagicPaginationNavModel {
	autoCollapsePrevBtn = false;

	autoCollapseNextBtn = false;

	buttonRefs = []

	currentPage = 1;

	interceptors

	pageCount = 1;

	scrollToElement = undefined

	visiblePages = 3;

	constructor(options = {}) {
		makeObservable(this, {
			actualCurrentPage: computed,
			actualPageCount: computed,
			actualVisiblePages: computed,
			autoCollapsePrevBtn: observable,
			autoCollapseNextBtn: observable,
			currentBlock: computed,
			currentPage: observable,
			currentPageButtonRef: computed,
			hasMorePagesThanShowable: computed,
			pageCount: observable,
			shouldNextBtnExist: computed,
			shouldPrevBtnExist: computed,
			shouldShowPrevBtn: computed,
			shouldShowNextBtn: computed,
			visiblePages: observable,
		});

		this.autoCollapsePrevBtn = options.autoCollapsePrevBtn || false;
		this.autoCollapseNextBtn = options.autoCollapseNextBtn || false;
		this.visiblePages = options.visiblePages || 3;
		this.pageCount = options.pageCount || 1;
		this.currentPage = options.currentPage || 1;
		this.scrollToElement = options.scrollToElement;
		this.buttonRefs = options.buttonRefs || [];
	}

	get actualCurrentPage() {
		return Math.round(parseFloat(this.currentPage));
	}

	get actualPageCount() {
		return Math.round(parseFloat(this.pageCount));
	}

	get actualVisiblePages() {
		return Math.round(parseFloat(this.visiblePages));
	}

	// Multiplier for the which grouping of visible pages' buttons to display
	get currentBlock() {
		const visibleCenterPage = Math.ceil(Math.max(this.visiblePages / 2, 1));
		const remainingNextPages = this.pageCount - this.currentPage;
		const visiblePrevPages = Math.floor(this.visiblePages / 2);
		const visibleNextPages = Math.min(remainingNextPages, this.visiblePages - visiblePrevPages - 1);

		// Logic when it is at the beginning of the list.
		if (this.currentPage <= visibleCenterPage) {
			return 1;
		}
		// Logic when it is at the end of the list.
		if (remainingNextPages < this.visiblePages - visibleNextPages - 1) {
			return this.pageCount - this.visiblePages + 1;
		}
		// Logic for everything in between.
		return Math.max(this.currentPage - visiblePrevPages, 1);
	}

	get currentPageButtonRef() {
		return this.buttonRefs?.find?.(ref => ref.page === this.currentPage);
	}

	destroy() {
		this.interceptors?.map?.(interceptor => interceptor?.());
	}

	get hasMorePagesThanShowable() {
		return this.pageCount > this.visiblePages;
	}

	get shouldNextBtnExist() {
		return this.hasMorePagesThanShowable;
	}

	get shouldPrevBtnExist() {
		return this.hasMorePagesThanShowable;
	}

	get shouldShowPrevBtn() {
		return this.hasMorePagesThanShowable && this.currentPage > 1;
	}

	get shouldShowNextBtn() {
		return this.hasMorePagesThanShowable && this.currentPage < this.pageCount;
	}
}

export const MagicPaginationNavModelFactory = {
	create(options = {}) {
		const model = new MagicPaginationNavModel(options);

		model.interceptors = [
			intercept(model, 'currentPage', (change) => {
				if (change.newValue < 1 || change.newValue > model.pageCount) {
					return null;
				}
				return change;
			}),
		];
	},
};
