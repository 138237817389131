import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import styles from '~/components/magic-pagination-nav/Components/magic-pagination-nav.module.scss';

export const MagicPaginationNav = observer(class MagicPaginationNav extends React.Component {
	constructor(props) {
		super(props);
		this.onClickHandler = this.onClickHandler.bind(this);
		// This is being brought into state to get more control over auto focusing.
		this.state = {
			shouldShowNextBtn: this.props.store.model.shouldShowNextBtn,
			shouldShowPrevBtn: this.props.store.model.shouldShowPrevBtn,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		// Focus is lost when the prev/next buttons go away when reaching the beginning/end.
		// When this happens, regain focus on the current page button.
		const nextBtnWasHidden = prevState.shouldShowNextBtn && !this.state.shouldShowNextBtn;
		const prevBtnWasHidden = prevState.shouldShowPrevBtn && !this.state.shouldShowPrevBtn;

		if (!nextBtnWasHidden && !prevBtnWasHidden) {
			return;
		}
		this.props.store.focusCurrentPage();
	}

	render() {
		const { props } = this;
		const { store } = props;
		const { model } = store;
		const {
			actualVisiblePages: visiblePages,
			actualPageCount: pageCount,
			actualCurrentPage: currentPage,
			autoCollapsePrevBtn,
			autoCollapseNextBtn,
			currentBlock,
			shouldPrevBtnExist,
			shouldNextBtnExist,
			shouldShowNextBtn,
			shouldShowPrevBtn,
		} = model;

		if (pageCount <= 1) {
			return null;
		}

		return (
			<nav className={styles.MagicPaginationNav} aria-label="pagination" data-qa="pagination-nav-container">
				{
					shouldPrevBtnExist &&
					<button
						className={classNames(styles['MagicPaginationNav-button'], {
							'tw-invisible': !shouldShowPrevBtn && !autoCollapsePrevBtn,
							'tw-hidden': !shouldShowPrevBtn && autoCollapsePrevBtn,
						})}
						data-tr-link-event-name="navigation to previous results page"
						disabled={!shouldShowPrevBtn}
						aria-disabled={!shouldShowPrevBtn}
						aria-label="previous page"
						data-qa="previous-page"
						onClick={event => this.onClickHandler(event, model.actualCurrentPage - 1)}
					>&lt;</button>
				}
				{
					[...Array(visiblePages)].map((visiblePage, i) => {
						const page = i + currentBlock;
						const isCurrentPage = page === currentPage;
						const btnProps = {
							...(isCurrentPage && {
								'aria-current': 'page',
							}),
							'aria-label': `go to page ${page}`,
							'aria-pressed': isCurrentPage,
							'ref': (elem) => { model.buttonRefs[i] = { page, elem, }; },
							'className': classNames(styles['MagicPaginationNav-button'], {
								'tw-bg-gray-300': isCurrentPage,
								'tw-text-white': isCurrentPage,
							}),
							'data-tr-link-event-name': `navigation to results page ${page}`,
							'data-qa': `page-${page}-button`,
							'onClick': event => this.onClickHandler(event, page)
						};

						if (page > pageCount) {
							return null;
						}
						return <button key={`page-btn-${page}`} {...btnProps}>{page}</button>;
					})
				}
				{
					shouldNextBtnExist &&
					<button
						className={classNames(styles['MagicPaginationNav-button'], {
							'u-visibility-hidden': !shouldShowNextBtn && !autoCollapseNextBtn,
							'tw-hidden': !shouldShowNextBtn && autoCollapseNextBtn
						})}
						aria-label="next page"
						data-tr-link-event-name="navigation to next results page"
						disabled={!shouldShowNextBtn}
						aria-disabled={!shouldShowNextBtn}
						data-qa="next-page"
						onClick={event => this.onClickHandler(event, model.actualCurrentPage + 1)}
					>
						&gt;
					</button>
				}
			</nav>
		);
	}

	onClickHandler(event, page) {
		const { store } = this.props;
		const { model } = store;

		store?.onClickHandler?.(event, page);
		this.setState({
			shouldShowNextBtn: model.shouldShowNextBtn,
			shouldShowPrevBtn: model.shouldShowPrevBtn,
		});
	}
});
