import { observable } from 'mobx';

import { cartNumberInterceptor } from '~/util/formz/interceptors/validationInterceptors';
import { ValidateAndAjaxSubmitPlugin } from '~/util/formz/plugins/ValidateAndAjaxSubmitPlugin';

const cartNumberLookupFormModel = () => {
	const formModel = {
		cartNumber: '',
	};

	const obsModel = observable(formModel);
	cartNumberInterceptor(obsModel, 'cartNumber');
	return obsModel;
};

const cartNumberLookupFormSettings = (store) => {
	const formSettings = {
		id: 'cartNumberLookupForm',
		settings: {
			plugins: [
				new ValidateAndAjaxSubmitPlugin({
					ajaxSubmit: {
						submitHandler() {
							const saveSuccessfull = store.save();
							return saveSuccessfull;
						},
					},
				}),
			],
		},
		fields: {
			cartNumber: {
				control: {
					reactProps: {
						'type': 'text',
						'pattern': '[0-9a-zA-Z-]*',
						'maxLength': 16,
						'data-qa': 'cart-number-control',
					},
				},
				label: {
					reactProps: {
						children: 'Cart #',
					},
				},
				settings: {
					validationConstraints: {
						presence: true,
						printableAscii: true,
						length: {
							minimum: 2,
							maximum: 16,
						},
						format: {
							pattern: '[0-9a-z-]*',
							flags: 'i',
							message: '^Enter a valid cart number',
						},
					},
				},
			},
		},
	};

	return formSettings;
};

export { cartNumberLookupFormModel, cartNumberLookupFormSettings };
