import React from 'react';
import { observer } from 'mobx-react';

import { GiftCardCameraScanner } from '~/engage/gift-card-scanner/Components/GiftCardCameraScanner';

export const CameraScanningView = observer((props) => {
	return (
		<GiftCardCameraScanner {...props} />
	);
});
