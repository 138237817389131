import React from 'react';
import cn from 'classnames';

export const FinanceActionRequiredMessage = ({ inline = false }: { inline?: boolean }) => {
	return (
		<div className={cn({ 'tw-text-red': inline })}>
			Refund must be determined by Customer Care and entered directly into SAP.
		</div>
	);
};
