import { Button } from '~/components/Buttons/Components/Button';
import { useGlobalContext } from '~/global/Contexts/Global.context';

export const SuccessView = () => {
	const { magicModal } = useGlobalContext();

	return (
		<div className="tw-space-y-4">
			<p>The customer is signed up for Room & Board text messages</p>
			<Button type="button" onClick={magicModal.closeModal}>Close</Button>
		</div>
	);
};
