import React from 'react';
import cn from 'classnames';

export const FinancialTransactionTerminalBusyMessage = ({ inline }: { inline?: boolean }) => {
	return (
		<div className={cn({ 'tw-text-red': inline })}>
			Terminal busy. Please select another terminal.
		</div>
	);
};
