import { observable } from 'mobx';

import { ValidateAndAjaxSubmitPlugin } from '~/util/formz/plugins/ValidateAndAjaxSubmitPlugin';

const phoneLookupFormModel = () => {
	const formModel = {
		phone: '',
	};

	return observable(formModel);
};

const phoneLookupFormSettings = (store) => {
	const formSettings = {
		id: 'phoneLookupForm',
		settings: {
			plugins: [
				new ValidateAndAjaxSubmitPlugin({
					ajaxSubmit: {
						submitHandler() {
							const saveSuccessfull = store.save();
							return saveSuccessfull;
						},
					},
				}),
			],
		},
		fields: {
			phone: {
				control: {
					reactProps: {
						type: 'tel',
						autoComplete: 'tel-national',
						size: 25,
						maxLength: 12,
						pattern: '[0-9]*',
						noValidate: true,
					},
				},
				label: {
					reactProps: {
						children: 'Phone',
					},
				},
				settings: {
					renderTypeOverride: 'PhoneNumberAndTypeFieldRenderer',
					validationConstraints: {
						presence: {
							message: '^Enter your phone',
						},
						phoneUS: true,
						length: {
							is: 12,
							message: '^Enter your phone',
						},
					},
					plugins: [
						{
							pluginId: 'maskJQ',
							settings: {
								mask: '999-999-9999',
								pattern: '[0-9]*',
							},
						},
					],
				},
			},
		},
	};

	return formSettings;
};

export { phoneLookupFormModel, phoneLookupFormSettings };
