import React from 'react';
import cn from 'classnames';

const currency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export const PaymentsExceedAmountDueMessage = (
	{ values, inline = false }: { values: { orderTotal?: number }, inline?: boolean }
) => {
	const orderTotal = values.orderTotal === undefined ? 'order total' : currency.format(values.orderTotal);

	return (
		<p className={cn({ 'tw-text-red tw-mb-0': inline })}>
			Payments applied exceed order total. Remove excess payments until total equals {orderTotal}.
		</p>
	);
};
