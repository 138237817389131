import { toJS, makeAutoObservable } from 'mobx';

import { hashObject } from '~/util/hashObject';

class SearchResultModel {
	sapCustomerNumber: Promise<string> | undefined;

	guestCartName: string | undefined;

	primaryEmail: string | undefined;

	businessName: string | undefined;

	constructor(searchResultData: any) {
		makeAutoObservable(this);

		Object.assign(this, toJS(searchResultData));
	}

	get id() {
		return this.sapCustomerNumber || hashObject('searchResult');
	}

	get webPrimaryName() {
		if (this.guestCartName) {
			return this.guestCartName;
		}
		return this.primaryEmail;
	}

	get customerName() {
		return `${this.firstName || ''} ${this.lastName || ''}`;
	}

	get displayName() {
		return typeof this.businessName === 'undefined' ? this.customerName : this.businessName;
	}

	get businessContactName() {
		return typeof this.businessName === 'undefined' ? null : this.customerName;
	}

	firstName = '';

	lastName = '';
}

export { SearchResultModel };
