import React from 'react';

import { s7ContentPath } from '~/global/global.constants';

export const BlockedCustomerErrorMessage = () => {
	return (
		<div>
			<img className="tw-w-4 tw-mr-3 tw-pb-1" src={`${s7ContentPath}/customer-blocked`} alt="Customer blocked."></img>
			Please contact leadership to access this account.
		</div>
	);
};
