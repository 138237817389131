import axios, { AxiosError } from 'axios';

import { isString } from '~/util/isString';
import { ErrorDto, isErrorDtoType } from '~/util/messaging/promise-error/error.constants';

export interface IPromiseErrorData extends ErrorDto {
	status: number
}

export const isPromiseErrorData = (data: unknown): data is IPromiseErrorData => {
	return typeof data === 'object' && data !== null && 'status' in data && 'errors' in data;
};

export class PromiseError {
	error: AxiosError | undefined;

	errorData: IPromiseErrorData | undefined;

	fallbackErrorMessage = 'An error has occurred.';

	get debugMessage() {
		if (isString(this.error)) {
			return this.error;
		}
		if (typeof this.errorData !== 'undefined') {
			const {
				errors: [{
					debugMessage = '',
				}] = [{}],
			} = this.errorData;

			return debugMessage;
		}
		if (this.error === null) {
			return '';
		}

		const {
			error: {
				response: {
					data: {
						errors: [
							{
								debugMessage = '',
							} = {},
						] = [],
					} = {} as any,
				} = {},
			} = {},
		} = this;

		return debugMessage;
	}

	get errorKey() {
		if (isString(this.error)) {
			return this.error;
		}
		if (typeof this.errorData !== 'undefined') {
			const {
				errors: [{
					errorKey = '',
				}] = [{}],
			} = this.errorData;

			return errorKey;
		}
		if (this.error === null) {
			return '';
		}
		const {
			error: {
				response: {
					data: {
						errors: [
							{
								errorKey = '',
							} = {},
						] = [],
					} = {} as any,
				} = {},
			} = {},
		} = this;

		return errorKey.trim();
	}

	get errorMessage() {
		if (isString(this.error)) {
			return this.error;
		}
		if (typeof this.errorData !== 'undefined') {
			const {
				errors: [{
					errorMessage = '',
				}] = [{}],
			} = this.errorData;

			return errorMessage;
		}
		const {
			error: {
				response: {
					data: {
						errors: [
							{
								errorMessage = '',
							} = {},
						] = [],
					} = {} as any,
				} = {},
				message,
			} = {},
		} = this;

		return errorMessage || message || this.fallbackErrorMessage;
	}

	get errorMessageObj() {
		return {
			messageKey: this.errorKey,
			messageValues: this.errorValues as object,
		};
	}

	get errorValues() {
		if (isString(this.error)) {
			return this.error;
		}
		if (typeof this.errorData !== 'undefined') {
			const {
				errors: [{
					errorValues = '',
				}] = [{}],
			} = this.errorData;

			return errorValues;
		}
		if (this.error === null) {
			return '';
		}
		const {
			error: {
				response: {
					data: {
						errors: [
							{
								errorValues = {},
							} = {},
						] = [],
					} = {} as any,
				} = {},
			} = {},
		} = this;

		return errorValues;
	}

	get data() {
		if (typeof this.errorData !== 'undefined') {
			if (isErrorDtoType(this.errorData)) {
				return this.errorData.errors;
			}
			// @ts-ignore
			return this.errorData.errors[0];
		}

		const {
			error: {
				response: {
					data = undefined,
				} = {},
			} = {},
		} = this;

		return data;
	}

	get isFallbackErrorMessage() {
		return this.errorMessage === this.fallbackErrorMessage;
	}

	get status() {
		if (typeof this.errorData !== 'undefined') {
			return this.errorData.status;
		}

		const {
			error: {
				response: {
					status = undefined,
				} = {},
			} = {},
		} = this;

		return status;
	}

	constructor(error: AxiosError | IPromiseErrorData) {
		if (!error) {
			throw new Error('No error found.');
		}

		if (axios.isAxiosError(error)) {
			this.error = error as AxiosError;
		} else if (isPromiseErrorData(error)) {
			this.errorData = error;
		}
	}
}
