import axios, { type AxiosPromise } from 'axios';
import {
	model, Model, prop, idProp, getRoot, modelFlow, _await, _async,
} from 'mobx-keystone';
import { observable } from 'mobx';

import type { OpportunitiesStore } from '~/engage/opportunities/Stores/Opportunities.store.root';
import type { IFormSettings } from '~/util/formz/Interfaces/FormSettings.interface';
import { ValidateAndAjaxSubmitPlugin } from '~/util/formz/plugins/ValidateAndAjaxSubmitPlugin';
import { modelNamespace } from '~/util/modelNamespace';
import { PromiseError } from '~/util/messaging/promise-error/PromiseError';

type Form = {
	fields: {
		notes: object
	}
	model: object
	reactProps: object
};

@model(`${modelNamespace.OPPORTUNITIES}/OpportunityNotesStore`)
export class OpportunityNotesStore extends Model({
	id: idProp,
	notes: prop<string | undefined>(),
	opportunityNotesLink: prop<string | undefined>(),
}) {
	form: unknown;

	get formModel() {
		return {
			notes: this.notes,
		};
	}

	get formSettings(): IFormSettings {
		const self = this;

		return {
			id: 'opportunityNotesForm',
			reactProps: {
				className: 'tw-space-y-6',
				method: 'put',
			},
			settings: {
				plugins: [
					new ValidateAndAjaxSubmitPlugin({
						ajaxSubmit: {
							promiseHandler: self.promiseHandler.bind(self),
							submitHandler: self.submitHandler.bind(self),
						},
					}),
				],
			},
			fields: {
				notes: {
					control: {
						reactProps: {
							autoComplete: 'off',
							className: 'tw-w-full tw-h-48',
							maxLength: 500,
							type: 'textarea',
						},
					},
					label: {
						reactProps: {
							children: 'Notes (optional):',
						},
					},
					settings: {
						plugins: [{
							pluginId: 'maxlengthRemainingCountdown',
						}],
						validationConstraints: {
							length: {
								maximum: 500,
							},
							printableAscii: true,
						},
					},
				},
			},
		};
	}

	hasForm(form: unknown): form is Form {
		return typeof form === 'object';
	}

	@observable.ref
	promiseError?: PromiseError;

	onSaveNotesFailure(error: unknown) {
		const rootStore = getRoot<OpportunitiesStore>(this);

		if (!axios.isAxiosError(error)) {
			console.error(error);
			return;
		}
		if (!rootStore) {
			throw new Error('No rootStore found.');
		}
		if (!rootStore.hasMagicOverlay(rootStore.magicOverlay)) {
			throw new Error('No magicOverlay found.');
		}
		this.promiseError = new PromiseError(error);
		rootStore.magicOverlay.stopLoading();
	}

	@modelFlow
	onSaveNotesSuccess = _async(function* (this: OpportunityNotesStore) {
		const rootStore = getRoot<OpportunitiesStore>(this);

		if (!rootStore) {
			throw new Error('No rootStore found.');
		}
		if (!rootStore.hasMagicOverlay(rootStore.magicOverlay)) {
			throw new Error('No magicOverlay found.');
		}
		if (!rootStore.hasMagicModal(rootStore.magicModal)) {
			throw new Error('No magicModal found.');
		}
		try {
			yield* _await(rootStore.fetchPageData());
			rootStore.magicModal.closeModal();
			rootStore.magicOverlay.stopLoading();
		} catch (error: unknown) {
			if (axios.isAxiosError(error)) {
				rootStore.onError(error);
				return;
			}
			console.error(error);
		}
	});

	@modelFlow
	promiseHandler = _async(function* (this: OpportunityNotesStore, promise: AxiosPromise) {
		const rootStore = getRoot<OpportunitiesStore>(this);

		if (!rootStore) {
			throw new Error('No rootStore found.');
		}
		if (!rootStore.hasMagicOverlay(rootStore.magicOverlay)) {
			throw new Error('No magicOverlay found.');
		}
		const timeoutId = setTimeout(rootStore.magicOverlay.startLoading, 2000);

		try {
			yield* _await(promise);
			this.onSaveNotesSuccess();
		} catch (error: unknown) {
			this.onSaveNotesFailure(error);
		} finally {
			clearTimeout(timeoutId);
		}
	});

	submitHandler(form: unknown) {
		if (!this.hasForm(form)) {
			throw new Error('No form found.');
		}
		if (!this.opportunityNotesLink) {
			throw new Error('No opportunityNotesLink found.');
		}
		this.promiseError = undefined;
		return axios.put(this.opportunityNotesLink, form.model);
	}
}
