import {
	model, ExtendedModel, prop,
} from 'mobx-keystone';

import { IFormSettings } from '~/util/formz/Interfaces/FormSettings.interface';

import { modelNamespace } from '~/util/modelNamespace';
import { AbstractFormStore } from '~/util/abstract-form-store/AbstractForm.store';
import { textMessagingOptInFormSettings } from '~/engage/toolbar/set-up-online-access/text-messaging-opt-in/textMessagingOptInForm.settings';

type FormModel = {
	mobileNumber: string
	termsAgreement: boolean
};

@model(`${modelNamespace.TEXT_OPT_IN}/TextMessagingOptIn`)
export class TextMessagingOptIn extends ExtendedModel(AbstractFormStore, {
	marketingTextMessagingLink: prop<string>(),
}) {
	get formModel(): FormModel {
		return {
			mobileNumber: '',
			termsAgreement: false,
		};
	}

	get formSettings(): IFormSettings {
		return textMessagingOptInFormSettings(this.submitHandler.bind(this), this.promiseHandler.bind(this));
	}

	get payload() {
		const result = {
			mobileNumber: this.normalizedPhoneNumber,
			optInSource: 'ENGAGE',
		};

		return JSON.stringify(result);
	}

	get normalizedPhoneNumber() {
		return `1${this.form.model.mobileNumber.replaceAll('-', '')}`;
	}

	get saveUrl(): RequestInfo | URL {
		return this.marketingTextMessagingLink;
	}
}
