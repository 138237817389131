import React from 'react';
import { observer } from 'mobx-react';

import { BaseField } from '~/util/formz/Components/fields/BaseField';
import { getAccessibleReactProps } from '~/util/formz/util/util';

const UsCurrency = observer(class UsCurrency extends React.Component {
	componentDidMount() {
		this.props.field.plugins.usCurrency.applyUsCurrencyPlugin();
	}

	componentWillUnmount() {
		this.props.field.plugins.usCurrency.removeUsCurrencyPlugin();
	}

	render() {
		const inputProps = {
			...getAccessibleReactProps(this.props),
			...this.props.field.plugins.usCurrency.usCurrencyReactProps,
		};
		const {
			key,
			value,
			...theInputProps
		} = inputProps;

		return (
			<BaseField {...this.props}>
				<input key={key} {...theInputProps} />
			</BaseField>
		);
	}
});

export { UsCurrency };
