// RNB.Engage.CartSearch.settingsFactory
const cartSearchFormSettings = (submitHandler) => {
	return {
		id: 'oeCartSearch',
		reactProps: {
			onSubmit: submitHandler,
		},
		settings: {
			plugins: [
				{
					pluginId: 'formValidator',
					settings: {},
				},
			],
		},
		fields: {
			cartId: {
				control: {
					reactProps: {
						type: 'text',
						maxLength: 12,
					},
				},
				label: {
					reactProps: {
						children: 'Cart Number',
					},
				},
				settings: {
					plugins: [
						{
							pluginId: 'maskJQ',
							settings: {
								mask: 'AAAAAAA-AAAA',
							},
						},
					],
					validationConstraints: {
						presence: {
							message: '^Enter your cart number',
						},
						length: {
							maximum: 12,
						},
					},
				},
			},
		},
	};
};

export { cartSearchFormSettings };
