export const bootstrapLinks = {
	ADD_TO_DELIVERY: {
		creditCardPaymentToken: '/api/add-to-delivery/payments/credit-card/payment-token',
		prepare: '/api/add-to-delivery',
		startCheckout: '/api/checkout/start',
		steps: '/api/add-to-delivery/steps',
	},
	CHECKOUT: {
		creditCardPaymentToken: '/api/checkout/payments/credit-card/payment-token',
		orderConfirmationSteps: '/api/checkout/confirmation/steps',
		prepare: '/api/checkout/prepare',
		steps: '/api/checkout/steps',
	},
	CART: {
		shoppingCart: '/api/cart',
		saveForLaterCart: '/api/save-for-later',
		shareCart: '/api/cart/share/{cartToken}',
		mergeCart: '/api/cart/merge',
	},
	FAVORITES: {
		entry: '/api/favorites',
		sharedList: '/api/favorites/public',
	},
	OPPORTUNITIES: {
		entry: '/api/opportunities',
	},
	ORDER_DETAIL: {
		creditCardPaymentToken: '/api/account/payments/credit-card/payment-token',
		placedOrderPayment: '/api/account/orders/{orderNumber}/payment',
		placedOrderRefund: '/api/account/orders/{orderNumber}/refund',
		lockingStatus: '/api/orders/{orderNumber}/locking-status',
		addToDelivery: '/api/orders/{orderNumber}/edit/add-to-delivery',
		deliveryAddress: '/api/orders/{orderNumber}/edit/delivery-address',
		deliveryOptions: '/api/orders/{orderNumber}/edit/delivery-options',
		itemsAndPricing: '/api/orders/{orderNumber}/edit/items-and-pricing',
	},
} as const;
