import React from 'react';
import cn from 'classnames';

export const FinancialTransactionDeclinedMessage = ({ inline }: { inline?: boolean }) => {
	return (
		<div className={cn({ 'tw-text-red': inline })}>
			Credit card declined. Please use a different card.
		</div>
	);
};
