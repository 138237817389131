import React from 'react';
import Link from 'next/link';

import { ROUTES, isEngageRoutesType } from '~/checkout/Types/constants';

export const ReturnToBillingPaymentButton = () => {
	if (isEngageRoutesType(ROUTES)) {
		return null;
	}
	return (
		<Link
			className="Button tw-mt-10 tw-mb-4"
			href={ROUTES.CHECKOUT_FLOW.BILLING_PAYMENT.pathname}
		>
			Return to Billing & Payment
		</Link>
	);
};
