import React from 'react';
import cn from 'classnames';

export const GiftCardInvalidProductErrorMessage = ({ inline }: { inline: boolean }) => {
	return (
		<div className={cn({ 'tw-text-red': inline })}>
			An error occurred. Please remove gift cards from cart and try again. If the problem persists, try a new
			gift card or open a Help Desk ticket.
		</div>
	);
};
