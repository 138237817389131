import { observable } from 'mobx';

import { ValidateAndAjaxSubmitPlugin } from '~/util/formz/plugins/ValidateAndAjaxSubmitPlugin';

const orderNumberLookupFormModel = () => {
	const formModel = {
		orderNumber: '',
	};

	return observable(formModel);
};

const orderNumberLookupFormSettings = (store) => {
	const formSettings = {
		id: 'orderNumberLookupForm',
		settings: {
			plugins: [
				new ValidateAndAjaxSubmitPlugin({
					ajaxSubmit: {
						submitHandler() {
							const saveSuccessfull = store.save();
							return saveSuccessfull;
						},
					},
				}),
			],
		},
		fields: {
			orderNumber: {
				control: {
					reactProps: {
						'type': 'text',
						'pattern': '[0-9]*',
						'maxLength': 10,
						'minLength': 6,
						'data-qa': 'order-number-control',
					},
				},
				label: {
					reactProps: {
						children: 'Order #',
					},
				},
				settings: {
					plugins: [
						{
							pluginId: 'number',
						},
					],
					validationConstraints: {
						presence: {
							message: '^Enter your order #',
						},
						numericality: true,
						length: {
							minimum: 6,
							maximum: 10,
							message: '^Order # is too short',
						},
					},
				},
			},
		},
	};

	return formSettings;
};

export { orderNumberLookupFormModel, orderNumberLookupFormSettings };
