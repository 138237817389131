import React from 'react';
import cn from 'classnames';

import { isEngage } from '~/global/global.constants';

export const GiftCardTemporarilyBlockedMessage = ({ inline = false }: { inline?: boolean }) => {
	if (isEngage) {
		return (
			<div className={cn({ 'tw-text-red': inline })}>
				For security purposes, this card has been blocked for 24 hours. Please open a Help Desk ticket.
			</div>
		);
	}
	return (
		<div className={cn({ 'tw-text-red': inline })}>
			For security purposes, this card has been blocked for 24 hours. For help, please contact Customer Care at
			{' '}
			<a href="tel:8003019720">800.301.9720</a>.
		</div>
	);
};
