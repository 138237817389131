import { useEffect } from 'react';
import { observer } from 'mobx-react';

import { SetUpOnlineAccessStore } from '~/engage/toolbar/set-up-online-access/SetUpOnlineAccess.store';
import { ViewState } from '~/util/viewState/Components/ViewState';
import { MagicOverlay } from '~/components/magic-overlay/Components/MagicOverlay';
import { ToolbarStoreProvider } from '~/engage/toolbar/Contexts/EngageToolbar.context';

export const SetUpOnlineAccess = observer(({ store }: { store: SetUpOnlineAccessStore }) => {
	useEffect(() => (() => {
		store.reactionDisposers.forEach(disposer => disposer());
	}), []);

	if (!store) {
		return null;
	}

	// @ts-ignore
	global.store = store;
	return (
		<ToolbarStoreProvider>
			<div className="tw-relative">
				<MagicOverlay store={store.magicOverlayStore} />
				<ViewState viewState={store.viewState} />
			</div>
		</ToolbarStoreProvider>
	);
});
