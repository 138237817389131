import React from 'react';
import { observer } from 'mobx-react';

// RNB.Engage.ShopSearch.Renderers.exceededMax
const ExceededMax = observer(class ExceededMax extends React.Component {
	render() {
		return (
			<div className={this.props.classes}>
				<strong className="error-text">More than 50 results found. </strong>
				<span>Use the results shown to help </span>
				<button className="button-as-anchor" onClick={this.props.tryAgainHandler}>narrow your search</button>
				<span>.</span>
			</div>
		);
	}
});

export { ExceededMax };
