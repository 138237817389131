import React from 'react';

import { isEngage } from '~/global/global.constants';

export const DuplicateGiftCardPaymentMessage = () => {
	if (isEngage) {
		return (
			<div className="tw-text-red">
				This card number has already been used on an order. If the problem persists, please open a Help Desk
				ticket.
			</div>
		);
	}
	return (
		<div className="tw-text-red">
			This card number has already been used on an order. For help, please contact Customer Care at{' '}
			<a href="tel:8003019720">800.301.9720</a>.
		</div>
	);
};
