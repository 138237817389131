import React from 'react';
import cn from 'classnames';

export const DeliveryAddressRestrictedByInventoryMessage = ({ inline }: { inline?: boolean }) => {
	return (
		<div className={cn({ 'tw-text-red': inline })}>
			At least one item on this order is at the local delivery center and the address cannot be updated in Engage.
			Please review the order and contact Customer Care.
		</div>
	);
};
