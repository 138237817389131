import React from 'react';

import { TextButton } from '~/components/Buttons/Components/TextButton';
import { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';

export const DuplicateQuoteContactErrorMessage = ({ values: { viewState } }: { values: { viewState: ViewStateStore }}) => {
	return (
		<div className="tw-text-red">
			{'Duplicate contact found. Please '}
			<TextButton
				type="button"
				data-qa="duplicate-contact-button"
				onClick={() => {
					if (viewState) {
						viewState.goTo('selectContact');
					}
				}}
			>
				select them from the list of existing contacts.
			</TextButton>
		</div>
	);
};
