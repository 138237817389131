'use client';

import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { isEngage } from '~/global/global.constants';

export const RoutingErrorMessage = observer(({ inline = false }: { inline?: boolean }) => {
	return (
		<div className={cn({ 'tw-text-red': inline })}>
			{
				isEngage
					?
					'Unable to save changes. Routing must be updated in SAP.'
					:
					<>
						An error has occurred. For help, please contact Customer Care at{' '}
						<a href="tel:8003019720">800.301.9720</a>.
					</>
			}
		</div>
	);
});
