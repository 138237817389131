import { computed, observable, makeObservable } from 'mobx';

export class RecentCustomersModal {
	cartImagesStores = [];

	modalResults;

	recentCustomerPages = [];

	recentCustomerPagesLink;

	pageNumber = 1;

	get pageIndex() {
		return this.pageNumber - 1;
	}

	get showPagination() {
		return this.recentCustomerPages.length > 1;
	}

	get totalPages() {
		return this.recentCustomerPages.length;
	}

	constructor(recentCustomerPagesLink) {
		makeObservable(this, {
			cartImagesStores: observable,
			modalResults: observable,
			recentCustomerPages: observable.ref,
			recentCustomerPagesLink: observable,
			pageNumber: observable,
			pageIndex: computed,
			showPagination: computed,
			totalPages: computed,
		});

		this.recentCustomerPagesLink = recentCustomerPagesLink;
	}
}
