export class NewCartEvent {
	checkForBackToBackDuplicates

	constructor() {
		this.checkForBackToBackDuplicates = false;
	}

	get trackingJson() {
		return {
			event: 'newcart',
		};
	}

	equalTo() {
		return false;
	}
}
