import React from 'react';
import { observer } from 'mobx-react';

import { ViewState } from '~/util/viewState/Components/ViewState';
import { MagicOverlay } from '~/components/magic-overlay/Components/MagicOverlay';

export const SellGiftCard = observer(({ overlay = {}, viewState = {} }) => {
	return (
		<div>
			<MagicOverlay store={overlay} containerSelector=".react-magic-modal-open" />
			<ViewState viewState={viewState} />
		</div>
	);
});
