import React from 'react';
import { observer } from 'mobx-react';

import type { OpportunitiesStore } from '~/engage/opportunities/Stores/Opportunities.store.root';
import { Button } from '~/components/Buttons/Components/Button';
import { TextButton } from '~/components/Buttons/Components/TextButton';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { PromiseErrorMessaging } from '~/util/messaging/promise-error/PromiseErrorMessaging';

export const ReassignOpportunityModal = observer(({ store }: { store: OpportunitiesStore }) => {
	const { magicModal } = useGlobalContext();
	const [yesBtnDisabled, setYesBtnDisabled] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (!store.reassignOpportunityPromiseError) {
			return;
		}
		setYesBtnDisabled(false);
	}, [store.reassignOpportunityPromiseError]);

	if (!store?.toolbarModel) {
		return null;
	}

	const {
		reassignOpportunityPromiseError,
		toolbarModel: {
			employeeName = 'someone else',
		},
	} = store;

	return (
		<div className="tw-space-y-6">
			<div>Are you sure you want to remove this opportunity from {employeeName} and assign it to you?</div>
			<div className="tw-space-x-6">
				<Button
					data-qa="yes-button"
					disabled={yesBtnDisabled}
					type="button"
					onClick={() => {
						setYesBtnDisabled(true);
						store.reassignOpportunity();
					}}
				>
					Yes
				</Button>
				<TextButton
					data-qa="cancel-button"
					type="button"
					onClick={magicModal.closeModal}
				>
					Cancel
				</TextButton>
			</div>
			{
				reassignOpportunityPromiseError !== undefined &&
				<PromiseErrorMessaging errorMessage={reassignOpportunityPromiseError.errorMessageObj} inline />
			}
		</div>
	);
});
