import React from 'react';
import { observer } from 'mobx-react';

import { isEmpty } from '~/util/isEmpty';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import type { Customer } from '~/engage/customer-search/search-results/Types/Customer.interface';
import type { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';

import styles from '~/engage/customer-search/search-results/customer-linking/Components/customer-search-results.module.scss';

interface Props {
	partialMatchFound: boolean
	searchResultsStore: any
	customer: Customer
	viewState: ViewStateStore
	readOnly: boolean
}

export const WebCustomer = observer((props: Props) => {
	const { HREF } = useGlobalContext();
	const {
		customer,
		readOnly = false,
	} = props;
	const SearchItem = readOnly ? 'div' : 'button';
	const searchItemProps: {'className': string, 'data-qa': string, 'data-track'?: string, onClick?: () => void} = {
		'className': `${styles['customer-search-result']} web-customer-search-result h-card`,
		'data-qa': 'search-result-item',
	};
	// Only one of these should be present.
	const proxyCustomerHref = customer?._links?.proxy?.href;
	const linkCustomerHref = customer?._links?.linkCurrentCustomer?.href;
	let activityDate;

	try {
		activityDate = new Intl.DateTimeFormat('en-US', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit'
		}).format(new Date(customer.lastActivityDate || customer.lastOrderDate || ''));
	} catch (error: unknown) {
		activityDate = 'Unknown';
	}

	if (!readOnly && (!isEmpty(proxyCustomerHref) || !isEmpty(linkCustomerHref))) {
		searchItemProps['data-track'] = 'Customer Selected';
		searchItemProps.onClick = () => {
			if (props.partialMatchFound && !isEmpty(linkCustomerHref)) {
				props.searchResultsStore.linkCurrentCustomerHandler(linkCustomerHref, HREF.cart);
			} else if (!isEmpty(proxyCustomerHref)) {
				props.searchResultsStore.proxyCustomer(proxyCustomerHref);
			}
		};
	}

	return (
		<SearchItem {...searchItemProps}>
			<div className={styles['link-status']} data-qa="link-status" />
			<div className={styles['customer-search-col']}>
				<div className={styles['p-name']} data-qa="customer-display-name">
					{customer.webPrimaryName}
				</div>
				<div className={styles['activity-date']}>
					Cart Activity Date: {customer.lastActivityDate ? activityDate : 'None'}
				</div>
			</div>
			<div className={styles['customer-search-col']}>
				<div className={styles['customer-number']} data-qa="customer-number">
					Customer Number: None
				</div>
			</div>
		</SearchItem>
	);
});
