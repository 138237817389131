import React from 'react';
import cn from 'classnames';

import { isEngage } from '~/global/global.constants';

export const SharepointConnectionErrorMessage = ({ inline = false }: { inline?: boolean }) => {
	return (
		<div className={cn({ 'tw-text-red': inline })}>
			{isEngage
				? 'A communication error occurred with SharePoint. Please try again in a few minutes.'
				: <>
					An error has occurred. For help, please contact Customer Care at{' '}
					<a href="tel:8003019720">800.301.9720</a>.
				</>
			}
		</div>
	);
};
