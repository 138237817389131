import { action, observable, makeObservable } from 'mobx';
import { fromPromise } from 'mobx-utils';
import axios from 'axios';

class CartImagesStore {
	constructor(options = {}) {
		const {
			index = null,
			link = null,
		} = options;

		makeObservable(this, {
			link: observable,
			index: observable,
			isOpen: observable,
			data: observable,
			loadCartImages: action,
		});

		this.index = index;
		this.link = link;
	}

	link = null;

	index = null;

	isOpen = false;

	data = null;

	loadCartImages() {
		if (this.data !== null) {
			return;
		}
		this.data = fromPromise(axios.get(this.link));
	}
}

export { CartImagesStore };
