import React from 'react';
import { observer } from 'mobx-react';

import styles from '~/engage/customer-search/search-results/customer-linking/Components/customer-search-results.module.scss';

const PartialWarningMessage = observer(() => {
	return (
		<li className={`${styles['customer-search-result-item']} ${styles['partial-warning-message']}`} data-qa="customer-search-result-item">
			<div className={styles['warning-symbol']}>
				&nbsp;
			</div>
			<div className={styles['warning-message-content']}>
				<strong>The delivery address entered partially matches the billing address of the account(s) below.</strong>
				{" Please select the correct customer account below and verify the customer's delivery address carefully."}
			</div>
		</li>
	);
});

export { PartialWarningMessage };
