import React from 'react';
import {
	toJS, observable, action, makeObservable
} from 'mobx';

import { SearchResultsModal } from '~/engage/shop-search/search-results/Components/SearchResultsModal';
import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import { quickSearchFormSettings } from '~/engage/shop-search/quick-search/Stores/quickSearchFormSettings';
import { SellGiftCardStoreFactory } from '~/engage/sell-gift-card/Stores/SellGiftCard.store';

class QuickSearchStore {
	featureTogglesModel

	model = {
		articleNumber: '',
	}

	globalDynamicStore

	magicModal

	form

	toolbarStore

	constructor() {
		makeObservable(this, {
			model: observable,
			globalDynamicStore: observable,
			magicModal: observable,
			form: observable,
			toolbarStore: observable,
			submitHandler: action.bound
		});
	}

	submitHandler(event) {
		const {
			featureTogglesModel,
			form: {
				model,
				model: {
					articleNumber,
				},
				plugins: {
					formValidator,
				},
			},
			globalDynamicStore,
			globalDynamicStore: {
				model: {
					addGiftCardToCartLink = '',
				} = {},
			},
			magicModal,
		} = this;
		const data = toJS(model);

		formValidator.validateForm();
		if (formValidator.hasErrors) {
			return;
		}
		this.toolbarStore.closeMenu();
		// Gift cards only
		if (articleNumber === '800800' && addGiftCardToCartLink) {
			setTimeout(() => {
				SellGiftCardStoreFactory.create(
					addGiftCardToCartLink,
					featureTogglesModel,
					magicModal,
					globalDynamicStore,
				);
			}, 500);
		} else {
			this.magicModal.openModal({
				maxWidth: 630,
				content: {
					children: (
						<SearchResultsModal
							searchParameters={data}
							toolbarStore={this.toolbarStore}
							magicModal={this.magicModal}
							globalDynamicStore={this.globalDynamicStore}
						/>
					)
				},
			}, event);
		}
	}
}

const QuickSearchStoreFactory = {
	create: (magicModal, toolbarStore, globalDynamicStore, featureTogglesModel) => {
		const instance = new QuickSearchStore();

		instance.form = new FormBuilder(instance.model, quickSearchFormSettings(instance.submitHandler));
		instance.magicModal = magicModal;
		instance.toolbarStore = toolbarStore;
		instance.globalDynamicStore = globalDynamicStore;
		instance.featureTogglesModel = featureTogglesModel;

		return instance;
	}
};

export { QuickSearchStoreFactory };
