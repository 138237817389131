import type { IFormSettings } from '~/util/formz/Interfaces/FormSettings.interface';
import { ValidateAndAjaxSubmitPlugin } from '~/util/formz/plugins/ValidateAndAjaxSubmitPlugin';

export const textMessagingOptInFormSettings = (
	submitHandler: (form: any) => Promise<Response>,
	promiseHandler: (promise: Promise<Response>) => void,
): IFormSettings => {
	return {
		id: 'textMessagingOptInForm',
		reactProps: {
			method: 'PUT',
			className: 'formz tw-pt-4',
		},
		settings: {
			plugins: [
				new ValidateAndAjaxSubmitPlugin({
					ajaxSubmit: {
						submitHandler,
						promiseHandler,
					},
				}),
			],
		},
		fields: {
			mobileNumber: {
				reactProps: {
					className: 'tw-mb-0',
				},
				control: {
					reactProps: {
						'data-qa': 'sms-mobile-number',
						'type': 'text',
						'className': 'tw-w-full md:tw-w-40',
						'inputMode': 'numeric',
						'autoComplete': 'off',
					},
				},
				label: {
					reactProps: {
						className: 'tw-shrink-0',
						children: 'Customer U.S. Mobile Number',
					},
				},
				settings: {
					plugins: [{
						pluginId: 'maskJQ',
						settings: {
							mask: '999-999-9999',
							pattern: '[0-9]*',
							enableTwoWayData: true,
						},
					}],
					validationConstraints: {
						length: {
							is: 12,
							message: '^Enter a valid phone number.',
						},
						phoneUS: true,
						presence: true,
					},
				},
			},
			termsAgreement: {
				control: {
					reactProps: {
						type: 'checkbox',
					},
				},
				label: {
					reactProps: {
						className: 'tw-font-normal',
						children: `
							By entering the customer's mobile number and checking this box, customer agrees to receive
							marketing text messages (e.g. new products, clearance events, design inspiration) from Room
							& Board.
						`,
					},
				},
				settings: {
					validationConstraints: {
						presence: true,
					},
				},
			},
		},
	};
};
