import { IEmployee, ILegacyOpportunityJSON } from '~/tracking/my-opportunity-events/Interfaces/IOpportunity.tracking';
import { IVisitorJSON } from '~/tracking/visitor-event/Interfaces/IVisitorEvent.tracking';

export class LegacyAddOpportunityTracking {
	customerId

	employee

	opportunityType

	visitorEvent

	constructor(customerId: number, opportunityType: string, employee: IEmployee, visitorEvent: IVisitorJSON) {
		this.customerId = customerId;
		this.opportunityType = opportunityType.replaceAll('_', ' ').toLowerCase();
		this.employee = employee;
		this.visitorEvent = visitorEvent;
	}

	get trackingJson(): ILegacyOpportunityJSON {
		return {
			event: 'addopp',
			...this.visitorEvent,
			oppType: this.opportunityType,
		};
	}
}
