import React from 'react';
import { observer } from 'mobx-react';

import type { OpportunityNotesStore } from '~/engage/opportunities/Stores/OpportunityNotes.store';
import { TextareaField } from '~/util/formz/Components/fields/TextareaField';
import { Button } from '~/components/Buttons/Components/Button';
import { TextButton } from '~/components/Buttons/Components/TextButton';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { ButtonVariant } from '~/components/Buttons/Types/constants';
import { PromiseErrorMessaging } from '~/util/messaging/promise-error/PromiseErrorMessaging';

export const AddEditNotesModal = observer(({ store }: { store: OpportunityNotesStore }) => {
	const { form } = store;

	if (!store.hasForm(form)) {
		return null;
	}
	const { fields } = form;
	const { magicModal } = useGlobalContext();

	return (
		<form {...form.reactProps}>
			<TextareaField field={fields.notes} />
			<div className="tw-space-x-6 tw-flex">
				<Button data-qa="save-note-button" variant={ButtonVariant.Support}>Save</Button>
				<TextButton
					data-qa="cancel-note-button"
					type="button"
					onClick={magicModal.closeModal}
				>
					Cancel
				</TextButton>
			</div>
			{
				store.promiseError !== undefined &&
				<PromiseErrorMessaging errorMessage={store.promiseError.errorMessageObj} inline />
			}
		</form>
	);
});
