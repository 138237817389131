'use client';

import React from 'react';
import cn from 'classnames';

export const AffirmActionRequiredMessage = ({ inline = false }: { inline?: boolean }) => {
	return (
		<div className={cn({ 'tw-text-red': inline })}>
			Refunds must be processed by Affirm then imported directly into SAP by Customer Care.
		</div>
	);
};
