import {
	model, Model, prop, idProp, ObjectMap,
} from 'mobx-keystone';

import type { OpportunityCategoryKey } from '~/engage/opportunities/Stores/Opportunities.store.root';
import type { OpportunityStore } from '~/engage/opportunities/Stores/Opportunity.store';
import { modelNamespace } from '~/util/modelNamespace';

@model(`${modelNamespace.OPPORTUNITIES}/OpportunitiesModel`)
export class OpportunitiesModel extends Model({
	id: idProp,
	categories: prop<ObjectMap<OpportunityStore[]>>(),
	sortedCategoryKeys: prop<OpportunityCategoryKey[]>(() => [
		'NEW_OPPORTUNITY',
		'FOLLOW_UP_REQUIRED',
		'PROJECTS_IN_PROGRESS',
		'PENDING_CUSTOMER_DECISION',
	]),
}) {}
