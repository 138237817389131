// RNB.Engage.Toolbar.Formz.incognitoLocationFormModel
const formModel = (defaults) => {
	const d = defaults || {};

	return {
		location: d.location || '',
	};
};

// RNB.Engage.Toolbar.Formz.incognitoLocationFormSettings
const formSettings = (changeHandler) => {
	return {
		id: 'profileLocationForm',
		reactProps: {
			key: 'create-account-form',
			method: 'POST',
			onSubmit: () => {
				return false;
			},
		},
		settings: {
			plugins: [
				{
					pluginId: 'validateAndAjaxSubmit',
					settings: {},
				},
			],
		},
		fields: {
			location: {
				control: {
					reactProps: {
						type: 'select',
						onChange(event) {
							event.preventDefault();
							changeHandler();
						},
					},
				},
				validationConstraints: {
					presence: {
						message: '^Select a location',
					},
				},
				labelNotRequired: true,
			},
		},
	};
};

export { formModel, formSettings };
