import React from 'react';
import { observer } from 'mobx-react';

import { SelectField } from '~/util/formz/Components/fields/SelectField';
import { LoadingSpinner } from '~/util/Components/LoadingSpinner';
import { ViewState } from '~/util/viewState/Components/ViewState';

const FORM_TITLES = {
	EMAIL: 'Email',
	PHONE: 'Phone',
	CUSTOMER_NUMBER: 'Customer #',
	RESIDENTIAL_ADVANCED: 'Residential Advanced',
	BUSINESS_ADVANCED: 'Business Advanced',
	ORDER_NUMBER: 'Order #',
	QUOTE_NUMBER: 'Quote #',
	CART_NUMBER: 'Cart #',
	PURCHASE_ORDER: 'Purchase Order',
};

const CustomerLookup = observer((props) => {
	const {
		store: {
			isLoading,
			hasServerError,
			viewState,
			searchTypes: {
				generalSearchTypes = [],
				businessSearchTypes = [],
			} = {},
			form: {
				fields: {
					formType = '',
				} = {},
			} = {},
		}
	} = props;

	if (isLoading) {
		return (
			<div className={'customer-search-type-loader'}>
				<LoadingSpinner minHeight={168} isLoading={isLoading} />
			</div>
		);
	}

	return (
		<div className="customer-search-form formz">
			<SelectField field={formType}>
				<optgroup label="General Search">
					{
						generalSearchTypes.map((option) => {
							if (option) {
								return <option key={option.type} value={option.type}>{FORM_TITLES[option.type]}</option>;
							}
							return null;
						})
					}
				</optgroup>
				<optgroup label="Business Customer">
					{
						businessSearchTypes.map((option) => {
							if (option) {
								return <option key={option.type} value={option.type}>{FORM_TITLES[option.type]}</option>;
							}
							return null;
						})
					}
				</optgroup>
			</SelectField>
			<ViewState viewState={viewState} />
			{
				hasServerError &&
				<div className="error">
					An unknown error occurred. Please try again.
				</div>
			}
		</div>
	);
});

export { CustomerLookup };
