import React from 'react';
import { observer } from 'mobx-react';

import { isEmpty } from '~/util/isEmpty';

const AsyncFormSubmitError = observer(class AsyncFormSubmitError extends React.Component {
	render() {
		const {
			form,
			errorAttrs = {},
			children = null,
		} = this.props;
		const formErrorMessages = form?.plugins?.formValidator?.formErrorMessages || [];

		if (isEmpty(formErrorMessages) || !this.props.showFormLevelErrors) {
			return null;
		}
		if (children) {
			// custom form error renderer
			return <div {...errorAttrs}>{children}</div>;
		}
		// default form error renderer
		return (
			<div {...errorAttrs}>
				{
					formErrorMessages.map(errorMessage => <div key={errorMessage.errorType + new Date().valueOf()}>{errorMessage.error}</div>)
				}
			</div>
		);
	}
});

export { AsyncFormSubmitError };
