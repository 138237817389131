import {
	action, observable, computed, makeObservable,
} from 'mobx';

import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import {
	advancedResidentialLookupFormModel,
	advancedResidentialLookupFormSettings,
} from '~/engage/toolbar/customer-side-menu/customer-lookup/Stores/AdvancedResidentialLookup.form.settings';

class AdvancedResidentialLookupStore {
	form;

	parentStore;

	reactions; // array of reactions to clean up

	constructor() {
		makeObservable(this, {
			form: observable,
			parentStore: observable,
			payload: computed,
			isFormValid: computed,
			destroy: action.bound,
			save: action.bound,
		});
	}

	get payload() {
		return {
			customerType: 'RESIDENTIAL',
			firstName: this.form.model.firstName,
			lastName: this.form.model.lastName,
			street: this.form.model.street,
			city: this.form.model.city,
			zipCode: this.form.model.zipCode,
		};
	}

	get isFormValid() {
		let valueCtr = 0;
		Object.values(this.form.model).forEach((value) => {
			if (value.replace(/[*]/g, '').length >= 2) {
				valueCtr++;
			}
		});

		return !this.form.plugins.formValidator.hasErrors && valueCtr >= 2;
	}

	destroy() {
		while (this.reactions.length > 0) {
			const reaction = this.reactions.pop();
			reaction();
		}
	}

	save() {
		if (this.isFormValid) {
			this.parentStore.save(this.payload);
		}
	}
}

const AdvancedResidentialLookupStoreFactory = {
	create: (parentStore) => {
		const instance = new AdvancedResidentialLookupStore();
		instance.parentStore = parentStore;
		instance.reactions = [];
		instance.form = new FormBuilder(
			advancedResidentialLookupFormModel(),
			advancedResidentialLookupFormSettings(instance),
		);

		return instance;
	},
};

export { AdvancedResidentialLookupStoreFactory };
