import React from 'react';
import cn from 'classnames';

export const LineItemSidemarkingExistsError = ({ inline = false }: { inline?: boolean }) => {
	return (
		<div className={cn({ 'tw-text-red': inline })}>
			Sidemarking already exists. Go to SAP and click the "Sidemark Items" checkbox to resolve the issue.
		</div>
	);
};
