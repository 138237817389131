import React from 'react';
import { observer } from 'mobx-react';

import { noop } from '~/util/noop';

export const PurchaseOrderSearchResultsError = observer(({ httpStatusCode = 404, tryAgainHandler = noop }) => {
	switch (httpStatusCode) {
		case 404:
		default:
			return (
				<div className="error-message" data-qa="order-search-results-message">
					<span>Purchase Order cannot be found. Please review what was entered and </span>
					<button
						type="button"
						onClick={tryAgainHandler}
					>
						try again
					</button>.
				</div>
			);
	}
});
