import type { QuaggaJSConfigObject } from '@ericblade/quagga2';
import { computed, observable, makeObservable } from 'mobx';

import { isCypress } from '~/global/global.constants';

export class GiftCardScannerModel {
	debugMode

	featureTogglesModel

	rawBarcode?: string

	scannerStarted?: boolean

	selectedDeviceId?: string

	hasError?: boolean

	hotspotFrameHeight?: number

	hotspotFrameWidth?: number

	hotspotFrameX?: number

	hotspotFrameY?: number

	showHotspotLabel?: boolean

	showVideo = false

	videoContainerElem?: HTMLDivElement

	videoInputDevices?: MediaDeviceInfo[]

	// Separating this out from giftCardNumber since this could be a different code at some point.
	get activationCode() {
		if (!this.rawBarcode) {
			// Need blank string since this is part of the form model
			return '';
		}
		return this.rawBarcode.substr(-19);
	}

	get backCameraInputDevice() {
		if (!this.videoInputDevices) {
			return null;
		}
		return this.videoInputDevices.find(device => /c920/i.test(device.label)) || {};
		// auto select ipad back facing camera
		// return this.videoInputDevices.find(device => /back/i.test(device.label)) || this.videoInputDevices[0] || {};
	}

	getScannerConfig(): QuaggaJSConfigObject {
		const self = this;
		const width = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
		const height = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

		if (this.useFileUpload) {
			return {
				inputStream: {
					size: 1000,
				},
				decoder: {
					readers: [{
						format: 'code_128_reader',
						// This needs to be here. Quagga does not properly null check.
						config: {
							supplements: [''],
						},
					}],
				},
				locate: false,
			};
		}
		return {
			inputStream: {
				name: 'Live',
				type: 'LiveStream',
				target: self.videoContainerElem,
				constraints: {
					width,
					height,
					deviceId: self.selectedDeviceId,
				},
				area: {
					top: '40%',
					right: '17%',
					bottom: '40%',
					left: '17%',
				},
			},
			locate: false,
			frequency: 10,
			numOfWorkers: 2,
			decoder: {
				readers: ['code_128_reader'],
				...(self.debugMode) && {
					debug: {
						drawBoundingBox: true,
						showFrequency: true,
						drawScanline: true,
						showPattern: true,
					},
				},
			},
		};
	}

	get cardUpc() {
		if (!this.rawBarcode) {
			// Need blank string since this is part of the form model
			return '';
		}
		return this.rawBarcode.substr(0, 11);
	}

	get giftCardNumber() {
		if (!this.rawBarcode) {
			// Need blank string since this is part of the form model
			return '';
		}
		return this.rawBarcode.substr(-19);
	}

	get isIpad() {
		const iPadMediaQuery = `
			only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait),
			only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape)
		`;
		const mql = window.matchMedia(iPadMediaQuery);

		return mql.matches && !isCypress;
	}

	get useFileUpload() {
		return this.debugMode && this.featureTogglesModel.isOn('USE_GIFT_CARD_SCANNING_FILE_UPLOAD');
	}

	constructor(featureTogglesModel: any) {
		if (!featureTogglesModel) {
			throw new Error('featureTogglesModel not found.');
		}
		makeObservable(this, {
			backCameraInputDevice: computed,
			hasError: observable,
			hotspotFrameHeight: observable,
			hotspotFrameWidth: observable,
			hotspotFrameX: observable,
			hotspotFrameY: observable,
			showHotspotLabel: observable,
			showVideo: observable,
			videoInputDevices: observable,
		});
		this.featureTogglesModel = featureTogglesModel;
		this.debugMode = this.featureTogglesModel.isOn('DEBUG_MODE');
	}
}
