import { action, observable, makeObservable } from 'mobx';
import axios from 'axios';

import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import { formSettings, formModel } from '~/engage/toolbar/incognito-location/Models/incognitoLocation.model';

// RNB.Engage.Toolbar.Incognito.formStore
class IncognitoLocationStore {
	constructor(link, defaults) {
		makeObservable(this, {
			form: observable.ref,
			isSubmitProcessing: observable,
			isSubmitted: observable,
			locations: observable,
			changeHandler: action.bound,
			fetchLocations: action.bound,
		});

		this.link = link;
		this.defaults = defaults;
	}

	form = {};

	isSubmitProcessing = false;

	isSubmitted = false;

	locations = [];

	changeHandler() {
		const selectedLocation = this.locations?.find?.(loc => loc.name === this.form.model.location);

		if (selectedLocation) {
			axios.post(selectedLocation._links.changeLocation.href).then(() => {
				window.location.reload();
			});
		}
	}

	fetchLocations() {
		return axios.get(this.link).then((response) => {
			this.locations = response.data;
		});
	}

	initForm() {
		this.form = new FormBuilder(formModel(this.defaults), formSettings(this.changeHandler));
	}
}

export { IncognitoLocationStore };
