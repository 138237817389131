import { FormValidatorPlugin } from '~/util/formz/plugins/formValidator/FormValidatorPlugin';

import styles from '~/engage/toolbar/side-menu/Components/shop-side-menu.legacy.module.scss';

// RNB.Engage.ShopSearch.quickSearchFormSettings
const quickSearchFormSettings = (submitHandler) => {
	return {
		id: 'oeShopQuickSearch',
		reactProps: {
			className: styles.oeShopQuickSearch,
			onSubmit(event) {
				event.preventDefault();
				submitHandler();
			},
		},
		settings: {
			plugins: [
				new FormValidatorPlugin(),
			],
		},
		fields: {
			articleNumber: {
				label: {
					reactProps: {
						children: 'Item Number',
					},
				},
				control: {
					reactProps: {
						type: 'number',
						pattern: '[0-9]*',
						min: 0,
						max: 999999999999999,
						inputMode: 'numeric',
					},
				},
				settings: {
					plugins: [
						{
							pluginId: 'number',
						},
					],
					validationConstraints: {
						presence: {
							message: '^Enter a valid article number',
						},
						numericality: true,
					},
				},
			},
		},
	};
};

export { quickSearchFormSettings };
