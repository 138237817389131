import React from 'react';
import { observer } from 'mobx-react';

import { CartSearchField } from '~/engage/cart-search/Components/CartSearchField';

// RNB.Engage.CartSearch.Renderer.cartSearchForm
const CartSearchForm = observer(class CartSearchForm extends React.Component {
	render() {
		const formStore = this.props.form;
		const {
			reactProps: {
				key,
				...formProps
			},
		} = formStore;

		return (
			<form key={key} {...formProps}>
				<CartSearchField form={formStore} />
				<input
					data-qa="cart-search-button"
					type="submit"
					className="ButtonTertiary"
					defaultValue="Go To Cart"
				/>
			</form>
		);
	}
});

export { CartSearchForm };
