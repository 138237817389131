import React from 'react';
import cn from 'classnames';

export const FinancialTransactionSapConnectionErrorMessage = ({ inline }: { inline?: boolean }) => {
	return (
		<div className={cn({ 'tw-text-red': inline })}>
			Connection error, please open a Help Desk ticket.
		</div>
	);
};
