import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import { MagicSpinner } from '~/components/MagicSpinner';
import { isEmpty } from '~/util/isEmpty';
import { CartImages } from '~/engage/recent-customers/Components/CartImages';
import { RecentCustomersModalStoreFactory } from '~/engage/recent-customers/Stores/RecentCustomersModal.store';
import { MagicPaginationNavStoreFactory } from '~/components/magic-pagination-nav/Stores/MagicPaginationNav.store';
import { MagicPaginationNav } from '~/components/magic-pagination-nav/Components/MagicPaginationNav';
import { isOnServer } from '~/global/global.constants';
import { debounce } from '~/util/debounce';
import { useGlobalContext } from '~/global/Contexts/Global.context';

import styles from '~/engage/toolbar/customer-side-menu/Components/recent-customers-modal.module.scss';

export const Modal = observer(({ link = '' }) => {
	const store = useRef({ model: {} });
	const [magicPaginationNavStore, setMagicPaginationNavStore] = useState({});
	const onPageClick = debounce((pageNumber) => {
		store.current.fetchRecentCustomersByPageNumber(pageNumber);
	}, 250);
	let modalResults = store.current.model?.modalResults;
	const { magicModal } = useGlobalContext();

	useEffect(() => {
		if (!link) {
			return;
		}
		store.current = RecentCustomersModalStoreFactory.create(link, magicModal);
		setMagicPaginationNavStore(MagicPaginationNavStoreFactory.create({
			onPageClick,
			model: {
				visiblePages: 3,
				pageCount: store.current.model?.totalPages || 0,
				currentPage: store.current.model?.pageNumber,
				autoCollapsePrevBtn: false,
				autoCollapseNextBtn: false,
			},
		}));
	}, []);
	useEffect(() => {
		if (isOnServer || isEmpty(magicPaginationNavStore)) {
			return;
		}
		magicPaginationNavStore.model.currentPage = store.current.model.pageNumber;
		magicPaginationNavStore.model.pageCount = store.current.model.totalPages;
	}, [store.current.model.pageNumber, store.current.model.totalPages]);

	modalResults = !isEmpty(modalResults) && modalResults.case({
		pending: () => <MagicSpinner minHeight="825px" parentSettings={{ width: '100%', height: '825px' }} isLoading />,
		fulfilled: (response) => {
			const recentCustomers = response?.data?.recentCustomers;
			const accordionItems = (
				recentCustomers?.map?.((recentCustomer, i) => {
					let employeeName = recentCustomer?.employeeName || null;
					const lastActivityDate = recentCustomer?.lastActivityDate || '';
					const proxyLink = recentCustomer?._links?.proxy?.href;
					const cartImagesStore = store.current.model.cartImagesStores?.find?.(ciStore => ciStore.index === i) || {};
					const controlClasses = classNames(`${styles['recent-more-control']} button-as-anchor`, {
						[styles['is-open']]: Boolean(cartImagesStore?.isOpen),
					});
					const dateTimeFormat = new Intl.DateTimeFormat('en-US', {
						year: 'numeric',
						month: '2-digit',
						day: '2-digit',
						hour: 'numeric',
						minute: '2-digit',
					});
					const formattedLastActivityDate = lastActivityDate ? dateTimeFormat.format(new Date(lastActivityDate)) : '';

					if (!isEmpty(employeeName)) {
						employeeName = <div className={`${styles['recent-more-entry']} ${styles['recent-more-employee-name']}`} data-qa="recent-more-employee-name">{employeeName}</div>;
					}
					return (
						<li key={`recent-more-item${i}`} className={styles['recent-more-accordion-item']} data-qa="recent-more-accordion-item">
							<div className={styles['recent-more-row']} data-qa="recent-more-row">
								<button
									data-track="Recent Customer Modal Selected"
									className={`${styles['recent-more-entry']} ${styles['recent-more-name']} button-as-anchor`}
									type="button"
									onClick={() => {
										if (!isEmpty(proxyLink)) {
											store.current.proxyRecentCustomer(proxyLink);
										}
									}}>{recentCustomer.name}</button>
								{employeeName}
								<div className={`${styles['recent-more-entry']} ${styles['recent-more-last-activity-date']}`}>
									{
										formattedLastActivityDate
											.replace((/am/i), 'a.m.')
											.replace((/pm/i), 'p.m.')
									}
								</div>
								<button className={controlClasses} type="button" onClick={() => {
									store.current.toggleCartImages(i);
								}} />
							</div>
							<CartImages isOpen={cartImagesStore.isOpen} cartImages={cartImagesStore.data} />
						</li>
					);
				})
			);

			if (accordionItems?.length) {
				return (
					<ol className={`${styles['recent-customers-accordion']} u-noListStyle tw-ml-0`}>
						{accordionItems}
					</ol>
				);
			}
			return <p>No customer data.</p>;
		},
		rejected: () => {
			store.current.model.hasErrors = true;
			return <p className="tw-text-red">We encounted an error. Please try again.</p>;
		},
	});

	return (
		<div className={styles['most-recent-customers-container']}>
			{modalResults}
			{
				store.current.model.showPagination &&
				<div className="pagination-container">
					<MagicPaginationNav store={magicPaginationNavStore} />
				</div>
			}
		</div>
	);
});
