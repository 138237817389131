class RecentCustomer {
	data;

	cartImages;

	constructor(data) {
		this.data = data;
	}

	get name() {
		return this.data.name;
	}

	get lastActivityDate() {
		return this.data.lastActivityDate;
	}

	// links
	get links() {
		return this.data._links || {};
	}

	get proxyLink() {
		return this.links.proxy?.href;
	}

	get cartImagesLink() {
		return this.links.cartImages?.href;
	}
}

export { RecentCustomer };
