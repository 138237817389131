import React from 'react';
import cn from 'classnames';

import { isEngage } from '~/global/global.constants';
import { ReturnToBillingPaymentButton } from '~/util/messaging/promise-error/ReturnToBillingPaymentButton';

interface Props {
	values: any
	inline: boolean
}
export const GiftCardVendorTimeoutMessage = ({ inline = false, values = {} }: Props) => {
	const showButton = values.showReturnToBillingPaymentButton;

	if (isEngage) {
		return (
			<div className={cn({ 'tw-text-red': inline })}>
				An unknown error occurred, please try again. If the problem persists, please open a Help Desk ticket.
			</div>
		);
	}
	return (
		<div className={cn({ 'tw-text-red': inline })}>
			{
				inline &&
				'There was an issue with your payment. '
			}
			For help, please contact Customer Care at <a href="tel:8003019720">800.301.9720</a>.
			{
				showButton &&
				<ReturnToBillingPaymentButton />
			}
		</div>
	);
};
