import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { AsyncFormSubmit } from '~/util/formz/Components/plugins/async-form-submit/AsyncFormSubmit';
import { Field } from '~/util/formz/Components/fields/Field';

import styles from '~/engage/available-delivery-dates/Components/delivery-dates-modal.module.scss';

export const DeliveryDatesForm = observer((props = {}) => {
	const {
		store: {
			form = {},
			form: {
				fields = {},
				model: {
					zipCode = '',
				} = {},
				plugins: {
					formValidator: {
						hasErrors = false,
					} = {},
				} = {},
			} = {},
		} = {},
	} = props;
	const disabled = hasErrors || zipCode.length < 5;
	const submitProps = {
		'id': 'delivery-date-lookup-button',
		'data-qa': 'delivery-date-lookup-button',
		'type': 'submit',
		'value': 'Submit',
		...(typeof props.submitProps !== 'undefined') && {
			...props.submitProps
		},
		'className': cn('Button', { disabled }),
		disabled,
	};

	return (
		<div>
			<div className={styles['delivery-dates-container']}>
				<AsyncFormSubmit form={form} />
				<form {...form.reactProps}>
					<Field field={fields.zipCode} showValidationMessage={false} />
					<input {...submitProps} />
				</form>
				<div className={styles['delivery-instructions']}>
					Enter delivery <span className="tw-uppercase">zip</span> code above to look up available delivery dates.
				</div>
			</div>
		</div>
	);
});
