import { action, observable, makeObservable } from 'mobx';
import { fromPromise } from 'mobx-utils';
import axios from 'axios';

import type { Customer } from '~/engage/customer-search/search-results/Types/Customer.interface';
import type { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';

export interface Details {
	city: string
	fullName: string
	primaryPhone?: string
	secondaryPhone?: string
	shoppingCartQty: number
	state: string
	street: string
	userName: string
	wishlistQty: number
	zipCode: string
}

// RNB.Engage.CustomerSearch.Stores.customerLink
class CustomerLinkingStore {
	constructor(customerToLink: Customer, viewState: ViewStateStore) {
		makeObservable(this, {
			customerToLink: observable,
			viewState: observable,
			results: observable,
			loadCustomerLinkData: action,
			linkCustomer: action,
		});

		this.customerToLink = customerToLink;
		this.viewState = viewState;
	}

	customerToLink;

	viewState;

	results: any;

	loadCustomerLinkData() {
		const linkableCustomersHref = this.customerToLink?._links?.linkableCustomers?.href;
		const linkedCustomersHref = this.customerToLink?._links?.linkedCustomers?.href;
		const theHref = linkableCustomersHref || linkedCustomersHref || null;

		if (!theHref) {
			console.error('Cannot load customer link data. No href found.', this.customerToLink);
			this.results = null;
		} else {
			this.results = fromPromise(axios.get(theHref));
		}
	}

	linkCustomer(href: string, customer: Customer) {
		if (!href || !customer) {
			return false;
		}
		this.viewState.goTo('processing');
		return axios.post(href).then((resp) => {
			const detailsHref = resp?.data?._links?.proxiedCustomerDetails?.href;
			const proxyLandingPage = resp?.data?._links?.proxyLandingPage?.href;
			const params: {customer: Customer, proxyLandingPage: string, details?: Details} = {
				customer,
				proxyLandingPage,
			};

			if (href) {
				axios.get(detailsHref).then((resp2) => {
					params.details = resp2.data;
					this.viewState.goTo('linkCustomerSuccess', params);
				});
			} else {
				this.viewState.goTo('linkCustomerSuccess', params);
			}
		});
	}
}

export { CustomerLinkingStore };
