export const formatCurrency = (strValue, hideZeroCents, showNegativeAsParens) => {
	let value = strValue;

	if (value === 0 && hideZeroCents) {
		return '$0';
	}
	if (value === 0) {
		return '$0.00';
	}
	if (value) {
		if ((value === 'Call for pricing') || (value === 'Updating…')) {
			return value;
		}

		value = value.toString().replace(/[$,]/g, '');

		let dblValue = parseFloat(value);
		const blnSign = (dblValue === (dblValue = Math.abs(dblValue)));

		dblValue = Math.floor(dblValue * 100 + 0.50000000001);
		const intCents = dblValue % 100;
		let strCents = intCents.toString();

		dblValue = Math.floor(dblValue / 100).toString();
		if (intCents < 10) {
			strCents = `0${strCents}`;
		}
		for (let i = 0; i < Math.floor((dblValue.length - (1 + i)) / 3); i++) {
			dblValue = `${dblValue.substring(0, dblValue.length - (4 * i + 3))},${dblValue.substring(dblValue.length - (4 * i + 3))}`;
		}
		if (hideZeroCents) {
			return `${((blnSign) ? '' : '-')}$${dblValue}${(strCents === '00' ? '' : `.${strCents}`)}`;
		}
		if (showNegativeAsParens) {
			return `${((blnSign) ? '' : '(')}$${dblValue}.${strCents}${((blnSign) ? '' : ')')}`;
		}
		return `${((blnSign) ? '' : '-')}$${dblValue}.${strCents}`;
	}
	return 'Call for pricing';
};
