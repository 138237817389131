import React from 'react';

import { ReturnToBillingPaymentButton } from '~/util/messaging/promise-error/ReturnToBillingPaymentButton';

export const WebCreditCardDeclinedErrorMessage = () => {
	return <>
		<div>Please use a different card.</div>
		<ReturnToBillingPaymentButton />
	</>;
};
