import { type IFormSettings } from '~/util/formz/Interfaces/FormSettings.interface';
import { type SubmitHandler, type PromiseHandler } from '~/util/abstract-form-store/AbstractForm.store';
import { ValidateAndAjaxSubmitPlugin } from '~/util/formz/plugins/ValidateAndAjaxSubmitPlugin';
import { FormReactionsPlugin } from '~/util/formz/plugins/FormReactionsPlugin';

export const setUpOnlineAccessSettings = (
	submitHandler: SubmitHandler,
	promiseHandler: PromiseHandler,
	reactionSettings: (form: any) => void,
): IFormSettings => {
	return {
		id: 'createAccountForm',
		reactProps: {
			method: 'post',
		},
		settings: {
			plugins: [
				new ValidateAndAjaxSubmitPlugin({
					ajaxSubmit: {
						submitHandler,
						promiseHandler,
					},
				}),
				new FormReactionsPlugin({ reaction: reactionSettings }),
			],
		},
		fields: {
			username: {
				control: {
					reactProps: {
						type: 'email',
						autoComplete: 'email',
						className: 'tw-w-[290px]',
						maxLength: 241,
						size: 30,
					},
				},
				label: {
					reactProps: {
						children: 'Customer Email Address',
					},
				},
				settings: {
					renderTypeOverride: 'EmailFieldRenderer',
					validationConstraints: {
						presence: {
							message: '^Enter Customer Email Address',
						},
						email: {
							message: '^Please enter a valid email address.',
						},
						length: {
							maximum: 241,
						},
						printableAscii: true,
					},
				},
			},
			zipCode: {
				control: {
					reactProps: {
						'type': 'text',
						'autoComplete': 'postal-code',
						'className': 'tw-w-[145px]',
						'maxLength': 10,
						'size': 10,
						'noValidate': true,
						'data-qa': 'zip-code-field',
					},
				},
				label: {
					reactProps: {
						children: 'Customer ZIP Code',
					},
				},
				settings: {
					validationConstraints: {
						presence: {
							message: '^Enter Customer ZIP code',
						},
						zipCode: true,
						length: {
							minimum: 5,
							maximum: 10,
						},
						printableAscii: true,
					},
				},
			},
			optIn: {
				control: {
					reactProps: {
						type: 'checkbox',
					},
				},
				label: {
					reactProps: {
						children: 'Yes, opt in to receive emails about store events, product updates and design ideas.',
					},
				},
				settings: {
					labelNotRequired: true,
				},
			},
		},
	};
};
