import {
	model, Model, idProp, prop,
} from 'mobx-keystone';
import { toJS } from 'mobx';

import { modelNamespace } from '~/util/modelNamespace';
import { IVisitorJSON } from '~/tracking/visitor-event/Interfaces/IVisitorEvent.tracking';
import { BaseOpportunityTrackingModel } from '~/tracking/my-opportunity-events/BaseOpportunity.tracking.model';

export enum OPPORTUNITIES_EVENT_TYPE {
	ADD = 'addopp',
	REMOVE = 'removeopp',
	REASSIGN = 'reassignopp',
}

@model(`${modelNamespace.OPPORTUNITIES}/OpportunityEventModel`)
export class OpportunityTrackingModel extends Model({
	id: idProp,
	eventType: prop<OPPORTUNITIES_EVENT_TYPE>(),
	currentTrackingModel: prop<BaseOpportunityTrackingModel>(),
	originalTrackingModel: prop<BaseOpportunityTrackingModel | undefined>(),
	visitorEvent: prop<IVisitorJSON>(),
}) {
	get visitorJSON() {
		return toJS(this.visitorEvent);
	}

	get trackingJson() {
		return {
			event: this.eventType,
			...this.visitorJSON,
			opp: {
				...(this.currentTrackingModel) && {
					...this.currentTrackingModel.trackingJson,
				},
				...(this.originalTrackingModel) && {
					...this.originalTrackingModel.trackingJson,
				},
			},
		};
	}
}
