import {
	model, Model, prop, idProp,
} from 'mobx-keystone';

import { modelNamespace } from '~/util/modelNamespace';
import {
	opportunityCategories, OpportunityCategoryKey, TriggeredEmailTypeKey,
} from '~/engage/opportunities/Stores/Opportunities.store.root';
import type { TriggeredEmailModel } from '~/engage/opportunities/Models/TriggeredEmail.model';

@model(`${modelNamespace.OPPORTUNITIES}/OpportunityModel`)
export class OpportunityModel extends Model({
	id: idProp,
	category: prop<OpportunityCategoryKey>(),
	customerName: prop<string>(),
	customDateUpdated: prop<string>(),
	dateCreated: prop<string>(),
	moveToCategories: prop<OpportunityCategoryKey[]>(),
	triggeredEmails: prop<TriggeredEmailModel[] | undefined>(),
}) {
	readonly sortedTriggeredEmailKeys: TriggeredEmailTypeKey[] = [
		'GENERAL_CHECK_IN',
		'SAMPLE_FOLLOW_UP',
		'DESIGN_PRESENTATION_FOLLOW_UP',
		'FINAL_FOLLOW_UP',
	];

	get displayCategory() {
		return opportunityCategories[this.category];
	}

	get displayMoveToCategories() {
		return this.moveToCategories.map(category => opportunityCategories[category]);
	}

	get sortedTriggeredEmails() {
		return this.sortedTriggeredEmailKeys.map((emailKey) => {
			return this.triggeredEmails?.find(email => email.triggeredEmailType === emailKey);
		});
	}
}
