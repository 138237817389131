import React from 'react';
import { observer } from 'mobx-react';

import { Field } from '~/util/formz/Components/fields/Field';

import styles from '~/engage/available-delivery-dates/Components/delivery-dates-modal.module.scss';
import { useSafeSpread } from '~/hooks/useSafeSpread';

const ZipCodeForm = observer((props = {}) => {
	const {
		store: {
			form: {
				reactProps: formProps = {},
				fields: {
					zipCode: zipCodeField = {},
				} = {},
			} = {},
		} = {},
		submitProps: additionalSubmitProps = {},
	} = props;

	const submitProps = {
		'id': 'delivery-date-lookup-button',
		'data-qa': 'delivery-date-lookup-button',
		'type': 'submit',
		'className': 'Button',
		'value': 'Submit',
		...additionalSubmitProps,
	};

	const [safeFormProps, safeFormKey] = useSafeSpread(formProps);
	return (
		<div className={styles['edit-zip-form-container']}>
			<form key={safeFormKey} {...safeFormProps}>
				<Field field={zipCodeField} showValidationMessage={false} />
				<input {...submitProps} />
			</form>
		</div>
	);
});

const ZipCodeDisplay = observer((props = {}) => {
	const {
		store,
		store: {
			editMode,
			sendZipIfPresent,
		} = {},
		zipCode = '',
	} = props;

	if (!sendZipIfPresent) {
		return (
			<div className={styles['edit-zip-container']}>
				<span className="tw-uppercase">zip</span> Code: <strong data-qa="zip-holder">{zipCode.substring(0, 5)}</strong>
			</div>
		);
	}

	if (editMode) {
		return (
			<ZipCodeForm { ...props } />
		);
	}

	return (
		<div className={styles['edit-zip-container']}>
			<span className="tw-uppercase">zip</span> Code: <strong data-qa="zip-holder">{zipCode.substring(0, 5)}</strong>
			<button
				className="button-as-anchor"
				onClick={store.editZip}
				data-qa="edit-zip-delivery-date-btn"
			>Edit <span className="tw-uppercase">zip</span> Code</button>
		</div>
	);
});

export const DeliveryDatesResults = observer((props = {}) => {
	const {
		store: {
			datesToDisplay = [],
		} = {},
	} = props;

	if (!datesToDisplay.length) {
		return (
			<p className={styles['delivery-dates-disclaimer']}>
				Add items of interest to the cart to see available delivery dates by ZIP. Delivery availability is based
				on the volume of the items in the cart.
			</p>
		);
	}

	return (
		<div className={styles['delivery-dates-content']}>
			<ZipCodeDisplay { ...props } />
			{
				datesToDisplay.slice(0, 10).map((result, index) => {
					const {
						date = '',
						fromDate = '',
						toDate = '',
					} = result;
					const dateToUse = fromDate || date;
					let formattedDate = '';

					const dateOptions = {
						timeZone: 'UTC',
						weekday: 'short',
						month: 'short',
						day: '2-digit',
					};

					formattedDate = new Intl.DateTimeFormat('en-US', dateOptions).format(new Date(dateToUse));
					if (toDate && dateToUse !== toDate) {
						formattedDate += ` to ${new Intl.DateTimeFormat('en-US', dateOptions).format(new Date(toDate))}`;
					}

					return (
						<div key={`delivery-result-${index}`} className={styles['delivery-result']} data-qa="delivery-result">
							{`${formattedDate} `}
							{
								Boolean(result.stops) &&
								<span>({result.stops})</span>
							}
						</div>
					);
				})
			}
			<p className={styles['delivery-dates-disclaimer']}>
				Delivery dates estimated and not guaranteed until scheduled.
			</p>
		</div>
	);
});
