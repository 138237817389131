import React from 'react';
import { observer } from 'mobx-react';

// RNB.Engage.ShopSearch.Renderers.searchResultsError
const SearchResultsError = observer(class SearchResultsError extends React.Component {
	render() {
		const { error } = this.props;
		let statusText = error?.response?.statusText;
		const status = error?.response?.status;
		let errorMessage;

		switch (status) {
			case 403:
				statusText = error?.response?.data?.message;
				errorMessage = [
					<strong key="server-message" className="error-text">Too many results. </strong>,
					<button key="try-again-button" className="button-as-anchor" onClick={this.props.tryAgainHandler}>Try again</button>,
					<span key="error-text"> with different or more specific search terms.</span>,
				];
				break;
			default:
				errorMessage = <strong className="error-text">{statusText}</strong>;
				break;
		}
		return (
			<div className="shop-search-error-message">
				{errorMessage}
			</div>
		);
	}
});

export { SearchResultsError };
