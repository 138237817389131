import {
	action, observable, computed, makeObservable,
} from 'mobx';

import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import {
	cartNumberLookupFormModel,
	cartNumberLookupFormSettings,
} from '~/engage/toolbar/customer-side-menu/customer-lookup/Stores/CartNumberLookup.form.settings';

class CartNumberLookupStore {
	form;

	parentStore;

	constructor() {
		makeObservable(this, {
			form: observable,
			parentStore: observable,
			payload: computed,
			save: action.bound,
		});
	}

	get payload() {
		return {
			cartNumber: this.form.model.cartNumber,
		};
	}

	save() {
		this.parentStore.save(this.payload);
	}
}

const CartNumberLookupStoreFactory = {
	create: (parentStore) => {
		const instance = new CartNumberLookupStore();
		instance.parentStore = parentStore;
		instance.form = new FormBuilder(cartNumberLookupFormModel(), cartNumberLookupFormSettings(instance));

		return instance;
	},
};

export { CartNumberLookupStoreFactory };
