import {
	model, Model, prop, idProp,
} from 'mobx-keystone';

import { modelNamespace } from '~/util/modelNamespace';
import { TriggeredEmailTypeKey, triggeredEmailTypes } from '~/engage/opportunities/Stores/Opportunities.store.root';

@model(`${modelNamespace.OPPORTUNITIES}/TriggeredEmailModel`)
export class TriggeredEmailModel extends Model({
	id: idProp,
	triggeredEmailType: prop<TriggeredEmailTypeKey>(),
	dateSent: prop<string | undefined>(),
	email: prop<string | undefined>(),
	sendTriggeredEmailLink: prop<string | undefined>(),
}) {
	get displayTriggeredEmailType() {
		return triggeredEmailTypes[this.triggeredEmailType];
	}
}
