import React from 'react';
import { observer } from 'mobx-react';

import type { Customer } from '~/engage/customer-search/search-results/Types/Customer.interface';
import type { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';
import { isEmpty } from '~/util/isEmpty';
import { s7ContentPath } from '~/global/global.constants';
import { useGlobalContext } from '~/global/Contexts/Global.context';

import styles from '~/engage/customer-search/search-results/customer-linking/Components/customer-search-results.module.scss';

// linked single and multi are apparently the same image
const linkStatusIcons = {
	LINKED_SINGLE: { src: `${s7ContentPath}/customer-link`, alt: 'Customer linked.' },
	LINKED_MULTI: { src: `${s7ContentPath}/customer-link`, alt: 'Customer linked.' },
	SAP_LINKABLE: { src: `${s7ContentPath}/customer-potential-link`, alt: 'Customer potentially linked.' },
	BLOCKED: { src: `${s7ContentPath}/customer-blocked`, alt: 'Customer blocked.' }
};
// no icon for RESIDENTIAL
const customerGroupIcons = {
	BUSINESS: { src: `${s7ContentPath}/customer-business`, alt: 'Business customer.' },
};

interface Props {
	partialMatchFound?: boolean
	searchResultsStore?: any
	customer: Customer
	viewState?: ViewStateStore
	readOnly: boolean
	linkStatus?: any
}

export const SapCustomer = observer((props: Props) => {
	const { HREF } = useGlobalContext();
	const { customer } = props;
	const SearchResultItem = props.readOnly ? 'div' : 'button';
	const searchResultItemProps: {'className': string, 'data-qa': string, 'data-track'?: string, onClick?: () => void} = {
		'className': props.readOnly ? styles['is-read-only'] : '',
		'data-qa': 'search-result-item',
	};
	const searchResultClickHandler = () => {
		const proxyCustomerHref = customer?._links?.proxy?.href;
		const linkCustomerHref = customer?._links?.linkCurrentCustomer?.href;

		switch (customer.type) {
			case 'BLOCKED':
				break;
			case 'LINKED_SINGLE':
			case 'SAP_NOT_LINKABLE':
				if (props.partialMatchFound && !isEmpty(linkCustomerHref)) {
					props.searchResultsStore.linkCurrentCustomerHandler(linkCustomerHref, HREF.cart);
				} else if (proxyCustomerHref) {
					props.searchResultsStore.proxyCustomer(proxyCustomerHref);
				} else {
					console.error('Cannot proxy or link customer. No links found.');
				}
				break;
			case 'LINKED_MULTI':
			case 'SAP_LINKABLE':
				props.viewState?.goTo('linkCustomer', { customer });
				break;
			default:
				break;
		}
	};
	let activityDate;

	try {
		activityDate = new Intl.DateTimeFormat('en-US', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit'
		}).format(new Date(customer.lastOrderDate || ''));
	} catch (error: unknown) {
		activityDate = 'Unknown';
	}

	if (!props.readOnly) {
		searchResultItemProps.onClick = searchResultClickHandler;
		searchResultItemProps['data-track'] = 'Customer Selected';
	}

	return (
		<SearchResultItem {...searchResultItemProps}>
			<div className={`${styles['customer-search-result']} sap-customer-search-result h-card`}>
				{
					customer.type !== 'SAP_NOT_LINKABLE' && customer.type !== 'WEB' &&
					<div className={`${styles[`link-status-${customer.type}`]} ${styles['link-status']}`} data-qa={`link-status-${customer.type}`}>
						{/* eslint-disable-next-line jsx-a11y/alt-text */}
						<img {...linkStatusIcons[customer.type]} />
					</div>
				}
				<div className={styles['customer-search-col']}>
					{
						!isEmpty(customer.displayName) &&
						<div className={styles['p-name']} data-qa="customer-display-name">{customer.displayName}</div>
					}
					{
						!isEmpty(customer.businessContactName) &&
						<div>{customer.businessContactName}</div>
					}
					<div className={styles['p-email']} data-qa="p-email">{customer.primaryEmail}</div>
					<div>{customer.primaryPhone}</div>
					<div className={styles['activity-date']}>
						Last Order Date: {customer.lastOrderDate ? activityDate : 'None'}
					</div>
				</div>
				<div className={styles['customer-search-col']}>
					<div data-qa="customer-number">
						Customer Number: {customer.sapCustomerNumber || 'None'}
						{
							customer.customerGroup !== 'RESIDENTIAL' &&
							<div className={`customer-group-${customer.customerGroup} ${styles['customer-group']}`}>
								{/* eslint-disable-next-line jsx-a11y/alt-text */}
								<img {...customerGroupIcons[customer.customerGroup]} />
							</div>
						}
					</div>
					<div className={styles['p-street-address']}>{customer.address}</div>
					<div>{customer.zipcode}</div>
					<div className={styles['flags']}>
						{
							Boolean(customer.businessDiscount) &&
							<span data-qa="business-discount">Discount</span>
						}
						{
							Boolean(customer.businessPaymentTerms) &&
							<span data-qa="business-payment-terms">Payment Terms</span>
						}
						{
							Boolean(customer.taxExempt) &&
							<span data-qa="tax-exempt">Tax Exempt</span>
						}
					</div>
				</div>
			</div>
		</SearchResultItem>
	);
});
