import {
	computed, observable, makeObservable,
	action,
} from 'mobx';

export type OpenDeliverySummaryDto = {
	openDeliveryGroups: unknown[]
	_links: {
		refresh: {
			href: string
		}
	}
};

export class OpenDeliverySummaryModel {
	data?: OpenDeliverySummaryDto;

	openDeliveryGroupsLink;

	openDeliveryGroupsStatusLink;

	get hasOpenDeliveryGroups() {
		return Boolean(this.openDeliveryGroups.length);
	}

	get openDeliveryGroups() {
		const {
			data: {
				openDeliveryGroups = [],
			} = {},
		} = this;

		return openDeliveryGroups;
	}

	get refreshLink() {
		const {
			data: {
				_links: {
					refresh: {
						href = '',
					} = {},
				} = {},
			} = {},
		} = this;

		return href;
	}

	showTruckIcon?: boolean;

	workingWithName = '';

	setWorkingWithName(name: string) {
		this.workingWithName = name;
	}

	setShowTruckIcon(showTruckIcon: boolean) {
		this.showTruckIcon = showTruckIcon;
	}

	constructor(openDeliveryGroupsLink?: string, openDeliveryGroupsStatusLink?: string) {
		makeObservable(this, {
			data: observable.ref,
			hasOpenDeliveryGroups: computed,
			openDeliveryGroups: computed,
			showTruckIcon: observable,
			setShowTruckIcon: action.bound,
			refreshLink: computed,
		});
		this.openDeliveryGroupsStatusLink = openDeliveryGroupsStatusLink;
		this.openDeliveryGroupsLink = openDeliveryGroupsLink;
	}
}
