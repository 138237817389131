import React from 'react';

export const MaxAvsAttemptsReachedMessage = () => {
	return (
		<div data-qa="max-avs-attempts-message">
			The information you provided does not match the information on file with your credit card company. For help,
			please contact Customer Care at <a href="tel:8003019720">800.301.9720</a>.
		</div>
	);
};
