import React from 'react';
import cn from 'classnames';

export const FinancialTransactionTimedOutMessage = ({ inline }: { inline?: boolean }) => {
	return (
		<div className={cn({ 'tw-text-red': inline })}>
			Card transaction timed out. No charge/refund processed. Please try again.
		</div>
	);
};
