import React from 'react';
import cn from 'classnames';

import { isEngage } from '~/global/global.constants';

export const TransportationZoneForbiddenMessage = ({ inline }: { inline?: boolean }) => {
	return (
		<div className={cn({ 'tw-text-red': inline })}>
			<span>We cannot determine your specific delivery zone. </span>
			{
				!isEngage
					?
					<span>
						Please contact a Design Associate at 800.301.9720 within the United States, or +001 612.234.3049
						internationally.
					</span>
					:
					<span>If the problem persists, please open a Help Desk ticket.</span>
			}
		</div>
	);
};
