import {
	action, observable, computed, makeObservable,
} from 'mobx';

import { quoteNumberLookupFormModel, quoteNumberLookupFormSettings } from '~/engage/toolbar/customer-side-menu/customer-lookup/Stores/QuoteNumberLookup.form.settings';

import { FormBuilder } from '~/util/formz/builders/FormBuilder';

class QuoteNumberLookupStore {
	form;

	parentStore;

	constructor() {
		makeObservable(this, {
			form: observable,
			parentStore: observable,
			payload: computed,
			save: action.bound,
		});
	}

	get payload() {
		return {
			quoteNumber: this.form.model.quoteNumber,
		};
	}

	save() {
		this.parentStore.save(this.payload);
	}
}

const QuoteNumberLookupStoreFactory = {
	create: (parentStore) => {
		const instance = new QuoteNumberLookupStore();
		instance.parentStore = parentStore;
		instance.form = new FormBuilder(quoteNumberLookupFormModel(), quoteNumberLookupFormSettings(instance));

		return instance;
	},
};

export { QuoteNumberLookupStoreFactory };
