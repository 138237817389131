import React from 'react';
import { action, makeObservable } from 'mobx';
import cn from 'classnames';
import axios from 'axios';

import { SellGiftCardModel } from '~/engage/sell-gift-card/Models/SellGiftCard.model';
import { SellGiftCard } from '~/engage/sell-gift-card/Components/SellGiftCard';
import { SellGiftCardView } from '~/engage/sell-gift-card/Components/SellGiftCard.view';
import { CameraScanningView } from '~/engage/sell-gift-card/Components/CameraScanning.view';
import { GunScanningView } from '~/engage/sell-gift-card/Components/GunScanning.view';
import { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';
import { MagicOverlayStoreFactory } from '~/components/magic-overlay/Stores/MagicOverlay.store';
import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import { GiftCardScannerStoreFactory } from '~/engage/gift-card-scanner/Stores/GiftCardScanner.store';
import { noop } from '~/util/noop';
import { PromiseError } from '~/util/messaging/promise-error/PromiseError';
import sellGiftCardStyles from '~/engage/sell-gift-card/Components/SellGiftCard.module.scss';
import giftCardScannerStyles from '~/engage/gift-card-scanner/Components/GiftCardScanner.module.scss';
import { cartChangedAction } from '~/cart/Actions/cartChanged.action';

class SellGiftCardStore {
	globalDynamic

	magicModal

	model

	overlay

	scanner

	viewState

	viewStateConfig = {
		main: {
			component: SellGiftCardView,
			onEnter: (viewState, store) => {
				const {
					barcodeButtonClickHandler = noop,
					tryAgainClickHandler = noop,
				} = store;

				return {
					barcodeButtonClickHandler,
					model: store.model,
					tryAgainClickHandler,
				};
			},
		},
		cameraScanning: {
			component: CameraScanningView,
			onEnter: () => {
				this.scanner.reset();
				return {
					store: this.scanner,
				};
			},
		},
		gunScanning: {
			component: GunScanningView,
			onEnter: () => {
				const {
					cancelGunScannerClickHandler = noop,
				} = this;

				this.scanner.init();
				return {
					cancelGunScannerClickHandler,
				};
			},
			onExit: () => {
				this.scanner.cleanup();
			}
		}
	}

	viewStateModalConfig = {
		cameraScanning: {
			// Transition looks better without the current modal collapsing as the scanning overlay fades in.
			get height() {
				return document.querySelector('.MagicModal').offsetHeight;
			},
			showCloseButton: false,
		},
		gunScanning: {
			showCloseButton: false,
		},
	}

	viewStateOptions = {
		onAfterEnterEach: (viewState) => {
			this.alterModal(viewState.key);
			window.scrollTo(0, 0);
		},
	}

	alterModal(viewStateKey) {
		const modalConfig = this.viewStateModalConfig[viewStateKey];

		this.magicModal.alterModal({
			height: 'auto',
			title: 'Sell a Gift Card',
			showCloseButton: true,
			...(modalConfig) && {
				...modalConfig
			},
			id: cn(sellGiftCardStyles['SellGiftCard'], {
				[giftCardScannerStyles['GiftCardScanner']]: viewStateKey === 'cameraScanning',
			}),
		});
	}

	barcodeButtonClickHandler() {
		if (this.scanner.model.isIpad) {
			this.openCameraScannerOverlay();
		} else {
			this.viewState.goTo('gunScanning');
		}
	}

	cancelGunScannerClickHandler() {
		this.showBarcodeButton();
		this.model.form.updateModelValue('cardNumber', '');
		this.viewState.goTo('main');
	}

	hideBarcodeButton() {
		this.model.showBarcodeButton = false;
	}

	init() {
		this.viewState.goTo('main');
		this.magicModal.openModal({
			id: sellGiftCardStyles['SellGiftCard'],
			maxWidth: 470,
			title: 'Sell a Gift Card',
			content: {
				children: <SellGiftCard overlay={this.overlay} viewState={this.viewState} />,
			},
			onCloseModal: () => {
				this.scanner.cleanup();
			},
		});
	}

	onAddGiftCardToCartFailure(error) {
		this.model.error = error;
	}

	onAddGiftCardToCartSuccess() {
		if (cartChangedAction) {
			if (cartChangedAction.getNumListeners()) {
				cartChangedAction.dispatch();
			}
		}
		this.globalDynamic.fetchData();
		this.magicModal.closeModal();
	}

	onCloseScannerOverlay() {
		const {
			scanner: {
				model: {
					activationCode = '',
					cardUpc = '',
				} = {},
			} = {},
			model: {
				form: {
					model: formModel = {},
					plugins: {
						formValidator: {
							validateField = noop,
						} = {},
					} = {},
				} = {},
			} = {},
		} = this;
		const newFormData = {
			cardNumber: activationCode,
			cardUpc,
		};

		this.viewState.goTo('main');
		formModel.cardNumber = newFormData.cardNumber;
		formModel.cardUpc = newFormData.cardUpc;
		if (formModel.cardNumber.length) {
			validateField('cardNumber');
			this.hideBarcodeButton();
		} else {
			this.showBarcodeButton();
		}
	}

	onGunScannerSuccess() {
		this.model.form.model.cardNumber = this.scanner.model.giftCardNumber;
		this.model.form.model.cardUpc = this.scanner.model.cardUpc;
		this.hideBarcodeButton();
		this.viewState.goTo('main');
	}

	openCameraScannerOverlay() {
		this.viewState.goTo('cameraScanning', {
			formModel: this.model.form.model,
		});
	}

	async promiseHandler(promise) {
		try {
			await promise;
			this.onAddGiftCardToCartSuccess();
		} catch (error) {
			this.onAddGiftCardToCartFailure(error);
		}
	}

	showBarcodeButton() {
		this.model.showBarcodeButton = true;
	}

	submitHandler(form) {
		this.model.error = null;
		form.plugins.formValidator.validateForm();

		if (form.plugins.formValidator.hasErrors) {
			return Promise.reject(new PromiseError('Form is not valid.'));
		}
		const promise = axios.post(this.model.addGiftCardToCartLink, this.model.payload);

		this.overlay.startLoading(promise);
		return promise;
	}

	tryAgainClickHandler() {
		this.model.form.plugins.formValidator.validateForm();
		this.model.form.fields.amount.plugins.usCurrency.autoNumeric.set('');
		this.model.form.updateModelValue('amount', '');
		this.model.form.updateModelValue('cardNumber', '');
		this.model.form.updateModelValue('cardUpc', '');
		this.model.error = null;
		this.showBarcodeButton();
	}

	constructor() {
		makeObservable(this, {
			alterModal: action.bound,
			barcodeButtonClickHandler: action.bound,
			cancelGunScannerClickHandler: action.bound,
			hideBarcodeButton: action.bound,
			init: action.bound,
			onAddGiftCardToCartFailure: action.bound,
			onAddGiftCardToCartSuccess: action.bound,
			onCloseScannerOverlay: action.bound,
			onGunScannerSuccess: action.bound,
			openCameraScannerOverlay: action.bound,
			promiseHandler: action.bound,
			showBarcodeButton: action.bound,
			submitHandler: action.bound,
			tryAgainClickHandler: action.bound,
		});
	}
}

export const SellGiftCardStoreFactory = {
	create(addGiftCardToCartLink, featureTogglesModel, magicModal, globalDynamicStore) {
		if (!magicModal) {
			throw new Error('magicModal not found.');
		}
		if (!globalDynamicStore) {
			throw new Error('globalDynamicStore not found.');
		}
		const store = new SellGiftCardStore();

		// global.store = store;
		store.magicModal = magicModal;
		store.globalDynamic = globalDynamicStore;
		store.model = new SellGiftCardModel(addGiftCardToCartLink, store.submitHandler, store.promiseHandler);
		store.viewState = new ViewStateStore(store, store.viewStateConfig, store.viewStateOptions);
		store.overlay = MagicOverlayStoreFactory.create({ useOpaqueFrosty: true });
		store.model.form = new FormBuilder(store.model.formModel, store.model.formSettings);
		store.scanner = GiftCardScannerStoreFactory.create({
			featureTogglesModel,
			onCloseScannerOverlay: store.onCloseScannerOverlay,
			onGunScannerSuccess: store.onGunScannerSuccess,
		});

		store.init();
		return store;
	},
};
