import { autorun } from 'mobx';

import { isEmpty } from '~/util/isEmpty';
import { FormValidatorPlugin } from '~/util/formz/plugins/formValidator/FormValidatorPlugin';
import { FormReactionsPlugin } from '~/util/formz/plugins/FormReactionsPlugin';
import { isOnServer } from '~/global/global.constants';

// RNB.Engage.ShopSearch.advancedSearchFormSettings
const advancedSearchFormSettings = (submitHandler) => {
	return {
		id: 'oeShopAdvancedSearch',
		reactProps: {
			onSubmit(event) {
				event.preventDefault();
				submitHandler();
			},
		},
		settings: {
			plugins: [
				new FormValidatorPlugin(),
				new FormReactionsPlugin({
					reaction(form) {
						const disposer = autorun(() => {
							if (!isEmpty(form.model.productName)) {
								form.plugins.formValidator.validationMessages.delete('collectionName');
							}
							if (!isEmpty(form.model.collectionName)) {
								form.plugins.formValidator.validationMessages.delete('productName');
							}
						});
						if (isOnServer) {
							disposer();
						}
					},
				}),
			],
		},
		fields: {
			productName: {
				label: {
					reactProps: {
						children: 'Product Name',
					},
				},
				control: {
					reactProps: {
						type: 'text',
						autoComplete: 'off',
						maxLength: 80,
						size: 80,
					},
				},
				settings: {
					validationConstraints: {
						dependentPresence: {
							dependentModelKey: 'collectionName',
							validateIfDependentModelEmpty: true,
							message: '^Enter a Product Name',
						},
					},
				},
			},
			collectionName: {
				label: {
					reactProps: {
						children: 'Collection Name',
					},
				},
				control: {
					reactProps: {
						type: 'text',
						autoComplete: 'off',
						maxLength: 30,
						size: 30,
					},
				},
				settings: {
					validationConstraints: {
						dependentPresence: {
							dependentModelKey: 'productName',
							validateIfDependentModelEmpty: true,
							message: '^Enter a Collection Name',
						},
					},
				},
			},
		},
	};
};

export { advancedSearchFormSettings };
