import React from 'react';

import { isEngage } from '~/global/global.constants';

export const InvalidEmailErrorMessage = () => {
	return (
		<div className="tw-text-red">
			Please enter a valid email.
			{ isEngage &&
				<> If the problem persists, please open a Help Desk ticket.</>
			}
		</div>
	);
};
