import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { SearchResultsStoreFactory } from '~/engage/shop-search/search-results/Stores/SearchResults.store';
import { LoadingSpinner } from '~/util/Components/LoadingSpinner';
import { SearchResultsError } from '~/engage/shop-search/search-results/Components/SearchResultsError';
import { ExceededMax } from '~/engage/shop-search/search-results/Components/ExceededMax';
import { SearchResults } from '~/engage/shop-search/search-results/Components/SearchResults';

import styles from '~/engage/toolbar/side-menu/Components/shop-side-menu.legacy.module.scss';

export const SearchResultsModal = observer((props) => {
	const [store] = useState(SearchResultsStoreFactory.create(
		props.searchParameters,
		props.magicModal,
		props.toolbarStore,
		props.globalDynamicStore
	));

	const results = store.results.case({
		pending: () => (
			<div style={{ minHeight: '200px' }}>
				<LoadingSpinner parentSettings={{ width: '100%' }} isLoading />
			</div>
		),
		rejected: error => (
			<SearchResultsError tryAgainHandler={store.tryAgainHandler} error={error} />
		),
		fulfilled: () => {
			if (!store.results.value.productSearchResults.length) {
				return (
					<div className={styles['shop-search-error-message']}>
						<strong className="error-text">No results. </strong>
						<button className="button-as-anchor" onClick={store.tryAgainHandler}>Try again</button>
						<span> with different or more specific search terms.</span>
					</div>
				);
			}

			return (
				<div className={styles['shop-search-results']}>
					{
						Boolean(store.results.value.exceededMaxSearchResults) &&
						<ExceededMax classes={styles['exceededMaxTop']} tryAgainHandler={store.tryAgainHandler} />
					}
					<div className={styles['search-results-header']}>
						<div className={styles['item']}>Item</div>
						<div className={styles['status']}>Status</div>
						<div className={styles['unitPrice']}>Unit Price</div>
						<div className={styles['quantity']}>Quantity</div>
					</div>
					<SearchResults store={store} />
					{
						Boolean(store.results.value.exceededMaxSearchResults) &&
						<ExceededMax classes={styles['exceededMaxBottom']} tryAgainHandler={store.tryAgainHandler} />
					}
				</div>
			);
		}
	});

	return results;
});
