import classNames from 'classnames';
import React from 'react';

import { useGlobalContext } from '~/global/Contexts/Global.context';

interface Props {
	values: {
		resetPasswordClickHandler: (sendPasswordResetEmailLink: string) => void
	}
}
export const AccountNotFoundMessage = ({ values }: Props) => {
	const {
		globalStaticModel: {
			sendPasswordResetEmailLink = undefined,
		} = {},
	} = useGlobalContext();
	const { resetPasswordClickHandler } = values;

	return (
		<div
			data-qa="account-not-found-message"
			className={classNames('tw-text-red', {
				'tw-border tw-border-solid tw-border-red tw-p-4 tw-space-y-4': resetPasswordClickHandler,
			})}
		>
			<div>
				We cannot find an account with that email address and password combination.
			</div>
			{
				Boolean(sendPasswordResetEmailLink) && Boolean(resetPasswordClickHandler) &&
				<div>
					Please try again or{' '}
					<button
						type="button"
						className="button-as-anchor"
						onClick={() => resetPasswordClickHandler(sendPasswordResetEmailLink)}
					>
						reset your password
					</button>.
				</div>
			}
		</div>
	);
};
