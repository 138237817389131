import axios from 'axios';

import { isOnServer } from '~/global/global.constants';
import { AddProductToCartEvent } from '~/product/common/Models/AddToCartEvent.tracking';
import { NewCartEvent } from '~/product/common/Models/NewCartEvent.tracking.model';
import { TrackedCart } from '~/product/common/Models/TrackedCart.tracking.model';
import { TrackedProduct } from '~/product/common/Models/TrackedProduct.tracking';

export class SearchResultsProductEventsTrackingStore {
	globalDynamicModel

	constructor(globalDynamicModel = {}) {
		this.globalDynamicModel = globalDynamicModel;
	}

	trackNewCartCreated(href) {
		if (href) {
			const event = new NewCartEvent();
			this.pushToEventDataLayer(event.trackingJson);

			// this tells the server that this event has now been tracked
			axios.delete(href);
		}
	}

	trackAddToCart(addToCartData) {
		if (!isOnServer && window?.eventDataLayer) {
			const {
				cartProductGroups = [],
			} = addToCartData;

			const products = cartProductGroups.map((cartProductGroup) => {
				return cartProductGroup.cartProducts.map((cartProduct) => {
					const {
						analytics,
					} = cartProduct;

					return new TrackedProduct(
						analytics,
					);
				});
			}).flat();

			const cart = new TrackedCart(addToCartData, products);
			const event = new AddProductToCartEvent(addToCartData, products, cart);

			if (cart.wasNewCartCreated) {
				this.trackNewCartCreated(cart.cancelNewCartHref);
			}

			this.pushToEventDataLayer(event.trackingJson);
		}
	}

	pushToEventDataLayer(json) {
		if (!isOnServer && window?.eventDataLayer) {
			console.info(json);
			this.previousTrackingJsonString = JSON.stringify(json);
			window.eventDataLayer.push(json);
		}
	}
}
