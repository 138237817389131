import React from 'react';
import cn from 'classnames';
import Link from 'next/link';

type Props = {
	values?: {
		currentSapCustomerNumber?: string
		missingSapOrderNumber?: string
	}
	inline?: boolean
}

export const PlacedOrderNotFoundMessage = ({ values, inline }: Props) => {
	const {
		missingSapOrderNumber = '',
	} = values || {};
	const orderText = `Order ${missingSapOrderNumber}`.trim();

	return (
		<div className={cn('tw-space-y-4', { 'tw-text-red': inline })}>
			<div>
				{orderText} was not found in your account. Please review your Order History.
			</div>
			<Link href="/account/orders" className="Button">
				Show Order History
			</Link>
		</div>
	);
};
