import React from 'react';
import { observer } from 'mobx-react';

import type { OpportunityStore } from '~/engage/opportunities/Stores/Opportunity.store';
import { Button } from '~/components/Buttons/Components/Button';
import { TextButton } from '~/components/Buttons/Components/TextButton';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { PromiseErrorMessaging } from '~/util/messaging/promise-error/PromiseErrorMessaging';

export const ConfirmRemoveOpportunityModal = observer(({ store }: { store: OpportunityStore }) => {
	const { magicModal } = useGlobalContext();
	const [yesBtnDisabled, setYesBtnDisabled] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (!store.confirmRemoveOpportunityPromiseError) {
			return;
		}
		setYesBtnDisabled(false);
	}, [store.confirmRemoveOpportunityPromiseError]);

	return (
		<div className="tw-space-y-6">
			<div className="tw-space-x-6 tw-flex">
				<Button
					type="button"
					disabled={yesBtnDisabled}
					onClick={() => {
						setYesBtnDisabled(true);
						store.removeOpportunity();
					}}
					data-qa="yes-button"
				>
					Yes
				</Button>
				<TextButton type="button" onClick={magicModal.closeModal} data-qa="cancel-button">Cancel</TextButton>
			</div>
			{
				store.confirmRemoveOpportunityPromiseError !== undefined &&
				<PromiseErrorMessaging
					errorMessage={store.confirmRemoveOpportunityPromiseError.errorMessageObj}
					inline
				/>
			}
		</div>
	);
});
