import React from 'react';
import { observer } from 'mobx-react';

import styles from '~/engage/toolbar/side-menu/Components/shop-side-menu.legacy.module.scss';

const ItemsAddedModal = observer(class ItemsAddedModal extends React.Component {
	render() {
		return (
			<div className="items-added-modal">
				<p>Item(s) have been added to your customer’s cart. You can continue shopping or quick add more items.</p>
				<div className={`${styles['call-to-action-added-to-cart-modal']} ButtonGroup`}>
					<button
						className="Button"
						data-qa="search-again-button"
						onClick={this.props.store.addMoreHandler}
					>Search Again</button>
					<button className="ButtonAnchor" onClick={this.props.store.magicModal.closeModal}>Close</button>
				</div>
			</div>
		);
	}
});

export { ItemsAddedModal };
