import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import type { Customer } from '~/engage/customer-search/search-results/Types/Customer.interface';
import type { Details } from '~/engage/customer-search/search-results/customer-linking/Stores/CustomerLinking.store';

import styles from '~/engage/customer-search/search-results/customer-linking/Components/customer-search-results.module.scss';

export const LinkSuccess = observer((props: { customer: Customer; details: Details; }) => {
	const { magicModal } = useGlobalContext();
	const { details } = props;

	return (
		<div>
			<div className={styles['customer-search-results']} data-qa="customer-search-results">
				<div
					className={cn(
						styles['customer-search-result-list'],
						styles['customer-search-result'],
						'sap-customer-search-result',
						styles['customer-result-link-success'],
						'h-card',
					)}
					data-qa="customer-search-result-list"
				>
					<div className="customer-search-col">
						{
							Boolean(details) &&
							<div className="full-name"><strong>{details.fullName}</strong></div>
						}
						{
							Boolean(details) &&
							<div data-qa="p-email" className={styles['p-email']}>{details.userName}</div>
						}
						{
							Boolean(details) &&
							<div className={styles['address']}>
								<div>{details.street}</div>
								<div>{details.city}, {details.state} {details.zipCode}</div>
							</div>
						}
						{
							Boolean(details) && (details.primaryPhone || details.secondaryPhone) &&
							<div className={styles['phones']}>
								{
									Boolean(details.primaryPhone) &&
									<div>{details.primaryPhone}</div>
								}
								{
									Boolean(details.secondaryPhone) &&
									<div>{details.secondaryPhone}</div>
								}
							</div>
						}
					</div>
				</div>
			</div>
			<div className={styles['customer-search-footer']}>
				<button className="ButtonTertiary" onClick={() => {
					magicModal.closeModal();
				}}>Close and Continue</button>
			</div>
		</div>
	);
});
