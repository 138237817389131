import { observable } from 'mobx';

import { ValidateAndAjaxSubmitPlugin } from '~/util/formz/plugins/ValidateAndAjaxSubmitPlugin';

const emailLookupFormModel = (defaultModel?: { email?: string }) => {
	const formModel = {
		email: defaultModel?.email || '',
	};

	return observable(formModel);
};

const emailLookupFormSettings = (store: any) => {
	const formSettings = {
		id: 'emailLookupForm',
		settings: {
			plugins: [
				new ValidateAndAjaxSubmitPlugin({
					ajaxSubmit: {
						submitHandler() {
							const saveSuccessfull = store.save();
							return saveSuccessfull;
						},
					},
				}),
			],
		},
		fields: {
			email: {
				control: {
					reactProps: {
						'type': 'email',
						'data-qa': 'email-control-emailLookupForm',
						'autoComplete': 'email',
						'maxLength': 241,
						'size': 30,
					},
				},
				label: {
					reactProps: {
						children: 'Email Address',
					},
				},
				settings: {
					renderTypeOverride: 'EmailFieldRenderer',
					validationConstraints: {
						presence: {
							message: '^Enter your email address',
						},
						email: {
							message: '^Please enter a valid email address.',
						},
						length: {
							maximum: 241,
						},
						printableAscii: true,
					},
				},
			},
		},
	};

	return formSettings;
};

export { emailLookupFormModel, emailLookupFormSettings };
