import {
	action, observable, computed, makeObservable,
} from 'mobx';

import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import {
	phoneLookupFormModel,
	phoneLookupFormSettings,
} from '~/engage/toolbar/customer-side-menu/customer-lookup/Stores/PhoneLookup.form.settings';

class PhoneLookupStore {
	form;

	parentStore;

	constructor() {
		makeObservable(this, {
			form: observable,
			parentStore: observable,
			payload: computed,
			save: action.bound,
		});
	}

	get payload() {
		return {
			phone: this.form.model.phone,
		};
	}

	save() {
		this.parentStore.save(this.payload);
	}
}

const PhoneLookupStoreFactory = {
	create: (parentStore) => {
		const instance = new PhoneLookupStore();
		instance.parentStore = parentStore;
		instance.form = new FormBuilder(phoneLookupFormModel(), phoneLookupFormSettings(instance));

		return instance;
	},
};

export { PhoneLookupStoreFactory };
