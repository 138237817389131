import React from 'react';
import { extendObservable, observable } from 'mobx';

import { zipCodeInterceptor } from '~/util/formz/interceptors/validationInterceptors';
import { ValidateAndAjaxSubmitPlugin } from '~/util/formz/plugins/ValidateAndAjaxSubmitPlugin';

import styles from '~/engage/available-delivery-dates/Components/delivery-dates-modal.module.scss';

const maxLength = 5;

const formModel = (defaultData = {}) => {
	const model = observable({
		zipCode: '',
	});

	extendObservable(model, defaultData);

	zipCodeInterceptor(model, 'zipCode');

	return model;
};
const formSettings = (formStore, submitHandler) => {
	const focusInput = (component) => {
		if (component) {
			component.focus();
		}
	};

	const settings = {
		id: 'deliveryDatesForm',
		settings: {
			plugins: [
				new ValidateAndAjaxSubmitPlugin({
					ajaxSubmit: {
						submitHandler(form) {
							return submitHandler(form);
						},
					},
				}),
			]
		},
		fields: {
			zipCode: {
				label: {
					reactProps: {
						children: (
							<span>
								{'Zip Code: '}
								{
									Boolean(formStore.form.model) &&
									<strong data-qa="zip-holder">{formStore.zipCode?.substring?.(0, 5)}</strong>
								}
							</span>
						)
					},
				},
				control: {
					reactProps: {
						'ref': focusInput,
						'type': 'text',
						'autoComplete': 'postal-code',
						'placeholder': 'Enter a ZIP',
						'data-qa': 'dd-zipCode-control',
						'className': styles['zipCode-field-group'],
					}
				},
				settings: {
					validationConstraints: {
						presence: {
							message: '^Enter a ZIP code'
						},
						zipCode: true,
						length: {
							minimum: maxLength,
							maximum: maxLength
						},
						printableAscii: true
					}
				},
				showValidationMessage: false
			}
		}
	};

	return settings;
};

export {
	formModel,
	formSettings,
};
