import React from 'react';
import { observer } from 'mobx-react';

import { addToUrl } from '~/util/addToUrl';

import styles from '~/engage/toolbar/customer-side-menu/Components/recent-customers-modal.module.scss';

const CartImages = observer(class CartImages extends React.Component {
	render() {
		return (
			<div>
				{
					this.props.isOpen && !this.props.cartImages !== null &&
					this.props.cartImages.case({
						fulfilled: response => (
							<div className={styles['recent-more-cart-images']}>
								{
									response?.data?.map?.((image, i) => (
										<img
											key={`cart-image-${i}`}
											src={addToUrl(image.imageUrl, '$cwl$')}
											srcSet={addToUrl(image.imageUrl, '$cwl_hires$')}
											alt=""
										/>
									))
								}
							</div>
						)
					})
				}
			</div>
		);
	}
});

export { CartImages };
