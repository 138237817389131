import React from 'react';
import { observer } from 'mobx-react';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';

export const Quantity = observer((props) => {
	const {
		result: {
			changeQuantity,
			maxQuantity,
			quantity,
			salesText,
		} = {},
	} = props;

	const {
		linkEventStore,
	} = useGlobalContext();

	const onQtyChange = (event) => {
		changeQuantity(event);

		if (window.eventDataLayer && linkEventStore) {
			const linkEventTrackingData = {
				trLinkEventName: 'update:quantity',
				trLinkEventCompType: 'design associate tools',
				trLinkEventCompName: salesText,
				trLinkEventType: LinkEventTypes.SITE_ACTION,
			};

			// Only send the traking event for the selections made in the open "dropdown"
			linkEventStore.trackLinkEvent(linkEventTrackingData);
		}
	};

	return (
		<select
			data-tr-link-event-track="false"
			onChange={onQtyChange}
			value={quantity}
		>
			{
				Array.from({ length: maxQuantity }, (_, i) => i + 1).map(value => (
					<option key={value} value={value}>{value}</option>
				))
			}
		</select>
	);
});
