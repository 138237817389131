'use client';

import React from 'react';

import { isEngage } from '~/global/global.constants';

interface Props {
	lockedByUsername: string
}
export const ResourceLockedMessage = ({ values, inline = false }: { values: Props, inline: boolean }) => {
	const { lockedByUsername = 'UNKNOWN' } = values;

	if (isEngage) {
		if (inline) {
			return (
				<div className="tw-text-red">
					Unable to save changes. Try again later, cancel or contact {lockedByUsername} to ask that they
					complete their work.
				</div>
			);
		}
		return (
			<div>
				<p className="tw-font-bold">This record is being edited by {lockedByUsername}</p>
				<p>Try again later or contact {lockedByUsername} to ask that they complete their work.</p>
			</div>
		);
	}
	if (inline) {
		return (
			<div className="tw-text-red">
				Unable to save changes. Please try again.
			</div>
		);
	}
	return (
		<div>
			An error has occurred. For help, please contact Customer Care at <a href="tel:8003019720">800.301.9720</a>.
		</div>
	);
};
