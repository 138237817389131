import { isOnServer } from '~/global/global.constants';
import { OPPORTUNITIES_EVENT_TYPE, OpportunityTrackingModel } from '~/tracking/my-opportunity-events/add/Models/Opportunity.tracking.model';

import { LegacyAddOpportunityTracking } from '~/tracking/my-opportunity-events/add/Models/LegacyAddOpportunity.tracking.model';
import { LegacyRemoveOpportunityTracking } from '~/tracking/my-opportunity-events/remove/Models/LegacyRemoveOpportunity.tracking.model';
import { VisitorEventModel } from '~/tracking/visitor-event/Models/VisitorEvent.tracking.model';
import { BaseOpportunityTrackingModel } from '~/tracking/my-opportunity-events/BaseOpportunity.tracking.model';

export class MyOpportunityTrackingStore {
	static router

	static globalDynamicStore

	legacyTrackAddOpp({ analytics = {}, globalDynamic = {}, router = {} }) {
		if (!isOnServer && window?.eventDataLayer) {
			const {
				customerId = '',
				model: {
					employee = '',
				},
			} = globalDynamic;

			const {
				opportunityType = null,
			} = analytics;

			const visitorEventModel = new VisitorEventModel(globalDynamic, router);
			const addOpportunityTrackingModel = new LegacyAddOpportunityTracking(customerId, opportunityType || 'NEW_FOR_FOLLOW_UP', employee, visitorEventModel.trackingJson);
			this.pushToEventDataLayer(addOpportunityTrackingModel.trackingJson);
		}
	}

	legacyTrackRemoveOpp(analytics, globalDynamic, router) {
		if (!isOnServer && window?.eventDataLayer) {
			const {
				customerId,
				opportunityType,
			} = analytics;
			const {
				model: {
					employee,
				},
			} = globalDynamic;
			const visitorEventModel = new VisitorEventModel(globalDynamic, router);
			const removeOpportunityTrackingModel = new LegacyRemoveOpportunityTracking(customerId, opportunityType, employee, visitorEventModel.trackingJson);
			this.pushToEventDataLayer(removeOpportunityTrackingModel.trackingJson);
		}
	}

	static trackReassignOpp(beforeAnalytics = {}, afterAnalytics = {}) {
		try {
			if (!isOnServer && window?.eventDataLayer) {
				const {
					createOpportunityTrackingModel,
					pushToEventDataLayer,
				} = MyOpportunityTrackingStore;

				const model = createOpportunityTrackingModel(afterAnalytics, beforeAnalytics, OPPORTUNITIES_EVENT_TYPE.REASSIGN);
				pushToEventDataLayer(model.trackingJson);
			}
		} catch (error) {
			console.error('An error occurred tracking the creation of an opportunity', error);
		}
	}

	static trackAddOpp(analytics = {}) {
		try {
			if (!isOnServer && window?.eventDataLayer) {
				const {
					createOpportunityTrackingModel,
					pushToEventDataLayer,
				} = MyOpportunityTrackingStore;

				const model = createOpportunityTrackingModel(analytics, null, OPPORTUNITIES_EVENT_TYPE.ADD);
				pushToEventDataLayer(model.trackingJson);
			}
		} catch (error) {
			console.error('An error occurred tracking the creation of an opportunity', error);
		}
	}

	static trackRemoveOpp(analytics = {}) {
		try {
			const {
				createOpportunityTrackingModel,
				pushToEventDataLayer,
			} = MyOpportunityTrackingStore;

			if (!isOnServer && window?.eventDataLayer) {
				const model = createOpportunityTrackingModel(analytics, null, OPPORTUNITIES_EVENT_TYPE.REMOVE);
				pushToEventDataLayer(model.trackingJson);
			}
		} catch (error) {
			console.error('An error occurred tracking the creation of an opportunity', error);
		}
	}

	static createOpportunityTrackingModel(currentAnalytics, originalAnalytics, eventType) {
		const {
			globalDynamicStore,
			router,
		} = MyOpportunityTrackingStore;

		const {
			opportunityId = null,
			category,
			expirationDate,
			employeeNumber,
			employeeName,
			locationNumber,
			locationName,
			customerId,
			customerName,
		} = currentAnalytics;

		const visitorEventModel = new VisitorEventModel(globalDynamicStore, router);
		const model = new OpportunityTrackingModel({
			eventType,
			currentTrackingModel: new BaseOpportunityTrackingModel({
				category,
				customerId,
				customerName,
				employeeNumber,
				employeeName,
				expirationDate,
				locationNumber,
				locationName,
				opportunityId,
				isOriginal: false,
			}),
			...(originalAnalytics) && {
				originalTrackingModel: new BaseOpportunityTrackingModel({
					isOriginal: true,
					...originalAnalytics,
				}),
			},
			visitorEvent: visitorEventModel.trackingJson,
		});

		return model;
	}

	static pushToEventDataLayer(json) {
		if (!isOnServer && window?.eventDataLayer) {
			window.eventDataLayer.push(json);
		}
	}

	pushToEventDataLayer(json) {
		if (!isOnServer && window?.eventDataLayer) {
			window.eventDataLayer.push(json);
		}
	}
}
