import {
	observable, action, computed, makeObservable,
} from 'mobx';

const categories = {
	GENERAL_SEARCH: {
		options: [
			'EMAIL',
			'PHONE',
			'CUSTOMER_NUMBER',
			'RESIDENTIAL_ADVANCED',
			'ORDER_NUMBER',
			'CART_NUMBER',
		],
	},
	BUSINESS_CUSTOMER: {
		options: [
			'BUSINESS_ADVANCED',
			'QUOTE_NUMBER',
			'PURCHASE_ORDER',
		],
	},
};

class SearchTypes {
	searchTypes;

	constructor(searchTypes) {
		makeObservable(this, {
			searchTypes: observable,
			defaultType: computed,
			availableTypes: computed,
			generalSearchTypes: computed,
			businessSearchTypes: computed,
			selectedType: action.bound,
			saveHref: action.bound,
		});

		this.searchTypes = searchTypes;
	}

	get defaultType() {
		return this.searchTypes.find(type => type.isDefault);
	}

	get generalSearchTypes() {
		const types = this.availableTypes;
		const generalTypes = categories.GENERAL_SEARCH.options.map((typeKey) => {
			return types.find(type => type.type === typeKey);
		}).filter(Boolean);

		return generalTypes;
	}

	get businessSearchTypes() {
		const types = this.availableTypes;
		const businessTypes = categories.BUSINESS_CUSTOMER.options.map((typeKey) => {
			return types.find(type => type.type === typeKey);
		}).filter(Boolean);

		return businessTypes;
	}

	get availableTypes() {
		if (this.searchTypes.length === 0) {
			return [];
		}

		if (this.defaultType) {
			return this.searchTypes.slice()
				.sort((a, b) => {
					if (a.isDefault) {
						return -1;
					}
					if (b.isDefault) {
						return 1;
					}
					return 0;
				});
		}

		return this.searchTypes;
	}

	selectedType(selectedSearchKey) {
		return this.searchTypes.find((type) => {
			return type.type === selectedSearchKey;
		});
	}

	saveHref(selectedSearchKey) {
		return this.selectedType(selectedSearchKey)?._links?.customerSearch?.href;
	}
}

export { SearchTypes };
