import React from 'react';
import cn from 'classnames';

import { isEngage } from '~/global/global.constants';
import { ReturnToBillingPaymentButton } from '~/util/messaging/promise-error/ReturnToBillingPaymentButton';

interface Props {
	values: {
		errorMessage?: string
		showReturnToBillingPaymentButton?: boolean
	}
	inline: boolean
}
export const FinancialTransactionErrorMessage = ({ inline = false, values = {} }: Props) => {
	const showButton = values.showReturnToBillingPaymentButton;

	if (values.errorMessage) {
		return (
			<div className={cn({ 'tw-text-red': inline })}>{values.errorMessage}</div>
		);
	}
	if (isEngage) {
		return (
			<div className={cn({ 'tw-text-red': inline })}>
				An unknown error occurred. Please open a Help Desk ticket.
			</div>
		);
	}
	return (
		<div className={cn({ 'tw-text-red': inline })}>
			{
				inline &&
				'There was an issue with your Gift Card. '
			}
			<div className={cn({ 'tw-inline': inline })}>
				For help, please contact Customer Care at <a href="tel:8003019720">800.301.9720</a>.
			</div>
			{
				showButton &&
				<ReturnToBillingPaymentButton />
			}
		</div>
	);
};
