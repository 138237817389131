import { observable, reaction } from 'mobx';

import { ValidateAndAjaxSubmitPlugin } from '~/util/formz/plugins/ValidateAndAjaxSubmitPlugin';
import { FormReactionsPlugin } from '~/util/formz/plugins/FormReactionsPlugin';

const advancedBusinessLookupFormModel = () => {
	const formModel = {
		firstName: '',
		lastName: '',
		businessName: '',
		street: '',
		city: '',
		zipCode: '',
	};

	return observable(formModel);
};

const advancedBusinessLookupFormSettings = (store) => {
	const validationConstraints = {
		lengthIgnoredChars: {
			minimum: 2,
			maximum: 40,
			ignoredChars: /[*]/g,
		},
		printableAscii: true,
	};

	const zipCodeValidationConstraints = {
		printableAscii: true,
		lengthIgnoredChars: {
			minimum: 2,
			minimumWithIgnoredChars: 3,
			maximum: 10,
			ignoredChars: /[*-]/g,
			sort: 1,
			includeMinMaxInError: false,
		},
		zipCodeWildcard: true,
	};

	const validationSettings = {
		plugins: [
			{
				pluginId: 'wildcardWrapper',
			},
		],
	};

	const validationReaction = (form, fieldName) => {
		return reaction(
			() => form.model[fieldName],
			(value) => {
				if (value.length) {
					form.fields[fieldName].settings.validationConstraints = validationConstraints;
				} else {
					form.fields[fieldName].settings.validationConstraints = {};
					form.plugins.formValidator.validationMessages.delete(fieldName);
				}
			},
		);
	};

	const formSettings = {
		id: 'advancedResidentialLookupForm',
		settings: {
			plugins: [
				new ValidateAndAjaxSubmitPlugin({
					ajaxSubmit: {
						submitHandler() {
							const saveSuccessfull = store.save();
							return saveSuccessfull;
						},
					},
				}),
				new FormReactionsPlugin({
					reaction(form) {
						// at least 2 fields must have values
						store.reactions.push(validationReaction(form, 'firstName'));
						store.reactions.push(validationReaction(form, 'lastName'));
						store.reactions.push(validationReaction(form, 'businessName'));
						store.reactions.push(validationReaction(form, 'street'));
						store.reactions.push(validationReaction(form, 'city'));

						store.reactions.push(
							reaction(
								() => form.model.zipCode,
								(value) => {
									if (value.length) {
										form.fields.zipCode.settings.validationConstraints = zipCodeValidationConstraints;
									} else {
										form.fields.zipCode.settings.validationConstraints = {};
										form.plugins.formValidator.validationMessages.delete('zipCode');
									}
								},
							),
						);
					},
				}),
			],
		},
		fields: {
			firstName: {
				control: {
					reactProps: {
						'type': 'text',
						'autoComplete': 'family-name',
						'maxLength': 40,
						'size': 25,
						'data-qa': 'first-name-control',
					},
				},
				label: {
					reactProps: {
						children: 'Contact First Name',
					},
				},
				settings: validationSettings,
			},
			lastName: {
				control: {
					reactProps: {
						'type': 'text',
						'autoComplete': 'family-name',
						'maxLength': 40,
						'size': 25,
						'data-qa': 'last-name-control',
					},
				},
				label: {
					reactProps: {
						children: 'Contact Last Name',
					},
				},
				settings: validationSettings,
			},
			businessName: {
				control: {
					reactProps: {
						'type': 'text',
						'autoComplete': 'organization',
						'maxLength': 35,
						'size': 45,
						'data-qa': 'business-name-control',
					},
				},
				label: {
					reactProps: {
						children: 'Business Name',
					},
				},
				settings: validationSettings,
			},
			zipCode: {
				control: {
					reactProps: {
						'type': 'text',
						'autoComplete': 'postal-code',
						'maxLength': 10,
						'size': 10,
						'noValidate': true,
						'data-qa': 'zip-code-field',
					},
				},
				label: {
					reactProps: {
						children: 'ZIP Code',
					},
				},
				settings: {
					renderTypeOverride: 'ZipCodeFieldRenderer',
					plugins: [{ pluginId: 'wildcardWrapper' }],
				},
			},
			street: {
				label: {
					reactProps: {
						children: 'Street Address',
					},
				},
				control: {
					reactProps: {
						'maxLength': 60,
						'type': 'text',
						'data-qa': 'address-field',
					},
				},
				settings: validationSettings,
			},
			city: {
				label: {
					reactProps: {
						children: 'City',
					},
				},
				control: {
					reactProps: {
						'maxLength': 60,
						'type': 'text',
						'data-qa': 'city-field',
					},
				},
				settings: validationSettings,
			},
		},
	};

	return formSettings;
};

export { advancedBusinessLookupFormModel, advancedBusinessLookupFormSettings };
