import React from 'react';
import cn from 'classnames';

export const OrderConfirmationFailureMessage = ({ inline = false }: { inline: boolean }) => {
	return (
		<div className={cn({ 'tw-text-red': inline })} data-qa="order-confirmation-failure-message">
			There was an issue sending the email. Please try again.
		</div>
	);
};
