import {
	model, Model, idProp, prop,
} from 'mobx-keystone';

import { modelNamespace } from '~/util/modelNamespace';
import { sanitize } from '~/util/sanitizeString';

@model(`${modelNamespace.OPPORTUNITIES}/BaseOpportunityEventModel`)
export class BaseOpportunityTrackingModel extends Model({
	id: idProp,
	category: prop<string>(),
	customerId: prop<number>(),
	customerName: prop<string>(),
	employeeNumber: prop<number>(),
	employeeName: prop<string>(),
	expirationDate: prop<string>(),
	isOriginal: prop<boolean>(),
	locationNumber: prop<string>(),
	locationName: prop<string>(),
	opportunityId: prop<string>(),
}) {
	get currentDA() {
		return `${this.employeeNumber}|${sanitize(this.employeeName)}`;
	}

	get currentLocation() {
		return `${this.locationNumber}|${sanitize(this.locationName)}`;
	}

	get fieldPrefix() {
		return this.isOriginal ? 'original' : 'current';
	}

	get trackingJson() {
		return {
			[`${this.fieldPrefix}Category`]: sanitize(this.category),
			[`${this.fieldPrefix}DA`]: sanitize(this.currentDA),
			[`${this.fieldPrefix}Location`]: sanitize(this.currentLocation),
			[`${this.fieldPrefix}Expiration`]: sanitize(this.expirationDate),
			[`${this.fieldPrefix}Id`]: sanitize(this.opportunityId),
		};
	}
}
