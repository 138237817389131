import React from 'react';

export const UsernameOrPasswordNotFoundMessage = () => {
	return (
		<div
			data-qa="account-not-found-message"
			className="tw-text-red"
		>
			The username or password is incorrect.
		</div>
	);
};
