import { action, makeObservable } from 'mobx';

import { MagicPaginationNavModel } from '~/components/magic-pagination-nav/Models/MagicPaginationNav.model';

export class MagicPaginationNavStore {
	model;

	focusCurrentPage() {
		this.model.currentPageButtonRef?.elem?.focus?.();
	}

	onClickHandler(event, page) {
		if (!page) {
			return;
		}
		this.model.currentPage = page;
		this.onPageClick?.(page);
		this.scroll(this.model.scrollToElement);
	}

	onPageClick

	scroll(selector) {
		document.querySelector(selector)?.scrollIntoView?.();
	}

	constructor(options) {
		makeObservable(this, {
			focusCurrentPage: action.bound,
			onClickHandler: action.bound,
			scroll: action.bound,
		});

		this.onPageClick = options.onPageClick;
	}
}

export const MagicPaginationNavStoreFactory = {
	create(options = {}) {
		const store = new MagicPaginationNavStore(options);

		store.model = new MagicPaginationNavModel(options.model);
		return store;
	},
};
