import {
	action, observable, computed, makeObservable,
} from 'mobx';

import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import {
	emailLookupFormModel,
	emailLookupFormSettings,
} from '~/engage/toolbar/customer-side-menu/customer-lookup/Stores/EmailLookup.form.settings';

export type EmailLookupStoreFactoryOptions = {
	defaultEmailLookupFormModel?: {
		email?: string
	}
};

class EmailLookupStore {
	form: any;

	parentStore: any;

	constructor() {
		makeObservable(this, {
			form: observable,
			parentStore: observable,
			payload: computed,
			save: action.bound,
		});
	}

	get payload() {
		return {
			email: this.form.model.email,
		};
	}

	save() {
		this.parentStore.save(this.payload);
	}
}

const EmailLookupStoreFactory = {
	create: (parentStore: any, options?: EmailLookupStoreFactoryOptions) => {
		const {
			defaultEmailLookupFormModel = {},
		} = options || {};
		const instance = new EmailLookupStore();

		instance.parentStore = parentStore;
		instance.form = new FormBuilder(
			emailLookupFormModel(defaultEmailLookupFormModel),
			emailLookupFormSettings(instance),
		);

		return instance;
	},
};

export { EmailLookupStoreFactory };
