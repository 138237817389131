import { observable } from 'mobx';

import { purchaseOrderInterceptor } from '~/util/formz/interceptors/validationInterceptors';
import { ValidateAndAjaxSubmitPlugin } from '~/util/formz/plugins/ValidateAndAjaxSubmitPlugin';

const purchaseOrderLookupFormModel = () => {
	const formModel = {
		purchaseOrder: '',
	};

	const obsModel = observable(formModel);
	purchaseOrderInterceptor(obsModel, 'purchaseOrder');
	return obsModel;
};

const purchaseOrderLookupFormSettings = (store) => {
	const formSettings = {
		id: 'purchaseOrderLookupForm',
		settings: {
			plugins: [
				new ValidateAndAjaxSubmitPlugin({
					ajaxSubmit: {
						submitHandler() {
							const saveSuccessfull = store.save();
							return saveSuccessfull;
						},
					},
				}),
			],
		},
		fields: {
			purchaseOrder: {
				control: {
					reactProps: {
						'type': 'text',
						'pattern': '[^*]*',
						'maxLength': 23,
						'minLength': 1,
						'data-qa': 'purchase-order-control',
					},
				},
				label: {
					reactProps: {
						children: 'Purchase Order',
					},
				},
				settings: {
					validationConstraints: {
						presence: {
							message: '^Enter your purchase order',
						},
						length: {
							minimum: 1,
							maximum: 23,
							message: '^Purchase order is too short',
						},
						format: {
							pattern: '[^*]*',
							flags: 'i',
							message: '^Wildcard characters not allowed',
						},
					},
				},
			},
		},
	};

	return formSettings;
};

export { purchaseOrderLookupFormModel, purchaseOrderLookupFormSettings };
