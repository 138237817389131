import React from 'react';

import { useGlobalContext } from '~/global/Contexts/Global.context';

interface Props {
	values: {
		continueClickHandler?: () => void
	}
}

export const AvsAttemptFailedMessage = ({ values }: Props) => {
	const { magicModal } = useGlobalContext();

	return (
		<div className="tw-space-y-4">
			<div data-qa="avs-attempt-failed-message">
				The information you provided does not match the information on file with your credit card company. Please try again.
			</div>
			<button
				className="Button"
				type="button"
				onClick={() => {
					if (values.continueClickHandler) {
						values.continueClickHandler();
					} else {
						magicModal.closeModal();
					}
				}}
			>
				Continue
			</button>
		</div>
	);
};
