import { observable } from 'mobx';

const customerLookupFormModel = (defaultType) => {
	const formModel = {
		formType: defaultType,
	};

	return observable(formModel);
};

const customerLookupFormSettings = (store) => {
	const formSettings = {
		id: 'customerLookupForm',
		fields: {
			formType: {
				control: {
					reactProps: {
						'type': 'select',
						'data-qa': 'customer-search-select',
						'onChange': store.changeForm,
					},
				},
			},
		},
	};

	return formSettings;
};

export { customerLookupFormModel, customerLookupFormSettings };
