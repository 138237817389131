import { intercept } from 'mobx';

const zipCodeInterceptor = (model, field) => {
	const maxLength = 5;

	return intercept(model, field, (change) => {
		const badNewValue = change.newValue === null || typeof change.newValue === 'undefined';
		const isNumber = (/^\d*$/).test(change.newValue);

		if (badNewValue || !isNumber || change.newValue.length > maxLength) {
			return null;
		}

		return change;
	});
};

const cartNumberInterceptor = (model, field) => {
	return intercept(model, field, (change) => {
		const badNewValue = change.newValue === null || typeof change.newValue === 'undefined';
		const isValid = (/^[0-9a-z-]*$/i).test(change.newValue);

		if (badNewValue || !isValid) {
			return null;
		}

		return change;
	});
};

const purchaseOrderInterceptor = (model, field) => {
	return intercept(model, field, (change) => {
		const badNewValue = change.newValue === null || typeof change.newValue === 'undefined';
		const isValid = (/^[^*]*$/i).test(change.newValue);

		if (badNewValue || !isValid) {
			return null;
		}

		return change;
	});
};

export {
	zipCodeInterceptor,
	cartNumberInterceptor,
	purchaseOrderInterceptor,
};
