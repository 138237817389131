import React from 'react';
import cn from 'classnames';

export const FinancialTransactionWithTokenDeclinedMessage = ({ inline }: { inline?: boolean }) => {
	return (
		<div className={cn({ 'tw-text-red': inline })}>
			The selected card's token is invalid. Choose "Credit Card" and try again with the original card.
		</div>
	);
};
