import React from 'react';
import { observer } from 'mobx-react';

import { PromiseError } from '~/util/messaging/promise-error/PromiseError';

export const ErrorMessage = observer(({ error = null, tryAgainClickHandler }) => {
	if (!error) {
		return null;
	}
	const promiseError = new PromiseError(error);

	return (
		<div className="tw-text-red">
			{(() => {
				switch (promiseError.errorKey) {
					case 'duplicateGiftCardError':
						return (
							<span>
								Gift card has already been added to cart.
							</span>
						);
					case 'giftCardTotalError':
						return (
							<span>
								Gift card purchases cannot exceed $10,000 per order
							</span>
						);
					case 'invalidGiftCardNumber':
						return (
							<span>
								Gift card number is not valid.{' '}
								{
									Boolean(tryAgainClickHandler) &&
									<span>
										Please{' '}
										<button
											className="button-as-anchor"
											type="button"
											onClick={tryAgainClickHandler}
										>
											try again
										</button>.
									</span>
								}
							</span>
						);
					default:
						return (
							<span>
								An unknown error occurred, please try again. If the problem persists, please open a Help
								Desk ticket.
							</span>
						);
				}
			})()}
		</div>
	);
});
