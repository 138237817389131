import { observer } from 'mobx-react';

import { type TextMessagingOptIn } from '~/engage/toolbar/set-up-online-access/text-messaging-opt-in/TextMessagingOptIn';
import { Label } from '~/util/formz/Components/Label';
import { MaskedField } from '~/util/formz/Components/fields/MaskedField';
import { Validation } from '~/util/formz/Components/Validation';
import { CheckboxField } from '~/util/formz/Components/fields/CheckboxField';
import { Button } from '~/components/Buttons/Components/Button';
import { TextButton } from '~/components/Buttons/Components/TextButton';
import { useSafeSpread } from '~/hooks/useSafeSpread';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { PromiseErrorMessaging } from '~/util/messaging/promise-error/PromiseErrorMessaging';

export const TextMessagingOptInForm = observer(({ store }: { store: TextMessagingOptIn }) => {
	const { magicModal } = useGlobalContext();
	const {
		form,
		form: {
			fields,
		},
	} = store;
	const [formProps, formKey] = useSafeSpread(form.reactProps);

	return (
		<form key={formKey} {...formProps}>
			<h2 className="tw-heading-4 tw-pb-4" data-qa="mms-form-heading">Opt In to Marketing Text Messages</h2>
			<div>
				<div>
					<Label field={fields.mobileNumber} />
					<div className="tw-flex">
						<input
							className="tw-text-gray-300 tw-text-center tw-w-8 tw-border-r-0"
							value="+1"
							readOnly
						/>
						<MaskedField
							hideLabel
							field={fields.mobileNumber}
							showValidationMessage={false}
						/>
					</div>
				</div>
				<div className="tw-mt-1 tw-mb-4 tw-text-red">
					<Validation field={fields.mobileNumber} />
				</div>
			</div>
			<CheckboxField field={fields.termsAgreement} />
			<div data-qa="text-messaging-legal" className="tw-text-gray-300 tw-text-2xs tw-pl-6 tw--mt-3">
				Message frequency varies. Text HELP to 83904 or email shop@roomandboard.com for help,
				STOP to 83904 to end. Msg & Data Rates May Apply. By opting in, I authorize Room & Board to
				deliver MMS messages using an automatic telephone dialing system and I understand that I am
				not required to opt in as a condition of purchasing any property, goods, or services. By
				leaving this box unchecked you will not be opted in for MMS messages at this time. Click to
				read{' '}
				<a href="/mobile-terms-and-conditions" data-qa="terms-anchor" target="_blank">Terms and Conditions</a>
				, and{' '}
				<a href="/privacy" data-qa="privacy-policy-anchor" target="_blank">Privacy Policy</a>.
			</div>
			<div className="tw-flex tw-gap-8 tw-pt-8">
				<Button
					data-qa="sign-up-for-texts-button"
					disabled={form.plugins.formValidator.hasErrors}
				>
					Sign Up for Texts
				</Button>
				<TextButton
					type="button"
					onClick={magicModal.closeModal}
					data-qa="create-account-success-modal-close-button"
					data-ignore-blur-validation
				>
					Close
				</TextButton>
			</div>
			{
				store.fetchError !== undefined &&
				<div className="tw-pt-4">
					<PromiseErrorMessaging errorMessage={store.fetchError} inline />
				</div>
			}
		</form>
	);
});
